@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #00cfe8;
  --bs-indigo: #6610f2;
  --bs-purple: #7367f0;
  --bs-pink: #d63384;
  --bs-red: #ea5455;
  --bs-orange: #ff9f43;
  --bs-yellow: #ffc107;
  --bs-green: #28c76f;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #b8c2cc;
  --bs-gray-dark: #1e1e1e;
  --bs-gray-100: #babfc7;
  --bs-gray-200: #ededed;
  --bs-gray-300: #dae1e7;
  --bs-gray-400: #636363;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #b8c2cc;
  --bs-gray-700: #4e5154;
  --bs-gray-800: #1e1e1e;
  --bs-gray-900: #2a2e30;
  --bs-primary: #bd832b;
  --bs-secondary: #82868b;
  --bs-success: #28c76f;
  --bs-info: #00cfe8;
  --bs-warning: #ff9f43;
  --bs-danger: #ea5455;
  --bs-light: #f6f6f6;
  --bs-dark: #4b4b4b;
  --bs-primary-rgb: 189, 131, 43;
  --bs-secondary-rgb: 130, 134, 139;
  --bs-success-rgb: 40, 199, 111;
  --bs-info-rgb: 0, 207, 232;
  --bs-warning-rgb: 255, 159, 67;
  --bs-danger-rgb: 234, 84, 85;
  --bs-light-rgb: 246, 246, 246;
  --bs-dark-rgb: 75, 75, 75;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 34, 41, 47;
  --bs-body-color-rgb: 110, 107, 123;
  --bs-body-bg-rgb: 248, 248, 248;
  --bs-font-sans-serif: "Montserrat", Helvetica, Arial, serif;
  --bs-font-monospace: "Montserrat", Helvetica, Arial, serif;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.45;
  --bs-body-color: #6e6b7b;
  --bs-body-bg: #f8f8f8;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(34, 41, 47, 0);
}

hr {
  margin: 1rem 0;
  color: #ebe9f1;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #5e5873;
}

h1, .h1 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2rem;
  }
}

h2, .h2 {
  font-size: calc(1.2964rem + 0.5568vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.714rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: calc(1.2536rem + 0.0432vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.286rem;
  }
}

h5, .h5 {
  font-size: 1.07rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 600;
}

small, .small {
  font-size: 0.857rem;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #bd832b;
  text-decoration: none;
}
a:hover {
  color: #b47c29;
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 90%;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 90%;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #fff;
  background-color: #eee;
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 500;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.72rem;
  padding-bottom: 0.72rem;
  color: #b9b9c3;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.14rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.725rem + 5.7vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 6rem;
  }
}

.display-2 {
  font-size: calc(1.675rem + 5.1vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 5.5rem;
  }
}

.display-3 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4.5rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 400;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.857rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 0;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.857rem;
  color: #b9b9c3;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8f8f8;
  border: 1px solid #dae1e7;
  border-radius: 0.357rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.857rem;
  color: #b8c2cc;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  width: 100%;
  padding-right: var(--bs-gutter-x, 1rem);
  padding-left: var(--bs-gutter-x, 1rem);
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 0;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
@media (min-width: 1440px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1440px;
  }
}
.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-25,
.gx-25 {
  --bs-gutter-x: 0.25rem;
}

.g-25,
.gy-25 {
  --bs-gutter-y: 0.25rem;
}

.g-50,
.gx-50 {
  --bs-gutter-x: 0.5rem;
}

.g-50,
.gy-50 {
  --bs-gutter-y: 0.5rem;
}

.g-75,
.gx-75 {
  --bs-gutter-x: 0.75rem;
}

.g-75,
.gy-75 {
  --bs-gutter-y: 0.75rem;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 1rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 1rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 1.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 1.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 3rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 3rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 3.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 3.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 4rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 4rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-25,
.gx-sm-25 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-25,
.gy-sm-25 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-50,
.gx-sm-50 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-50,
.gy-sm-50 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-75,
.gx-sm-75 {
    --bs-gutter-x: 0.75rem;
  }

  .g-sm-75,
.gy-sm-75 {
    --bs-gutter-y: 0.75rem;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 3rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 3.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 3.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 4rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-25,
.gx-md-25 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-25,
.gy-md-25 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-50,
.gx-md-50 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-50,
.gy-md-50 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-75,
.gx-md-75 {
    --bs-gutter-x: 0.75rem;
  }

  .g-md-75,
.gy-md-75 {
    --bs-gutter-y: 0.75rem;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 1rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 1rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 3rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 3rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 3.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 3.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 4rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-25,
.gx-lg-25 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-25,
.gy-lg-25 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-50,
.gx-lg-50 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-50,
.gy-lg-50 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-75,
.gx-lg-75 {
    --bs-gutter-x: 0.75rem;
  }

  .g-lg-75,
.gy-lg-75 {
    --bs-gutter-y: 0.75rem;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 3rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 3.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 3.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 4rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-25,
.gx-xl-25 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-25,
.gy-xl-25 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-50,
.gx-xl-50 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-50,
.gy-xl-50 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-75,
.gx-xl-75 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xl-75,
.gy-xl-75 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 3rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 4rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 4rem;
  }
}
@media (min-width: 1440px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-25,
.gx-xxl-25 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-25,
.gy-xxl-25 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-50,
.gx-xxl-50 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-50,
.gy-xxl-50 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-75,
.gx-xxl-75 {
    --bs-gutter-x: 0.75rem;
  }

  .g-xxl-75,
.gy-xxl-75 {
    --bs-gutter-y: 0.75rem;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 3rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 3.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 3.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 4rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 4rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #6e6b7b;
  --bs-table-striped-bg: #fafafc;
  --bs-table-active-color: #6e6b7b;
  --bs-table-active-bg: rgba(34, 41, 47, 0.1);
  --bs-table-hover-color: #6e6b7b;
  --bs-table-hover-bg: #f6f6f9;
  width: 100%;
  margin-bottom: 1rem;
  color: #6e6b7b;
  vertical-align: middle;
  border-color: #ebe9f1;
}
.table > :not(caption) > * > * {
  padding: 0.72rem 2rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid #ebe9f1;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.3rem 0.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #f2e6d5;
  --bs-table-striped-bg: #e8ddcd;
  --bs-table-striped-color: #22292f;
  --bs-table-active-bg: #ddd3c4;
  --bs-table-active-color: #22292f;
  --bs-table-hover-bg: #e2d8c9;
  --bs-table-hover-color: #22292f;
  color: #22292f;
  border-color: #ddd3c4;
}

.table-secondary {
  --bs-table-bg: #e6e7e8;
  --bs-table-striped-bg: #dcdedf;
  --bs-table-striped-color: #22292f;
  --bs-table-active-bg: #d2d4d6;
  --bs-table-active-color: #22292f;
  --bs-table-hover-bg: #d7d9da;
  --bs-table-hover-color: #22292f;
  color: #22292f;
  border-color: #d2d4d6;
}

.table-success {
  --bs-table-bg: #d4f4e2;
  --bs-table-striped-bg: #cbead9;
  --bs-table-striped-color: #22292f;
  --bs-table-active-bg: #c2e0d0;
  --bs-table-active-color: #22292f;
  --bs-table-hover-bg: #c7e5d5;
  --bs-table-hover-color: #22292f;
  color: #22292f;
  border-color: #c2e0d0;
}

.table-info {
  --bs-table-bg: #ccf5fa;
  --bs-table-striped-bg: #c4ebf0;
  --bs-table-striped-color: #22292f;
  --bs-table-active-bg: #bbe1e6;
  --bs-table-active-color: #22292f;
  --bs-table-hover-bg: #bfe6eb;
  --bs-table-hover-color: #22292f;
  color: #22292f;
  border-color: #bbe1e6;
}

.table-warning {
  --bs-table-bg: #ffecd9;
  --bs-table-striped-bg: #f4e2d1;
  --bs-table-striped-color: #22292f;
  --bs-table-active-bg: #e9d9c8;
  --bs-table-active-color: #22292f;
  --bs-table-hover-bg: #eeddcc;
  --bs-table-hover-color: #22292f;
  color: #22292f;
  border-color: #e9d9c8;
}

.table-danger {
  --bs-table-bg: #fbdddd;
  --bs-table-striped-bg: #f0d4d4;
  --bs-table-striped-color: #22292f;
  --bs-table-active-bg: #e5cbcc;
  --bs-table-active-color: #22292f;
  --bs-table-hover-bg: #ebd0d0;
  --bs-table-hover-color: #22292f;
  color: #22292f;
  border-color: #e5cbcc;
}

.table-light {
  --bs-table-bg: #f6f6f6;
  --bs-table-striped-bg: #ebecec;
  --bs-table-striped-color: #22292f;
  --bs-table-active-bg: #e1e2e2;
  --bs-table-active-color: #22292f;
  --bs-table-hover-bg: #e6e7e7;
  --bs-table-hover-color: #22292f;
  color: #22292f;
  border-color: #e1e2e2;
}

.table-dark {
  --bs-table-bg: #4b4b4b;
  --bs-table-striped-bg: #545454;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #5d5d5d;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #595959;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #5d5d5d;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.2857rem;
  font-size: 0.857rem;
  color: #5e5873;
}

.col-form-label {
  padding-top: calc(0.571rem + 1px);
  padding-bottom: calc(0.571rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.45;
  color: #5e5873;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  font-size: 1.143rem;
}

.col-form-label-sm {
  padding-top: calc(0.188rem + 1px);
  padding-bottom: calc(0.188rem + 1px);
  font-size: 0.857rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: #b9b9c3;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  appearance: none;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #6e6b7b;
  background-color: #fff;
  border-color: #bd832b;
  outline: 0;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
.form-control::-webkit-date-and-time-value {
  height: 1.45em;
}
.form-control::placeholder {
  color: #b9b9c3;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #efefef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.571rem 1rem;
  margin: -0.571rem -1rem;
  margin-inline-end: 1rem;
  color: #6e6b7b;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}
.form-control::-webkit-file-upload-button {
  padding: 0.571rem 1rem;
  margin: -0.571rem -1rem;
  margin-inline-end: 1rem;
  color: #6e6b7b;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.571rem 0;
  margin-bottom: 0;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: 2.142rem;
  padding: 0.188rem 0.857rem;
  font-size: 0.857rem;
  border-radius: 0.357rem;
}
.form-control-sm::file-selector-button {
  padding: 0.188rem 0.857rem;
  margin: -0.188rem -0.857rem;
  margin-inline-end: 0.857rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.188rem 0.857rem;
  margin: -0.188rem -0.857rem;
  margin-inline-end: 0.857rem;
}

.form-control-lg {
  min-height: 3.2857rem;
  padding: 0.75rem 1.143rem;
  font-size: 1.143rem;
  border-radius: 0.357rem;
}
.form-control-lg::file-selector-button {
  padding: 0.75rem 1.143rem;
  margin: -0.75rem -1.143rem;
  margin-inline-end: 1.143rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.75rem 1.143rem;
  margin: -0.75rem -1.143rem;
  margin-inline-end: 1.143rem;
}

textarea.form-control {
  min-height: 2.714rem;
}
textarea.form-control-sm {
  min-height: 2.142rem;
}
textarea.form-control-lg {
  min-height: 3.2857rem;
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.571rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.45em;
  border-radius: 0.357rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.45em;
  border-radius: 0.357rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.571rem 1rem 0.571rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 15px 14px;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #bd832b;
  outline: 0;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #efefef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #6e6b7b;
}

.form-select-sm {
  padding-top: 0.188rem;
  padding-bottom: 0.188rem;
  padding-left: 0.857rem;
  font-size: 0.857rem;
  border-radius: 0.357rem;
}

.form-select-lg {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.143rem;
  font-size: 1.143rem;
  border-radius: 0.357rem;
}

.form-check {
  display: block;
  min-height: 1.45rem;
  padding-left: 1.785rem;
  margin-bottom: 0;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.785rem;
}

.form-check-input {
  width: 1.285rem;
  height: 1.285rem;
  margin-top: 0.0825rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #d8d6de;
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 3px;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #bd832b;
  outline: 0;
  box-shadow: 0 2px 4px 0 rgba(189, 131, 43, 0.4);
}
.form-check-input:checked {
  background-color: #bd832b;
  border-color: #bd832b;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'%3e%3cpolyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: none;
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #bd832b;
  border-color: #bd832b;
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus'%3e%3cline x1='5' y1='12' x2='19' y2='12'%3e%3c/line%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.65;
}

.form-check-label {
  cursor: pointer;
}

.form-switch {
  padding-left: 3.5rem;
}
.form-switch .form-check-input {
  width: 3rem;
  margin-left: -3.5rem;
  background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 3rem;
  transition: background-position 0.15s ease-in-out, background-color 0.1s ease;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3ccircle id='path-1' cx='8' cy='8' r='8'%3e%3c/circle%3e%3cfilter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'%3e%3cfeOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'%3e%3c/feOffset%3e%3cfeGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3e%3c/feGaussianBlur%3e%3cfeColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3e%3c/feColorMatrix%3e%3c/filter%3e%3c/defs%3e%3cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='switches-dot' transform='translate%285.000000, 5.000000%29' fill-rule='nonzero'%3e%3cg id='Oval'%3e%3cuse fill='black' fill-opacity='1' filter='url%28%23filter-2%29' xlink:href='%23path-1'%3e%3c/use%3e%3cuse fill='%23fff' xlink:href='%23path-1'%3e%3c/use%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8f8f8, 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8f8f8, 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #bd832b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #ebdabf;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dae1e7;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #bd832b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #ebdabf;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dae1e7;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(2.57rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 0.571rem 1rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 0.571rem 1rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 0.571rem;
  padding-bottom: 0.571rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 0.571rem;
  padding-bottom: 0.571rem;
}
.form-floating > .form-select {
  padding-top: 0.571rem;
  padding-bottom: 0.571rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.9;
  transform: scale(0.85) translateY(-2.25rem) translateX(-0.85rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.9;
  transform: scale(0.85) translateY(-2.25rem) translateX(-0.85rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.75rem 1.143rem;
  font-size: 1.143rem;
  border-radius: 0.357rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.188rem 0.857rem;
  font-size: 0.857rem;
  border-radius: 0.357rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: #28c76f;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.775rem;
  margin-top: 0.1rem;
  font-size: 0.857rem;
  color: #fff;
  background-color: #28c76f;
  border-radius: 0.358rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28c76f;
  padding-right: calc(1.45em + 1.142rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3625em + 0.2855rem) center;
  background-size: calc(0.725em + 0.571rem) calc(0.725em + 0.571rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28c76f;
  box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.45em + 1.142rem);
  background-position: top calc(0.3625em + 0.2855rem) right calc(0.3625em + 0.2855rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28c76f;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 3.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 1rem;
  background-size: 15px 14px, calc(0.725em + 0.571rem) calc(0.725em + 0.571rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #28c76f;
  box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28c76f;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #28c76f;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28c76f;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.857rem;
  color: #ea5455;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.775rem;
  margin-top: 0.1rem;
  font-size: 0.857rem;
  color: #fff;
  background-color: #ea5455;
  border-radius: 0.358rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ea5455;
  padding-right: calc(1.45em + 1.142rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ea5455'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3625em + 0.2855rem) center;
  background-size: calc(0.725em + 0.571rem) calc(0.725em + 0.571rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ea5455;
  box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.45em + 1.142rem);
  background-position: top calc(0.3625em + 0.2855rem) right calc(0.3625em + 0.2855rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ea5455;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 3.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ea5455'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 1rem;
  background-size: 15px 14px, calc(0.725em + 0.571rem) calc(0.725em + 0.571rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ea5455;
  box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ea5455;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ea5455;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ea5455;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: #6e6b7b;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.358rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #6e6b7b;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(189, 131, 43, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #bd832b;
  border-color: #bd832b;
}
.btn-primary:hover {
  color: #fff;
  background-color: #a16f25;
  border-color: #976922;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #a16f25;
  border-color: #976922;
  box-shadow: 0 0 0 0.25rem rgba(199, 150, 75, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #976922;
  border-color: #8e6220;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(199, 150, 75, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #bd832b;
  border-color: #bd832b;
}

.btn-secondary {
  color: #fff;
  background-color: #82868b;
  border-color: #82868b;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #6f7276;
  border-color: #686b6f;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #6f7276;
  border-color: #686b6f;
  box-shadow: 0 0 0 0.25rem rgba(149, 152, 156, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #686b6f;
  border-color: #626568;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(149, 152, 156, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #82868b;
  border-color: #82868b;
}

.btn-success {
  color: #22292f;
  background-color: #28c76f;
  border-color: #28c76f;
}
.btn-success:hover {
  color: #22292f;
  background-color: #48cf85;
  border-color: #3ecd7d;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #22292f;
  background-color: #48cf85;
  border-color: #3ecd7d;
  box-shadow: 0 0 0 0.25rem rgba(39, 175, 101, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #22292f;
  background-color: #53d28c;
  border-color: #3ecd7d;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 175, 101, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #22292f;
  background-color: #28c76f;
  border-color: #28c76f;
}

.btn-info {
  color: #22292f;
  background-color: #00cfe8;
  border-color: #00cfe8;
}
.btn-info:hover {
  color: #22292f;
  background-color: #26d6eb;
  border-color: #1ad4ea;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #22292f;
  background-color: #26d6eb;
  border-color: #1ad4ea;
  box-shadow: 0 0 0 0.25rem rgba(5, 182, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #22292f;
  background-color: #33d9ed;
  border-color: #1ad4ea;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(5, 182, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #22292f;
  background-color: #00cfe8;
  border-color: #00cfe8;
}

.btn-warning {
  color: #22292f;
  background-color: #ff9f43;
  border-color: #ff9f43;
}
.btn-warning:hover {
  color: #22292f;
  background-color: #ffad5f;
  border-color: #ffa956;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #22292f;
  background-color: #ffad5f;
  border-color: #ffa956;
  box-shadow: 0 0 0 0.25rem rgba(222, 141, 64, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #22292f;
  background-color: #ffb269;
  border-color: #ffa956;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 141, 64, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #22292f;
  background-color: #ff9f43;
  border-color: #ff9f43;
}

.btn-danger {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c74748;
  border-color: #bb4344;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #c74748;
  border-color: #bb4344;
  box-shadow: 0 0 0 0.25rem rgba(237, 110, 111, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bb4344;
  border-color: #b03f40;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 110, 111, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}

.btn-light {
  color: #22292f;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}
.btn-light:hover {
  color: #22292f;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #22292f;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  box-shadow: 0 0 0 0.25rem rgba(214, 215, 216, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #22292f;
  background-color: #f8f8f8;
  border-color: #f7f7f7;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(214, 215, 216, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #22292f;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}

.btn-dark {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}
.btn-dark:hover {
  color: #fff;
  background-color: #404040;
  border-color: #3c3c3c;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #404040;
  border-color: #3c3c3c;
  box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #3c3c3c;
  border-color: #383838;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.btn-outline-primary {
  color: #bd832b;
  border-color: #bd832b;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #bd832b;
  border-color: #bd832b;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 131, 43, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #bd832b;
  border-color: #bd832b;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 131, 43, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #bd832b;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #82868b;
  border-color: #82868b;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #82868b;
  border-color: #82868b;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 134, 139, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #82868b;
  border-color: #82868b;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 134, 139, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #82868b;
  background-color: transparent;
}

.btn-outline-success {
  color: #28c76f;
  border-color: #28c76f;
}
.btn-outline-success:hover {
  color: #22292f;
  background-color: #28c76f;
  border-color: #28c76f;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #22292f;
  background-color: #28c76f;
  border-color: #28c76f;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(40, 199, 111, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #28c76f;
  background-color: transparent;
}

.btn-outline-info {
  color: #00cfe8;
  border-color: #00cfe8;
}
.btn-outline-info:hover {
  color: #22292f;
  background-color: #00cfe8;
  border-color: #00cfe8;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 207, 232, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #22292f;
  background-color: #00cfe8;
  border-color: #00cfe8;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 207, 232, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #00cfe8;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ff9f43;
  border-color: #ff9f43;
}
.btn-outline-warning:hover {
  color: #22292f;
  background-color: #ff9f43;
  border-color: #ff9f43;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 159, 67, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #22292f;
  background-color: #ff9f43;
  border-color: #ff9f43;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 159, 67, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ff9f43;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ea5455;
  border-color: #ea5455;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #ea5455;
  border-color: #ea5455;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 84, 85, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #ea5455;
  background-color: transparent;
}

.btn-outline-light {
  color: #f6f6f6;
  border-color: #f6f6f6;
}
.btn-outline-light:hover {
  color: #22292f;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 246, 246, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #22292f;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 246, 246, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f6f6f6;
  background-color: transparent;
}

.btn-outline-dark {
  color: #4b4b4b;
  border-color: #4b4b4b;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 75, 75, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(75, 75, 75, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #4b4b4b;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #bd832b;
  text-decoration: none;
}
.btn-link:hover {
  color: #b47c29;
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #b8c2cc;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.8rem 2rem;
  font-size: 1.25rem;
  border-radius: 0.358rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.486rem 1rem;
  font-size: 0.9rem;
  border-radius: 0.358rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #6e6b7b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 41, 47, 0.05);
  border-radius: 0.357rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1440px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(34, 41, 47, 0.08);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.65rem 1.28rem;
  clear: both;
  font-weight: 400;
  color: #6e6b7b;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #bd832b;
  background-color: rgba(189, 131, 43, 0.12);
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #bd832b;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #b9b9c3;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.28rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #5e5873;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.65rem 1.28rem;
  color: #6e6b7b;
}

.dropdown-menu-dark {
  color: #dae1e7;
  background-color: #1e1e1e;
  border-color: rgba(34, 41, 47, 0.05);
}
.dropdown-menu-dark .dropdown-item {
  color: #dae1e7;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #bd832b;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(34, 41, 47, 0.08);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dae1e7;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.358rem 0.5rem;
  color: #bd832b;
  transition: none;
}
.nav-link:hover, .nav-link:focus {
  color: #b47c29;
}
.nav-link.disabled {
  color: #b9b9c3;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0 solid #dae1e7;
}
.nav-tabs .nav-link {
  margin-bottom: 0;
  background: none;
  border: 0 solid transparent;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #ededed #ededed #dae1e7;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #b9b9c3;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #bd832b;
  background-color: transparent;
  border-color: #dae1e7 #dae1e7 transparent;
}
.nav-tabs .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.358rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #bd832b;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-xs,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: -0.367rem;
  padding-bottom: -0.367rem;
  margin-right: 1rem;
  font-size: calc(1.325rem + 0.9vw);
  white-space: nowrap;
}
@media (min-width: 1200px) {
  .navbar-brand {
    font-size: 2rem;
  }
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.358rem;
  padding-bottom: 0.358rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: calc(1.325rem + 0.9vw);
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.358rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (min-width: 1200px) {
  .navbar-toggler {
    font-size: 2rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(34, 41, 47, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(34, 41, 47, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(34, 41, 47, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(34, 41, 47, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(34, 41, 47, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(34, 41, 47, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(34, 41, 47, 0.55);
  border-color: rgba(34, 41, 47, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2834, 41, 47, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(34, 41, 47, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(34, 41, 47, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.428rem;
  border-top-right-radius: 0.428rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.428rem;
  border-bottom-left-radius: 0.428rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

.card-title {
  margin-bottom: 1.5rem;
}

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 0 solid rgba(34, 41, 47, 0.125);
}
.card-header:first-child {
  border-radius: 0.428rem 0.428rem 0 0;
}

.card-footer {
  padding: 1.5rem 1.5rem;
  background-color: transparent;
  border-top: 0 solid rgba(34, 41, 47, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0.428rem 0.428rem;
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.428rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.428rem;
  border-top-right-radius: 0.428rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.428rem;
  border-bottom-left-radius: 0.428rem;
}

.card-group > .card {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1rem;
  font-size: 1rem;
  color: #6e6b7b;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #6e6b7b;
  background-color: transparent;
  box-shadow: inset 0 0 0 rgba(34, 41, 47, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3e%3cpolyline points='18 15 12 9 6 15'%3e%3c/polyline%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3e%3cpolyline points='18 15 12 9 6 15'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #bd832b;
  outline: 0;
  box-shadow: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 0 solid rgba(34, 41, 47, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}

.accordion-body {
  padding: 1rem 1rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.6rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.6rem;
  color: #6e6b7b;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "\\") */;
}
.breadcrumb-item.active {
  color: #6e6b7b;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #6e6b7b;
  background-color: #f3f2f7;
  border: 0 solid #dae1e7;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #bd832b;
  background-color: #f3f2f7;
  border-color: #dae1e7;
}
.page-link:focus {
  z-index: 3;
  color: inherit;
  background-color: #f3f2f7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(189, 131, 43, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item.active .page-link {
  z-index: 3;
  color: #bd832b;
  background-color: #bd832b;
  border-color: #bd832b;
}
.page-item.disabled .page-link {
  color: #b9b9c3;
  pointer-events: none;
  background-color: #f3f2f7;
  border-color: #dae1e7;
}

.page-link {
  padding: 0.5rem 0.85rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
}

.pagination-lg .page-link {
  padding: 0.5575rem 1rem;
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 2rem;
  }
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.pagination-sm .page-link {
  padding: 0.443rem 0.745rem;
  font-size: 1rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.badge {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.358rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 0.71rem 1rem;
  margin-bottom: 1rem;
  border: 0 solid transparent;
  border-radius: 0.358rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.8875rem 1rem;
}

.alert-primary {
  color: #714f1a;
  background-color: #f2e6d5;
  border-color: #ebdabf;
}
.alert-primary .alert-link {
  color: #5a3f15;
}

.alert-secondary {
  color: #4e5053;
  background-color: #e6e7e8;
  border-color: #dadbdc;
}
.alert-secondary .alert-link {
  color: #3e4042;
}

.alert-success {
  color: #187743;
  background-color: #d4f4e2;
  border-color: #bfeed4;
}
.alert-success .alert-link {
  color: #135f36;
}

.alert-info {
  color: #007c8b;
  background-color: #ccf5fa;
  border-color: #b3f1f8;
}
.alert-info .alert-link {
  color: #00636f;
}

.alert-warning {
  color: #995f28;
  background-color: #ffecd9;
  border-color: #ffe2c7;
}
.alert-warning .alert-link {
  color: #7a4c20;
}

.alert-danger {
  color: #8c3233;
  background-color: #fbdddd;
  border-color: #f9cccc;
}
.alert-danger .alert-link {
  color: #702829;
}

.alert-light {
  color: #777b7f;
  background-color: #fdfdfd;
  border-color: #fcfcfc;
}
.alert-light .alert-link {
  color: #5f6266;
}

.alert-dark {
  color: #2d2d2d;
  background-color: #dbdbdb;
  border-color: #c9c9c9;
}
.alert-dark .alert-link {
  color: #242424;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.857rem;
  }
}
.progress {
  display: flex;
  height: 0.857rem;
  overflow: hidden;
  font-size: 0.857rem;
  background-color: rgba(189, 131, 43, 0.12);
  border-radius: 5rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #bd832b;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.857rem 0.857rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.357rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #6e6b7b;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #6e6b7b;
  text-decoration: none;
  background-color: #f8f8f8;
}
.list-group-item-action:active {
  color: #fff;
  background-color: #bd832b;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  color: #6e6b7b;
  background-color: #fff;
  border: 1px solid rgba(34, 41, 47, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #b9b9c3;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #bd832b;
  border-color: #bd832b;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.357rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.357rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.357rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.357rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.357rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.357rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.357rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.357rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.357rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.357rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.357rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.357rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #714f1a;
  background-color: #f2e6d5;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #714f1a;
  background-color: #dacfc0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #714f1a;
  border-color: #714f1a;
}

.list-group-item-secondary {
  color: #4e5053;
  background-color: #e6e7e8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #4e5053;
  background-color: #cfd0d1;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #4e5053;
  border-color: #4e5053;
}

.list-group-item-success {
  color: #187743;
  background-color: #d4f4e2;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #187743;
  background-color: #bfdccb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #187743;
  border-color: #187743;
}

.list-group-item-info {
  color: #007c8b;
  background-color: #ccf5fa;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007c8b;
  background-color: #b8dde1;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #007c8b;
  border-color: #007c8b;
}

.list-group-item-warning {
  color: #995f28;
  background-color: #ffecd9;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #995f28;
  background-color: #e6d4c3;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #995f28;
  border-color: #995f28;
}

.list-group-item-danger {
  color: #8c3233;
  background-color: #fbdddd;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #8c3233;
  background-color: #e2c7c7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8c3233;
  border-color: #8c3233;
}

.list-group-item-light {
  color: #777b7f;
  background-color: #fdfdfd;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #777b7f;
  background-color: #e4e4e4;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #777b7f;
  border-color: #777b7f;
}

.list-group-item-dark {
  color: #2d2d2d;
  background-color: #dbdbdb;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #2d2d2d;
  background-color: #c5c5c5;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2d2d2d;
  border-color: #2d2d2d;
}

.btn-close {
  box-sizing: content-box;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.25em 0.25em;
  color: currentColor;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  border: 0;
  border-radius: 0.357rem;
  opacity: 0.4;
}
.btn-close:hover {
  color: currentColor;
  text-decoration: none;
  opacity: 0.65;
}
.btn-close:focus {
  outline: 0;
  box-shadow: none;
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 380px;
  max-width: 100%;
  font-size: 0.857rem;
  color: #6e6b7b;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 0 solid rgba(34, 41, 47, 0.1);
  box-shadow: 0 2px 20px 0 rgba(34, 41, 47, 0.08);
  border-radius: 0.286rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 1rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.14rem;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 0 solid rgba(34, 41, 47, 0.05);
  border-top-left-radius: 0.286rem;
  border-top-right-radius: 0.286rem;
}
.toast-header .btn-close {
  margin-right: -0.57rem;
  margin-left: 1.14rem;
}

.toast-body {
  padding: 1.14rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(34, 41, 47, 0.2);
  border-radius: 0.357rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #22292f;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.4rem;
  border-bottom: 0 solid #ebe9f1;
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}
.modal-header .btn-close {
  padding: 0.4rem 0.7rem;
  margin: -0.4rem -0.7rem -0.4rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.45;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 0.8rem 1.4rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem-0.25rem;
  border-top: 1px solid rgba(34, 41, 47, 0.05);
  border-bottom-right-radius: 0.357rem;
  border-bottom-left-radius: 0.357rem;
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 400px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 94%;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1439.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.857rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #323232;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #323232;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #323232;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #323232;
}

.tooltip-inner {
  max-width: 220px;
  padding: 0.4rem 0.775rem;
  color: #fff;
  text-align: center;
  background-color: #323232;
  border-radius: 0.358rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid #ebe9f1;
  border-radius: 0.358rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: -0.5rem;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ebe9f1;
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: -0.5rem;
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ebe9f1;
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: -0.5rem;
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ebe9f1;
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 0 solid #bd832b;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: -0.5rem;
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ebe9f1;
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.65rem 1.21rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #fff;
  background-color: #bd832b;
  border-bottom: 0 solid #ebe9f1;
  border-top-left-radius: 0.358rem;
  border-top-right-radius: 0.358rem;
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #6e6b7b;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 1.43rem;
  height: 1.43rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"%3e%3cpolyline points="15 18 9 12 15 6"%3e%3c/polyline%3e%3c/svg%3e');
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"%3e%3cpolyline points="9 18 15 12 9 6"%3e%3c/polyline%3e%3c/svg%3e');
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #22292f;
}
.carousel-dark .carousel-caption {
  color: #22292f;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #22292f;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.4rem;
}
.offcanvas-header .btn-close {
  padding: 0.4rem 0.7rem;
  margin-top: -0.4rem;
  margin-right: -0.7rem;
  margin-bottom: -0.4rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.45;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 0.8rem 1.4rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 320px;
  border-right: 0 solid rgba(34, 41, 47, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 320px;
  border-left: 0 solid rgba(34, 41, 47, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 0 solid rgba(34, 41, 47, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 0 solid rgba(34, 41, 47, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #22292f 55%, rgba(0, 0, 0, 0.8) 75%, #22292f 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #bd832b;
}
.link-primary:hover, .link-primary:focus {
  color: #b47c29;
}

.link-secondary {
  color: #82868b;
}
.link-secondary:hover, .link-secondary:focus {
  color: #7c7f84;
}

.link-success {
  color: #28c76f;
}
.link-success:hover, .link-success:focus {
  color: #33ca76;
}

.link-info {
  color: #00cfe8;
}
.link-info:hover, .link-info:focus {
  color: #0dd1e9;
}

.link-warning {
  color: #ff9f43;
}
.link-warning:hover, .link-warning:focus {
  color: #ffa44c;
}

.link-danger {
  color: #ea5455;
}
.link-danger:hover, .link-danger:focus {
  color: #de5051;
}

.link-light {
  color: #f6f6f6;
}
.link-light:hover, .link-light:focus {
  color: #f6f6f6;
}

.link-dark {
  color: #4b4b4b;
}
.link-dark:hover, .link-dark:focus {
  color: #474747;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1440px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(34, 41, 47, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(34, 41, 47, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #ebe9f1 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #ebe9f1 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #ebe9f1 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #ebe9f1 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #ebe9f1 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #bd832b !important;
}

.border-secondary {
  border-color: #82868b !important;
}

.border-success {
  border-color: #28c76f !important;
}

.border-info {
  border-color: #00cfe8 !important;
}

.border-warning {
  border-color: #ff9f43 !important;
}

.border-danger {
  border-color: #ea5455 !important;
}

.border-light {
  border-color: #f6f6f6 !important;
}

.border-dark {
  border-color: #4b4b4b !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-25 {
  gap: 0.25rem !important;
}

.gap-50 {
  gap: 0.5rem !important;
}

.gap-75 {
  gap: 0.75rem !important;
}

.gap-1 {
  gap: 1rem !important;
}

.gap-2 {
  gap: 1.5rem !important;
}

.gap-3 {
  gap: 3rem !important;
}

.gap-4 {
  gap: 3.5rem !important;
}

.gap-5 {
  gap: 4rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-25 {
  margin: 0.25rem !important;
}

.m-50 {
  margin: 0.5rem !important;
}

.m-75 {
  margin: 0.75rem !important;
}

.m-1 {
  margin: 1rem !important;
}

.m-2 {
  margin: 1.5rem !important;
}

.m-3 {
  margin: 3rem !important;
}

.m-4 {
  margin: 3.5rem !important;
}

.m-5 {
  margin: 4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-25 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-50 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-75 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-2 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-4 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
}

.mx-5 {
  margin-right: 4rem !important;
  margin-left: 4rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-25 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-50 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-75 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-2 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-4 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.my-5 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-25 {
  margin-top: 0.25rem !important;
}

.mt-50 {
  margin-top: 0.5rem !important;
}

.mt-75 {
  margin-top: 0.75rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 1.5rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 3.5rem !important;
}

.mt-5 {
  margin-top: 4rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-25 {
  margin-right: 0.25rem !important;
}

.me-50 {
  margin-right: 0.5rem !important;
}

.me-75 {
  margin-right: 0.75rem !important;
}

.me-1 {
  margin-right: 1rem !important;
}

.me-2 {
  margin-right: 1.5rem !important;
}

.me-3 {
  margin-right: 3rem !important;
}

.me-4 {
  margin-right: 3.5rem !important;
}

.me-5 {
  margin-right: 4rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-25 {
  margin-bottom: 0.25rem !important;
}

.mb-50 {
  margin-bottom: 0.5rem !important;
}

.mb-75 {
  margin-bottom: 0.75rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 1.5rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 3.5rem !important;
}

.mb-5 {
  margin-bottom: 4rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-25 {
  margin-left: 0.25rem !important;
}

.ms-50 {
  margin-left: 0.5rem !important;
}

.ms-75 {
  margin-left: 0.75rem !important;
}

.ms-1 {
  margin-left: 1rem !important;
}

.ms-2 {
  margin-left: 1.5rem !important;
}

.ms-3 {
  margin-left: 3rem !important;
}

.ms-4 {
  margin-left: 3.5rem !important;
}

.ms-5 {
  margin-left: 4rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-25 {
  padding: 0.25rem !important;
}

.p-50 {
  padding: 0.5rem !important;
}

.p-75 {
  padding: 0.75rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 1.5rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.p-4 {
  padding: 3.5rem !important;
}

.p-5 {
  padding: 4rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-25 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-50 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-75 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-2 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-4 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important;
}

.px-5 {
  padding-right: 4rem !important;
  padding-left: 4rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-25 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-50 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-75 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-2 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.py-5 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-25 {
  padding-top: 0.25rem !important;
}

.pt-50 {
  padding-top: 0.5rem !important;
}

.pt-75 {
  padding-top: 0.75rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 1.5rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-4 {
  padding-top: 3.5rem !important;
}

.pt-5 {
  padding-top: 4rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-25 {
  padding-right: 0.25rem !important;
}

.pe-50 {
  padding-right: 0.5rem !important;
}

.pe-75 {
  padding-right: 0.75rem !important;
}

.pe-1 {
  padding-right: 1rem !important;
}

.pe-2 {
  padding-right: 1.5rem !important;
}

.pe-3 {
  padding-right: 3rem !important;
}

.pe-4 {
  padding-right: 3.5rem !important;
}

.pe-5 {
  padding-right: 4rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-25 {
  padding-bottom: 0.25rem !important;
}

.pb-50 {
  padding-bottom: 0.5rem !important;
}

.pb-75 {
  padding-bottom: 0.75rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 1.5rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pb-4 {
  padding-bottom: 3.5rem !important;
}

.pb-5 {
  padding-bottom: 4rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-25 {
  padding-left: 0.25rem !important;
}

.ps-50 {
  padding-left: 0.5rem !important;
}

.ps-75 {
  padding-left: 0.75rem !important;
}

.ps-1 {
  padding-left: 1rem !important;
}

.ps-2 {
  padding-left: 1.5rem !important;
}

.ps-3 {
  padding-left: 3rem !important;
}

.ps-4 {
  padding-left: 3.5rem !important;
}

.ps-5 {
  padding-left: 4rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-2 {
  font-size: calc(1.2964rem + 0.5568vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: calc(1.2536rem + 0.0432vw) !important;
}

.fs-5 {
  font-size: 1.07rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.fw-bolder {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1 !important;
}

.lh-base {
  line-height: 1.45 !important;
}

.lh-lg {
  line-height: 1.25 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #b9b9c3 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(34, 41, 47, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.357rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.25rem !important;
}

.rounded-2 {
  border-radius: 0.357rem !important;
}

.rounded-3 {
  border-radius: 0.6rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.357rem !important;
  border-top-right-radius: 0.357rem !important;
}

.rounded-end {
  border-top-right-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.357rem !important;
  border-top-left-radius: 0.357rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-25 {
    gap: 0.25rem !important;
  }

  .gap-sm-50 {
    gap: 0.5rem !important;
  }

  .gap-sm-75 {
    gap: 0.75rem !important;
  }

  .gap-sm-1 {
    gap: 1rem !important;
  }

  .gap-sm-2 {
    gap: 1.5rem !important;
  }

  .gap-sm-3 {
    gap: 3rem !important;
  }

  .gap-sm-4 {
    gap: 3.5rem !important;
  }

  .gap-sm-5 {
    gap: 4rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-25 {
    margin: 0.25rem !important;
  }

  .m-sm-50 {
    margin: 0.5rem !important;
  }

  .m-sm-75 {
    margin: 0.75rem !important;
  }

  .m-sm-1 {
    margin: 1rem !important;
  }

  .m-sm-2 {
    margin: 1.5rem !important;
  }

  .m-sm-3 {
    margin: 3rem !important;
  }

  .m-sm-4 {
    margin: 3.5rem !important;
  }

  .m-sm-5 {
    margin: 4rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-50 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-75 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-sm-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-4 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-25 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-50 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-75 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-sm-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-4 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-sm-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-25 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-50 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-75 {
    margin-top: 0.75rem !important;
  }

  .mt-sm-1 {
    margin-top: 1rem !important;
  }

  .mt-sm-2 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 3rem !important;
  }

  .mt-sm-4 {
    margin-top: 3.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 4rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-25 {
    margin-right: 0.25rem !important;
  }

  .me-sm-50 {
    margin-right: 0.5rem !important;
  }

  .me-sm-75 {
    margin-right: 0.75rem !important;
  }

  .me-sm-1 {
    margin-right: 1rem !important;
  }

  .me-sm-2 {
    margin-right: 1.5rem !important;
  }

  .me-sm-3 {
    margin-right: 3rem !important;
  }

  .me-sm-4 {
    margin-right: 3.5rem !important;
  }

  .me-sm-5 {
    margin-right: 4rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-25 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-50 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-75 {
    margin-bottom: 0.75rem !important;
  }

  .mb-sm-1 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 3.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 4rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-25 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-50 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-75 {
    margin-left: 0.75rem !important;
  }

  .ms-sm-1 {
    margin-left: 1rem !important;
  }

  .ms-sm-2 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 3rem !important;
  }

  .ms-sm-4 {
    margin-left: 3.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 4rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-25 {
    padding: 0.25rem !important;
  }

  .p-sm-50 {
    padding: 0.5rem !important;
  }

  .p-sm-75 {
    padding: 0.75rem !important;
  }

  .p-sm-1 {
    padding: 1rem !important;
  }

  .p-sm-2 {
    padding: 1.5rem !important;
  }

  .p-sm-3 {
    padding: 3rem !important;
  }

  .p-sm-4 {
    padding: 3.5rem !important;
  }

  .p-sm-5 {
    padding: 4rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-25 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-50 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-75 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-sm-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-4 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-sm-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-25 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-50 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-75 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-sm-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-4 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-sm-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-25 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-50 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-75 {
    padding-top: 0.75rem !important;
  }

  .pt-sm-1 {
    padding-top: 1rem !important;
  }

  .pt-sm-2 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 3rem !important;
  }

  .pt-sm-4 {
    padding-top: 3.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 4rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-25 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-50 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-75 {
    padding-right: 0.75rem !important;
  }

  .pe-sm-1 {
    padding-right: 1rem !important;
  }

  .pe-sm-2 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 3rem !important;
  }

  .pe-sm-4 {
    padding-right: 3.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 4rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-25 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-50 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-75 {
    padding-bottom: 0.75rem !important;
  }

  .pb-sm-1 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 3.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 4rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-25 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-50 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-75 {
    padding-left: 0.75rem !important;
  }

  .ps-sm-1 {
    padding-left: 1rem !important;
  }

  .ps-sm-2 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 3rem !important;
  }

  .ps-sm-4 {
    padding-left: 3.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 4rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-25 {
    gap: 0.25rem !important;
  }

  .gap-md-50 {
    gap: 0.5rem !important;
  }

  .gap-md-75 {
    gap: 0.75rem !important;
  }

  .gap-md-1 {
    gap: 1rem !important;
  }

  .gap-md-2 {
    gap: 1.5rem !important;
  }

  .gap-md-3 {
    gap: 3rem !important;
  }

  .gap-md-4 {
    gap: 3.5rem !important;
  }

  .gap-md-5 {
    gap: 4rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-25 {
    margin: 0.25rem !important;
  }

  .m-md-50 {
    margin: 0.5rem !important;
  }

  .m-md-75 {
    margin: 0.75rem !important;
  }

  .m-md-1 {
    margin: 1rem !important;
  }

  .m-md-2 {
    margin: 1.5rem !important;
  }

  .m-md-3 {
    margin: 3rem !important;
  }

  .m-md-4 {
    margin: 3.5rem !important;
  }

  .m-md-5 {
    margin: 4rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-50 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-75 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-md-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-4 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-md-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-25 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-50 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-75 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-md-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-4 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-md-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-25 {
    margin-top: 0.25rem !important;
  }

  .mt-md-50 {
    margin-top: 0.5rem !important;
  }

  .mt-md-75 {
    margin-top: 0.75rem !important;
  }

  .mt-md-1 {
    margin-top: 1rem !important;
  }

  .mt-md-2 {
    margin-top: 1.5rem !important;
  }

  .mt-md-3 {
    margin-top: 3rem !important;
  }

  .mt-md-4 {
    margin-top: 3.5rem !important;
  }

  .mt-md-5 {
    margin-top: 4rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-25 {
    margin-right: 0.25rem !important;
  }

  .me-md-50 {
    margin-right: 0.5rem !important;
  }

  .me-md-75 {
    margin-right: 0.75rem !important;
  }

  .me-md-1 {
    margin-right: 1rem !important;
  }

  .me-md-2 {
    margin-right: 1.5rem !important;
  }

  .me-md-3 {
    margin-right: 3rem !important;
  }

  .me-md-4 {
    margin-right: 3.5rem !important;
  }

  .me-md-5 {
    margin-right: 4rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-25 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-50 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-75 {
    margin-bottom: 0.75rem !important;
  }

  .mb-md-1 {
    margin-bottom: 1rem !important;
  }

  .mb-md-2 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 3rem !important;
  }

  .mb-md-4 {
    margin-bottom: 3.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 4rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-25 {
    margin-left: 0.25rem !important;
  }

  .ms-md-50 {
    margin-left: 0.5rem !important;
  }

  .ms-md-75 {
    margin-left: 0.75rem !important;
  }

  .ms-md-1 {
    margin-left: 1rem !important;
  }

  .ms-md-2 {
    margin-left: 1.5rem !important;
  }

  .ms-md-3 {
    margin-left: 3rem !important;
  }

  .ms-md-4 {
    margin-left: 3.5rem !important;
  }

  .ms-md-5 {
    margin-left: 4rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-25 {
    padding: 0.25rem !important;
  }

  .p-md-50 {
    padding: 0.5rem !important;
  }

  .p-md-75 {
    padding: 0.75rem !important;
  }

  .p-md-1 {
    padding: 1rem !important;
  }

  .p-md-2 {
    padding: 1.5rem !important;
  }

  .p-md-3 {
    padding: 3rem !important;
  }

  .p-md-4 {
    padding: 3.5rem !important;
  }

  .p-md-5 {
    padding: 4rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-25 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-50 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-75 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-md-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-4 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-md-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-25 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-50 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-75 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-md-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-4 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-md-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-25 {
    padding-top: 0.25rem !important;
  }

  .pt-md-50 {
    padding-top: 0.5rem !important;
  }

  .pt-md-75 {
    padding-top: 0.75rem !important;
  }

  .pt-md-1 {
    padding-top: 1rem !important;
  }

  .pt-md-2 {
    padding-top: 1.5rem !important;
  }

  .pt-md-3 {
    padding-top: 3rem !important;
  }

  .pt-md-4 {
    padding-top: 3.5rem !important;
  }

  .pt-md-5 {
    padding-top: 4rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-25 {
    padding-right: 0.25rem !important;
  }

  .pe-md-50 {
    padding-right: 0.5rem !important;
  }

  .pe-md-75 {
    padding-right: 0.75rem !important;
  }

  .pe-md-1 {
    padding-right: 1rem !important;
  }

  .pe-md-2 {
    padding-right: 1.5rem !important;
  }

  .pe-md-3 {
    padding-right: 3rem !important;
  }

  .pe-md-4 {
    padding-right: 3.5rem !important;
  }

  .pe-md-5 {
    padding-right: 4rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-25 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-50 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-75 {
    padding-bottom: 0.75rem !important;
  }

  .pb-md-1 {
    padding-bottom: 1rem !important;
  }

  .pb-md-2 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 3rem !important;
  }

  .pb-md-4 {
    padding-bottom: 3.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 4rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-25 {
    padding-left: 0.25rem !important;
  }

  .ps-md-50 {
    padding-left: 0.5rem !important;
  }

  .ps-md-75 {
    padding-left: 0.75rem !important;
  }

  .ps-md-1 {
    padding-left: 1rem !important;
  }

  .ps-md-2 {
    padding-left: 1.5rem !important;
  }

  .ps-md-3 {
    padding-left: 3rem !important;
  }

  .ps-md-4 {
    padding-left: 3.5rem !important;
  }

  .ps-md-5 {
    padding-left: 4rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-25 {
    gap: 0.25rem !important;
  }

  .gap-lg-50 {
    gap: 0.5rem !important;
  }

  .gap-lg-75 {
    gap: 0.75rem !important;
  }

  .gap-lg-1 {
    gap: 1rem !important;
  }

  .gap-lg-2 {
    gap: 1.5rem !important;
  }

  .gap-lg-3 {
    gap: 3rem !important;
  }

  .gap-lg-4 {
    gap: 3.5rem !important;
  }

  .gap-lg-5 {
    gap: 4rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-25 {
    margin: 0.25rem !important;
  }

  .m-lg-50 {
    margin: 0.5rem !important;
  }

  .m-lg-75 {
    margin: 0.75rem !important;
  }

  .m-lg-1 {
    margin: 1rem !important;
  }

  .m-lg-2 {
    margin: 1.5rem !important;
  }

  .m-lg-3 {
    margin: 3rem !important;
  }

  .m-lg-4 {
    margin: 3.5rem !important;
  }

  .m-lg-5 {
    margin: 4rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-50 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-75 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-lg-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-4 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-25 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-50 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-75 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-lg-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-4 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-lg-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-25 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-50 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-75 {
    margin-top: 0.75rem !important;
  }

  .mt-lg-1 {
    margin-top: 1rem !important;
  }

  .mt-lg-2 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 3rem !important;
  }

  .mt-lg-4 {
    margin-top: 3.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 4rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-25 {
    margin-right: 0.25rem !important;
  }

  .me-lg-50 {
    margin-right: 0.5rem !important;
  }

  .me-lg-75 {
    margin-right: 0.75rem !important;
  }

  .me-lg-1 {
    margin-right: 1rem !important;
  }

  .me-lg-2 {
    margin-right: 1.5rem !important;
  }

  .me-lg-3 {
    margin-right: 3rem !important;
  }

  .me-lg-4 {
    margin-right: 3.5rem !important;
  }

  .me-lg-5 {
    margin-right: 4rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-25 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-50 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-75 {
    margin-bottom: 0.75rem !important;
  }

  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 3.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 4rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-25 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-50 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-75 {
    margin-left: 0.75rem !important;
  }

  .ms-lg-1 {
    margin-left: 1rem !important;
  }

  .ms-lg-2 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 3rem !important;
  }

  .ms-lg-4 {
    margin-left: 3.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 4rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-25 {
    padding: 0.25rem !important;
  }

  .p-lg-50 {
    padding: 0.5rem !important;
  }

  .p-lg-75 {
    padding: 0.75rem !important;
  }

  .p-lg-1 {
    padding: 1rem !important;
  }

  .p-lg-2 {
    padding: 1.5rem !important;
  }

  .p-lg-3 {
    padding: 3rem !important;
  }

  .p-lg-4 {
    padding: 3.5rem !important;
  }

  .p-lg-5 {
    padding: 4rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-25 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-50 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-75 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-lg-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-4 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-lg-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-25 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-50 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-75 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-lg-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-4 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-lg-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-25 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-50 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-75 {
    padding-top: 0.75rem !important;
  }

  .pt-lg-1 {
    padding-top: 1rem !important;
  }

  .pt-lg-2 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 3rem !important;
  }

  .pt-lg-4 {
    padding-top: 3.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 4rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-25 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-50 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-75 {
    padding-right: 0.75rem !important;
  }

  .pe-lg-1 {
    padding-right: 1rem !important;
  }

  .pe-lg-2 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 3rem !important;
  }

  .pe-lg-4 {
    padding-right: 3.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 4rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-25 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-50 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-75 {
    padding-bottom: 0.75rem !important;
  }

  .pb-lg-1 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 3.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 4rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-25 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-50 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-75 {
    padding-left: 0.75rem !important;
  }

  .ps-lg-1 {
    padding-left: 1rem !important;
  }

  .ps-lg-2 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 3rem !important;
  }

  .ps-lg-4 {
    padding-left: 3.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 4rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-25 {
    gap: 0.25rem !important;
  }

  .gap-xl-50 {
    gap: 0.5rem !important;
  }

  .gap-xl-75 {
    gap: 0.75rem !important;
  }

  .gap-xl-1 {
    gap: 1rem !important;
  }

  .gap-xl-2 {
    gap: 1.5rem !important;
  }

  .gap-xl-3 {
    gap: 3rem !important;
  }

  .gap-xl-4 {
    gap: 3.5rem !important;
  }

  .gap-xl-5 {
    gap: 4rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-25 {
    margin: 0.25rem !important;
  }

  .m-xl-50 {
    margin: 0.5rem !important;
  }

  .m-xl-75 {
    margin: 0.75rem !important;
  }

  .m-xl-1 {
    margin: 1rem !important;
  }

  .m-xl-2 {
    margin: 1.5rem !important;
  }

  .m-xl-3 {
    margin: 3rem !important;
  }

  .m-xl-4 {
    margin: 3.5rem !important;
  }

  .m-xl-5 {
    margin: 4rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-50 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-75 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xl-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-4 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-25 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-50 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-75 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xl-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-4 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xl-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-25 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-50 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-75 {
    margin-top: 0.75rem !important;
  }

  .mt-xl-1 {
    margin-top: 1rem !important;
  }

  .mt-xl-2 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 3rem !important;
  }

  .mt-xl-4 {
    margin-top: 3.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 4rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-25 {
    margin-right: 0.25rem !important;
  }

  .me-xl-50 {
    margin-right: 0.5rem !important;
  }

  .me-xl-75 {
    margin-right: 0.75rem !important;
  }

  .me-xl-1 {
    margin-right: 1rem !important;
  }

  .me-xl-2 {
    margin-right: 1.5rem !important;
  }

  .me-xl-3 {
    margin-right: 3rem !important;
  }

  .me-xl-4 {
    margin-right: 3.5rem !important;
  }

  .me-xl-5 {
    margin-right: 4rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-25 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-50 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-75 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 4rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-25 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-50 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-75 {
    margin-left: 0.75rem !important;
  }

  .ms-xl-1 {
    margin-left: 1rem !important;
  }

  .ms-xl-2 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 3rem !important;
  }

  .ms-xl-4 {
    margin-left: 3.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 4rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-25 {
    padding: 0.25rem !important;
  }

  .p-xl-50 {
    padding: 0.5rem !important;
  }

  .p-xl-75 {
    padding: 0.75rem !important;
  }

  .p-xl-1 {
    padding: 1rem !important;
  }

  .p-xl-2 {
    padding: 1.5rem !important;
  }

  .p-xl-3 {
    padding: 3rem !important;
  }

  .p-xl-4 {
    padding: 3.5rem !important;
  }

  .p-xl-5 {
    padding: 4rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-25 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-50 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-75 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xl-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-4 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xl-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-25 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-50 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-75 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xl-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-4 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xl-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-25 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-50 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-75 {
    padding-top: 0.75rem !important;
  }

  .pt-xl-1 {
    padding-top: 1rem !important;
  }

  .pt-xl-2 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 3rem !important;
  }

  .pt-xl-4 {
    padding-top: 3.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 4rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-25 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-50 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-75 {
    padding-right: 0.75rem !important;
  }

  .pe-xl-1 {
    padding-right: 1rem !important;
  }

  .pe-xl-2 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 3rem !important;
  }

  .pe-xl-4 {
    padding-right: 3.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 4rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-25 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-50 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-75 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xl-1 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 4rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-25 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-50 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-75 {
    padding-left: 0.75rem !important;
  }

  .ps-xl-1 {
    padding-left: 1rem !important;
  }

  .ps-xl-2 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 3rem !important;
  }

  .ps-xl-4 {
    padding-left: 3.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 4rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1440px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-25 {
    gap: 0.25rem !important;
  }

  .gap-xxl-50 {
    gap: 0.5rem !important;
  }

  .gap-xxl-75 {
    gap: 0.75rem !important;
  }

  .gap-xxl-1 {
    gap: 1rem !important;
  }

  .gap-xxl-2 {
    gap: 1.5rem !important;
  }

  .gap-xxl-3 {
    gap: 3rem !important;
  }

  .gap-xxl-4 {
    gap: 3.5rem !important;
  }

  .gap-xxl-5 {
    gap: 4rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-25 {
    margin: 0.25rem !important;
  }

  .m-xxl-50 {
    margin: 0.5rem !important;
  }

  .m-xxl-75 {
    margin: 0.75rem !important;
  }

  .m-xxl-1 {
    margin: 1rem !important;
  }

  .m-xxl-2 {
    margin: 1.5rem !important;
  }

  .m-xxl-3 {
    margin: 3rem !important;
  }

  .m-xxl-4 {
    margin: 3.5rem !important;
  }

  .m-xxl-5 {
    margin: 4rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-25 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-50 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-75 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }

  .mx-xxl-1 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-2 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-4 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 4rem !important;
    margin-left: 4rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-25 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-50 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-75 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .my-xxl-1 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-4 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-25 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-50 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-75 {
    margin-top: 0.75rem !important;
  }

  .mt-xxl-1 {
    margin-top: 1rem !important;
  }

  .mt-xxl-2 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 3rem !important;
  }

  .mt-xxl-4 {
    margin-top: 3.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 4rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-25 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-50 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-75 {
    margin-right: 0.75rem !important;
  }

  .me-xxl-1 {
    margin-right: 1rem !important;
  }

  .me-xxl-2 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 3rem !important;
  }

  .me-xxl-4 {
    margin-right: 3.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 4rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-25 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-50 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-75 {
    margin-bottom: 0.75rem !important;
  }

  .mb-xxl-1 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 3.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 4rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-25 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-50 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-75 {
    margin-left: 0.75rem !important;
  }

  .ms-xxl-1 {
    margin-left: 1rem !important;
  }

  .ms-xxl-2 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 3rem !important;
  }

  .ms-xxl-4 {
    margin-left: 3.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 4rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-25 {
    padding: 0.25rem !important;
  }

  .p-xxl-50 {
    padding: 0.5rem !important;
  }

  .p-xxl-75 {
    padding: 0.75rem !important;
  }

  .p-xxl-1 {
    padding: 1rem !important;
  }

  .p-xxl-2 {
    padding: 1.5rem !important;
  }

  .p-xxl-3 {
    padding: 3rem !important;
  }

  .p-xxl-4 {
    padding: 3.5rem !important;
  }

  .p-xxl-5 {
    padding: 4rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-25 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-50 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-75 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }

  .px-xxl-1 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-4 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-25 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-50 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-75 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .py-xxl-1 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-2 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-4 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-25 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-50 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-75 {
    padding-top: 0.75rem !important;
  }

  .pt-xxl-1 {
    padding-top: 1rem !important;
  }

  .pt-xxl-2 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 3rem !important;
  }

  .pt-xxl-4 {
    padding-top: 3.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 4rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-25 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-50 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-75 {
    padding-right: 0.75rem !important;
  }

  .pe-xxl-1 {
    padding-right: 1rem !important;
  }

  .pe-xxl-2 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 3rem !important;
  }

  .pe-xxl-4 {
    padding-right: 3.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 4rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-25 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-50 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-75 {
    padding-bottom: 0.75rem !important;
  }

  .pb-xxl-1 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 3.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 4rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-25 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-50 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-75 {
    padding-left: 0.75rem !important;
  }

  .ps-xxl-1 {
    padding-left: 1rem !important;
  }

  .ps-xxl-2 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 3rem !important;
  }

  .ps-xxl-4 {
    padding-left: 3.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 4rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2rem !important;
  }

  .fs-2 {
    font-size: 1.714rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }

  .fs-4 {
    font-size: 1.286rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
a:focus {
  outline: none;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

.list-style-icons {
  padding-left: 10px;
  margin-left: 0;
  list-style: none;
}
.list-style-icons > li svg,
.list-style-icons > li i {
  margin-right: 6px;
}

.pull-up {
  transition: all 0.25s ease;
}
.pull-up:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
  z-index: 30;
}

.spinner {
  display: inline-block;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-reverse {
  display: inline-block;
  animation: spin-reverse 1s linear infinite;
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.bullet {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-block;
}
.bullet.bullet-xs {
  width: 0.5rem;
  height: 0.5rem;
}
.bullet.bullet-sm {
  width: 0.714rem;
  height: 0.714rem;
}
.bullet.bullet-lg {
  width: 1.25rem;
  height: 1.25rem;
}

.section-label {
  font-size: 0.85rem;
  color: #b9b9c3;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}

.cell-fit {
  width: 0.1%;
  white-space: nowrap;
}

.match-height > [class*=col] {
  display: flex;
  flex-flow: column;
}
.match-height > [class*=col] > .card {
  flex: 1 1 auto;
}

.text-body-heading {
  color: #5e5873;
}
.text-body-heading:hover {
  color: inherit !important;
}

.text-body[href]:hover {
  color: #b47c29 !important;
}

code {
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d63384;
  background-color: #eee;
  border-radius: 0.1785rem;
}

pre {
  background-color: #f7f7f9;
}
pre code {
  background-color: transparent !important;
}

.table-white-space th,
.table-white-space td {
  white-space: nowrap;
}

.table thead th,
.table tfoot th {
  vertical-align: top;
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
}
.table.table-sm th,
.table.table-sm td {
  padding: 0.3rem 0.5rem;
}
.table.table-sm th:first-child,
.table.table-sm td:first-child {
  padding-left: 0.75rem;
}
.table.table-dark tr > * {
  border-bottom-color: #3b4253 !important;
}

.table:not(.table-dark):not(.table-light) thead:not(.table-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.table-dark) th {
  background-color: #f3f2f7;
}

.table-hover tbody tr {
  cursor: pointer;
}

.card .table {
  margin-bottom: 0;
  border-bottom-left-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
}
.card .table tbody tr:last-child > * {
  border-bottom-width: 0;
}
.card .table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.357rem;
}
.card .table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.357rem;
}

.table.table-flush-spacing thead tr > td:first-child,
.table.table-flush-spacing tbody tr > td:first-child {
  padding-left: 0;
}
.table.table-flush-spacing thead tr > td:last-child,
.table.table-flush-spacing tbody tr > td:last-child {
  padding-right: 0;
}

.col-form-label,
.col-form-label-lg,
.col-form-label-sm {
  font-size: 0.857rem;
  align-self: center;
}

.form-control::placeholder {
  transition: all 0.2s ease;
}
.form-control:focus::placeholder {
  transform: translate(5px);
  transition: all 0.2s ease;
}
.form-control:focus:valid, .form-control:focus.is-valid {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
}

.form-control-plaintext:focus {
  outline: none;
}

textarea.form-control {
  line-height: 1.6rem;
  padding: 0.8rem 1rem !important;
}
textarea.form-control-sm {
  padding: 0.4rem 0.857rem !important;
}
textarea.form-control-lg {
  padding: 1rem 1.143rem !important;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.357rem;
}
.input-group:not(.bootstrap-touchspin):focus-within .form-control,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #bd832b;
  box-shadow: none;
}
.input-group:not(.bootstrap-touchspin):focus-within .form-control.is-valid,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text.is-valid {
  border-color: #28c76f;
}
.input-group:not(.bootstrap-touchspin):focus-within .form-control.is-invalid,
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text.is-invalid {
  border-color: #ea5455;
}
.input-group.is-valid .input-group-text {
  border-color: #28c76f !important;
}
.input-group.is-valid:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #28c76f;
}
.input-group.is-invalid .input-group-text {
  border-color: #ea5455 !important;
}
.input-group.is-invalid:not(.bootstrap-touchspin):focus-within .input-group-text {
  border-color: #ea5455;
}
.input-group.disabled .input-group-text {
  background-color: #efefef;
}
.input-group.round .form-control,
.input-group.round .input-group-text, .input-group.round:focus-within {
  border-radius: 1.5rem;
}
.input-group.square .form-control,
.input-group.square .input-group-text, .input-group.square:focus-within {
  border-radius: 0;
}

.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .input-group-text {
    transition: none;
  }
}

.input-group-merge .form-control:not(:first-child) {
  padding-left: 0;
  border-left: 0;
}
.input-group-merge .form-control:not(:last-child) {
  padding-right: 0;
  border-right: 0;
}
.input-group-merge .form-control.is-valid .input-group-text {
  border-color: #28c76f;
}

.form-check:not(.form-switch) .form-check-input[type=checkbox]:checked {
  background-size: 65%;
}
.form-check:not(.form-switch) .form-check-input:disabled:not(:checked) {
  background-color: #efefef;
  border-color: #efefef;
  opacity: 1;
}

.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgba(189, 131, 43, 0.4);
}
.form-check-input:not(:disabled):indeterminate {
  background-size: 85%;
}

/* Switches */
.form-switch {
  position: relative;
  /*For Switch Handle Animation*/
}
.form-switch .form-check-input {
  height: 1.7rem;
}
.form-switch .form-check-input:checked {
  box-shadow: none !important;
}
.form-switch .form-check-input {
  border: none;
}
.form-switch .form-check-input:not(:checked) {
  background-color: #e2e2e2;
}
.form-switch .form-check-input:active {
  filter: none;
}
.form-switch .form-check-input:not(:disabled):checked, .form-switch .form-check-input:not(:disabled):focus {
  box-shadow: none !important;
}
.form-switch .form-check-label {
  line-height: 1.7rem;
  /*For Switch text*/
}
.form-switch .form-check-label .switch-text-left,
.form-switch .form-check-label .switch-text-right,
.form-switch .form-check-label .switch-icon-left,
.form-switch .form-check-label .switch-icon-right {
  position: absolute;
  user-select: none;
  line-height: 1.65;
}
.form-switch .form-check-label .switch-text-left i,
.form-switch .form-check-label .switch-text-left svg,
.form-switch .form-check-label .switch-text-right i,
.form-switch .form-check-label .switch-text-right svg,
.form-switch .form-check-label .switch-icon-left i,
.form-switch .form-check-label .switch-icon-left svg,
.form-switch .form-check-label .switch-icon-right i,
.form-switch .form-check-label .switch-icon-right svg {
  height: 13px;
  width: 13px;
  font-size: 13px;
}
.form-switch .form-check-label .switch-text-left,
.form-switch .form-check-label .switch-icon-left {
  left: 6px;
  top: 0;
  color: #fff;
  opacity: 0;
  transform: translateX(8px);
  transition: opacity 0.1s ease, transform 0.15s ease;
}
.form-switch .form-check-label .switch-text-right,
.form-switch .form-check-label .switch-icon-right {
  left: 25px;
  top: 0;
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 0.08s ease, transform 0.15s ease;
}
.form-switch .form-check-label:focus {
  outline: 0;
}
.form-switch .form-check-input:checked ~ .form-check-label::before {
  box-shadow: none;
}
.form-switch .form-check-input:checked ~ .form-check-label::after {
  transform: translateX(1.4rem);
}
.form-switch .form-check-input:checked ~ .form-check-label .switch-text-left,
.form-switch .form-check-input:checked ~ .form-check-label .switch-icon-left {
  transform: translateX(0);
  opacity: 1;
}
.form-switch .form-check-input:checked ~ .form-check-label .switch-text-right,
.form-switch .form-check-input:checked ~ .form-check-label .switch-icon-right {
  transform: translateX(-8px);
  opacity: 0;
}
.form-switch .form-check-input:not(:checked) ~ .form-check-label .switch-text-left {
  opacity: 0;
}
.form-switch .form-check-input:not(:checked) ~ .form-check-label .switch-text-right {
  opacity: 1;
}
.form-switch .form-check-input:checked ~ .form-check-label .switch-text-right {
  opacity: 0;
}
.form-switch .form-check-input:checked ~ .form-check-label .switch-text-left {
  opacity: 1;
}

.form-floating > .form-control:focus ~ label {
  color: #bd832b;
}

.form-floating > label {
  opacity: 0.65;
}

/* Textarea with Counter */
.textarea-counter-value {
  background-color: #bd832b;
  color: #fff;
  padding: 1px 6px;
  font-size: 0.6rem;
  border-radius: 0 0 5px 5px;
  margin-right: 1rem;
}

.btn.disabled-max-min,
.btn.disabled-max-min:focus,
.btn.disabled-max-min:active {
  background-color: rgba(34, 41, 47, 0.5) !important;
  cursor: default;
}

.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down,
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up,
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down,
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up {
  border-color: transparent !important;
}

/* Number Type Input Box Scss for - Remove arrow on hover */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.picker__input.form-control {
  background-color: #fff;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
  -webkit-text-fill-color: #6e6b7b !important;
}

.btn {
  box-shadow: none;
  font-weight: 500;
}
.btn[class*=bg-gradient-] {
  transition: all 0.2s ease;
}
.btn[class*=bg-gradient-]:hover {
  transform: translateY(-2px);
}
.btn.btn-icon {
  padding: 0.715rem 0.736rem;
}
.btn.btn-sm.btn-icon, .btn-group-sm > .btn.btn-icon {
  padding: 0.5rem;
  line-height: 0.5;
}
.btn.btn-lg.btn-icon, .btn-group-lg > .btn.btn-icon {
  padding: 1rem;
  line-height: 0.75;
}
.btn:focus, .btn.focus, .btn:active, .btn.active {
  outline: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active:focus, .btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: none !important;
}
.btn .feather {
  vertical-align: bottom;
}

.btn-check:checked + [class*=btn-], .btn-check:active + [class*=btn-] {
  outline: none;
  box-shadow: none;
}

.btn.waves-input-wrapper {
  padding: 0;
}

.btn-pinned {
  position: absolute;
  top: 1.2rem;
  right: 0.7rem;
}

@media (max-width: 767.98px) {
  .btn-sm-block {
    display: block;
    width: 100%;
  }
}
.waves-effect {
  cursor: inherit;
}

.dropdown-toggle-split {
  padding-right: 0.7894736842rem;
  padding-left: 0.7894736842rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.1428571429rem;
  padding-left: 1.1428571429rem;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5263157895rem;
  padding-left: 0.5263157895rem;
}

.btn-group [class*=btn-]:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]) {
  border-left-color: rgba(34, 41, 47, 0.08) !important;
  border-right-color: rgba(34, 41, 47, 0.08) !important;
}

.btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-child,
.btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):first-of-type {
  border-left-color: transparent !important;
}

.btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-child,
.btn-group > .btn:not([class*=btn-outline-]):not([class*=btn-flat-]):not([class*=btn-gradient-]):not([class*=btn-relief-]):last-of-type {
  border-right-color: transparent !important;
}

.btn-group-toggle [class*=btn-outline-]:not(:last-child) {
  border-right-width: 0 !important;
}
.btn-group-toggle :not([class*=btn-outline-]).active, .btn-group-toggle :not([class*=btn-outline-]):active {
  box-shadow: inset 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.dropdown [class*=btn-outline-].dropdown-toggle.dropdown-toggle-split {
  border-left: 0 !important;
}
.dropdown.no-arrow .dropdown-toggle:after {
  display: none;
}
.dropdown.no-arrow .dropdown-toggle i,
.dropdown.no-arrow .dropdown-toggle svg {
  margin-right: 0;
}

.dropdown-menu {
  transform: scale(1, 0);
  box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
}
.dropdown-menu .dropdown-item {
  width: auto;
  cursor: pointer;
}
.dropdown-menu .dropdown-header {
  font-weight: 500;
  line-height: 1;
}

.show > .dropdown-menu {
  transform: scale(1, 1);
  opacity: 1;
  display: block;
}
.show .dropdown-toggle:focus {
  box-shadow: none;
}
.show .btn.dropdown-toggle:focus {
  box-shadow: none;
}

.dropdown-toggle::after {
  border: none !important;
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  color: #6e6b7b;
  width: 14px;
  height: 11px;
  position: relative;
  top: 1px;
  right: 0px;
  left: 0.714rem;
  padding: 0;
  margin: 0;
  vertical-align: 0;
}
.dropdown-toggle.btn-lg::after, .btn-group-lg > .dropdown-toggle.btn::after {
  background-size: 18px;
}
.dropdown-toggle.btn-sm::after, .btn-group-sm > .dropdown-toggle.btn::after {
  background-size: 14px;
}
.dropdown-toggle.dropdown-toggle-split:after {
  left: 0;
}
.dropdown-toggle.nav-hide-arrow::after {
  display: none;
}
.dropdown-toggle:focus {
  outline: 0;
}

.dropup {
  position: relative;
}
.dropup .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 14px;
  height: 11px;
  content: "";
  vertical-align: 0.05rem;
}
.dropup .dropdown-menu {
  min-width: 8rem;
}

.dropstart .dropdown-toggle::before {
  border: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 14px;
  height: 11px;
  content: "";
  position: relative;
  left: 0;
}
.dropstart .dropdown-menu {
  min-width: 8rem;
}

.dropend .dropdown-toggle::after {
  border: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 14px;
  height: 11px;
  content: "";
}
.dropend .dropdown-menu {
  min-width: 8rem;
}

.dropdown-menu[data-popper-placement=bottom-start], .dropdown-menu[data-popper-placement=bottom-end] {
  margin-top: 0.4rem !important;
}
.dropdown-menu[data-popper-placement=top-start], .dropdown-menu[data-popper-placement=top-end] {
  margin-bottom: 0.4rem !important;
}
.dropdown-menu[data-popper-placement=right-start], .dropdown-menu[data-popper-placement=right-end] {
  margin-left: 0.4rem !important;
}
.dropdown-menu[data-popper-placement=left-start], .dropdown-menu[data-popper-placement=left-end] {
  margin-right: 0.4rem !important;
}

.dropdown.dropdown-icon-wrapper .dropdown-toggle:after,
.dropup.dropdown-icon-wrapper .dropdown-toggle:after {
  display: none;
}
.dropdown.dropdown-icon-wrapper .dropdown-menu,
.dropup.dropdown-icon-wrapper .dropdown-menu {
  min-width: auto;
}
.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item {
  padding: 0.5rem 1.1rem;
  cursor: pointer;
}
.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item i,
.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item svg,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item i,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item svg {
  height: 1.3rem;
  width: 1.3rem;
  font-size: 1.3rem;
}

.horizontal-menu-wrapper .dropdown-menu,
.header-navbar .dropdown-menu {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideIn;
}

.dropdown-toggle.hide-arrow::before, .dropdown-toggle.hide-arrow::after,
.dropdown-toggle-hide-arrow > .dropdown-toggle::before,
.dropdown-toggle-hide-arrow > .dropdown-toggle::after {
  display: none;
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
/* ===============================================================================================
    File Name: navbar.scss
    Description: Contain header navigation bar, vertical main navigation bar and
    horiznotal main navigation bar related SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/
.header-navbar {
  padding: 0px;
  min-height: 4.45rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  transition: 300ms ease all, background 0s;
  z-index: 997;
}
.header-navbar.fixed-top, .header-navbar.floating-nav {
  left: 260px;
}
.header-navbar[class*=bg-] .navbar-nav .nav-item > a {
  color: #fff !important;
}
.header-navbar[class*=bg-] .navbar-nav .nav-item > a i,
.header-navbar[class*=bg-] .navbar-nav .nav-item > a svg,
.header-navbar[class*=bg-] .navbar-nav .nav-item > a span {
  color: #fff !important;
}
.header-navbar[class*=bg-] .navbar-nav .nav-item .dropdown-menu i,
.header-navbar[class*=bg-] .navbar-nav .nav-item .dropdown-menu svg {
  color: inherit !important;
}
.header-navbar[class*=bg-] .navbar-nav .search-input .input,
.header-navbar[class*=bg-] .navbar-nav .search-input .search-list .auto-suggestion span {
  color: #6e6b7b !important;
}
.header-navbar.floating-nav {
  position: fixed;
  right: 0;
  margin: 1.3rem auto 0;
  width: calc(100vw - (100vw - 100%) - calc(2rem * 2) - 260px);
  border-radius: 0.428rem;
  z-index: 12;
}
.header-navbar.navbar-static-top {
  top: 0;
  right: 0;
  left: 260px;
  width: calc(100vw - (100vw - 100%) - 260px);
  background: transparent;
  box-shadow: none !important;
}
.header-navbar.navbar-border {
  border-bottom: 1px solid #ebe9f1;
}
.header-navbar.navbar-shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.header-navbar.navbar-dark.navbar-border {
  border-bottom: 1px solid #3b4253;
}
.header-navbar:not(.navbar-horizontal) .nav-link.dropdown-toggle::after {
  display: none;
}
.header-navbar .navbar-container {
  padding: 0.8rem 1rem;
  flex-basis: 100%;
  transition: 300ms ease all;
  margin-left: 0;
}
@media (min-width: 576px) {
  .header-navbar .navbar-container {
    padding: 0.8rem 1.4rem;
  }
}
.header-navbar .navbar-container .bookmark-wrapper .bookmark-input {
  z-index: 1;
}
.header-navbar .navbar-container .dropdown-language .selected-language {
  font-weight: 500;
}
.header-navbar .navbar-container .dropdown-language .nav-link .flag-icon {
  margin-right: 0.4rem;
}
.header-navbar .navbar-container .dropdown-language .dropdown-menu .dropdown-item .flag-icon {
  margin-right: 0.4rem;
}
.header-navbar .navbar-container ul.navbar-nav li {
  line-height: 1.5;
}
.vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu {
  top: 41px !important;
  right: 0;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-language .dropdown-menu.dropdown-menu-end {
  right: -2px;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .badge.badge-up, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .badge.badge-up {
  right: -3px;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-end, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-end {
  right: -2px;
  padding: 0;
  left: inherit;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu.dropdown-menu-end::before, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu.dropdown-menu-end::before {
  background: #bd832b;
  border-color: #bd832b;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-header, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-header {
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-header .dropdown-header, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-header .dropdown-header {
  padding: 1.22rem 1.28rem;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .notification-text, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .notification-text {
  margin-bottom: 0.5rem;
  font-size: smaller;
  color: #b9b9c3;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .dropdown-menu-footer, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .dropdown-menu-footer {
  padding: 1.28rem;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .list-item-body .media-heading {
  color: #6e6b7b;
  margin-bottom: 0;
  line-height: 1.2;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .list-item {
  position: relative;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .list-item img {
  background: #f8f8f8;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .list-item:hover .cart-item-remove {
  visibility: visible;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading {
  width: 8rem;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading h6.cart-item-title, .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading .cart-item-title.h6 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .media-heading .cart-item-by {
  color: #b9b9c3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .list-item-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-price {
  margin-bottom: 0;
  width: 5rem;
  text-align: right;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-remove {
  position: absolute;
  top: 10px;
  right: 17px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .cart-item-remove {
    visibility: hidden;
  }
}
.header-navbar .navbar-container ul.navbar-nav li > a.nav-link {
  color: #6e6b7b;
  padding: 0 0.5rem;
  position: relative;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user {
  line-height: 1 !important;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
  margin-top: 10px;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu i,
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu svg {
  height: 16px;
  width: 16px;
  font-size: 16px;
  vertical-align: top;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link {
  display: flex;
  align-items: center;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name {
  display: inline-block;
  margin-bottom: 0.435rem;
  margin-left: 0.2rem;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-status {
  font-size: smaller;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link img {
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12), 0 2px 4px 0 rgba(34, 41, 47, 0.08);
}
.header-navbar .navbar-container ul.navbar-nav li a.menu-toggle i,
.header-navbar .navbar-container ul.navbar-nav li a.menu-toggle svg {
  height: 1.6rem;
  width: 1.6rem;
  font-size: 1.6rem;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  float: left;
  margin-right: 0.8rem;
}
.header-navbar .navbar-container ul.navbar-nav li div.input-group {
  padding: 0.7rem 1rem;
}
.header-navbar .navbar-container ul.navbar-nav li i.ficon,
.header-navbar .navbar-container ul.navbar-nav li svg.ficon {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1.5rem;
  color: #6e6b7b;
}
.header-navbar .navbar-container ul.navbar-nav li i.ficon:hover,
.header-navbar .navbar-container ul.navbar-nav li svg.ficon:hover {
  color: #bd832b;
}
.header-navbar .navbar-container ul.navbar-nav li .media-list {
  max-height: 25rem;
}
.header-navbar .navbar-container ul.navbar-nav li .scrollable-container {
  position: relative;
}
.header-navbar .navbar-container .dropdown-menu-media {
  width: 30rem;
}
.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-header {
  border-bottom: 1px solid #ebe9f1;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .list-item {
  display: flex;
  flex-grow: 1;
  padding: 0.9rem 1.28rem;
  border: none;
  border-bottom: 1px solid #ebe9f1;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .list-item:hover {
  background: #f8f8f8;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .list-item .media-meta {
  color: #6e6b7b;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list a:last-of-type .list-item {
  border-bottom: none;
}
.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-footer {
  border-top: 1px solid #ebe9f1;
}

.header-navbar-shadow {
  display: none;
}

.navbar-floating .navbar-container:not(.main-menu-content) {
  padding: 0.8rem 1rem;
}
.navbar-floating .header-navbar-shadow {
  display: block;
  background: linear-gradient(180deg, rgba(248, 248, 248, 0.95) 44%, rgba(248, 248, 248, 0.46) 73%, rgba(255, 255, 255, 0));
  padding-top: 2.2rem;
  background-repeat: repeat;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
}

.navbar-light {
  background: #fff;
}
.navbar-light.navbar-horizontal {
  background: #fff;
}
.navbar-light .navbar-nav .active.nav-link {
  background-color: rgba(34, 41, 47, 0.03);
}
.navbar-light .navbar-nav .disabled.nav-link {
  color: #b9b9c3;
}

.navbar-dark {
  background: #b8c2cc;
}
.navbar-dark .navbar-brand {
  color: #fff !important;
}
.navbar-dark.navbar-horizontal {
  background: #b8c2cc;
}
.navbar-dark .nav-search .form-control,
.navbar-dark .nav-search .btn-secondary {
  color: #fff;
  background: #b8c2cc;
}
.navbar-dark .navbar-nav li {
  line-height: 1;
}
.navbar-dark .navbar-nav .active.nav-link {
  background-color: rgba(255, 255, 255, 0.05);
}
.navbar-dark .navbar-nav .disabled.nav-link {
  color: #dae1e7;
}

@media (max-width: 767.98px) {
  .header-navbar .navbar-nav .show {
    position: static;
  }
  .header-navbar .navbar-nav .open-navbar-container {
    padding-top: 0.625rem;
  }
  .header-navbar .dropup,
.header-navbar .dropend,
.header-navbar .dropdown,
.header-navbar .dropstart {
    position: static;
  }
  .header-navbar .navbar-container .show ~ .dropdown-menu {
    right: 0;
    left: 0 !important;
    float: none;
    width: auto !important;
    margin-top: 0;
    overflow: hidden;
  }
  .header-navbar .navbar-container .dropdown-user .dropdown-menu-end {
    right: 0 !important;
  }
  .header-navbar .navbar-container ul.navbar-nav li .dropdown-toggle::after {
    margin-right: 0;
    margin-left: -2px;
  }

  .navbar-dark .navbar-header .navbar-nav .nav-link {
    color: #fff;
  }
  .navbar-dark .navbar-container .navbar-nav .nav-link {
    color: #b8c2cc;
  }

  .navbar-light .navbar-header .navbar-nav .nav-link {
    color: #b8c2cc;
  }
  .navbar-light .navbar-container .navbar-nav .nav-link {
    color: #b8c2cc;
  }
}
@media (max-width: 1199.98px) {
  .header-navbar.floating-nav {
    width: calc(100vw - (100vw - 100%) - calc(2rem * 2));
  }
  .header-navbar.navbar-static-top {
    left: 0;
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .header-navbar .navbar-nav {
    margin: 0;
    flex-flow: row wrap;
  }
  .header-navbar .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .header-navbar .navbar-nav .nav-item {
    float: left;
  }
}
@media (max-width: 767px) {
  .header-navbar .navbar-nav {
    margin: 0;
    flex-flow: row wrap;
  }
  .header-navbar .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .header-navbar .navbar-nav .nav-item {
    float: left;
  }
}
@media (max-width: 767.98px) {
  .header-navbar.floating-nav {
    width: calc( 100vw - (100vw - 100%) - calc(2rem + 0.4rem) ) !important;
    margin-left: calc(2rem - 0.8rem);
    margin-right: calc(2rem - 0.8rem);
  }
  .header-navbar .navbar-container ul.navbar-nav li .selected-language {
    display: none;
  }
}
/* ----------- iPhone 5, 5S  iPhone 6----------- */
/* Landscape */
@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
    max-height: 180px;
  }
}
/* ----------- iPhone 6+ ----------- */
/* Landscape */
@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
  .header-navbar .navbar-container .show .dropdown-menu {
    max-height: 280px;
  }
}
@media (max-width: 1201px) {
  .header-navbar.fixed-top, .header-navbar.floating-nav {
    left: 0;
  }

  .horizontal-layout .header-navbar .navbar-container {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}
@media (max-width: 1199px) {
  .header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link .user-name {
    margin-bottom: 0;
  }
}
.card {
  margin-bottom: 2rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}
.card .card {
  box-shadow: none !important;
}
.card .card-title {
  font-weight: 500;
  font-size: 1.285rem;
  margin-bottom: 1.53rem;
}
.card .card-bordered {
  border: 0 solid rgba(34, 41, 47, 0.125);
}
.card .card-img {
  object-fit: cover;
}
.card .card-img-overlay {
  border-radius: 0.428rem;
}
.card.card-fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
}
.card .card-body[class*=border-bottom-] {
  border-bottom-width: 2px !important;
}
.card .card-img-overlay.bg-overlay {
  background: rgba(34, 41, 47, 0.45);
}
.card .card-img-overlay .text-muted {
  color: #1e1e1e !important;
}
.card.card-minimal {
  border: none;
  box-shadow: none;
}
.card .card-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.card .card-header .card-title {
  margin-bottom: 0;
}
.card .card-header .heading-elements {
  position: relative;
  top: -1px;
}
.card .card-header .heading-elements li:not(:first-child) a {
  margin-left: 0.75rem;
}
.card .card-header .heading-elements a.btn {
  padding-top: 6px;
  padding-bottom: 6px;
}
.card .card-header .heading-elements a i,
.card .card-header .heading-elements a svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
}
.card .card-header .heading-elements a[data-action=collapse] i,
.card .card-header .heading-elements a[data-action=collapse] svg {
  transition: all 0.25s ease-out;
  display: inline-block;
}
.card .card-header .heading-elements a[data-action=collapse].rotate i,
.card .card-header .heading-elements a[data-action=collapse].rotate svg {
  transform: rotate(-180deg);
}
.card .card-header + .card-content > .card-body:first-of-type, .card .card-header + .card-body {
  padding-top: 0px;
}
.card .card-footer {
  border-top: 1px solid #dae1e7;
  background-color: transparent;
}

.card-group {
  margin-bottom: 0.75rem;
}

.card-head-inverse {
  color: #fff;
}
.card-head-inverse .heading-elements i,
.card-head-inverse .heading-elements svg {
  color: #fff;
}

.card-transparent {
  background-color: transparent;
}

.text-white .card-img-overlay .text-muted {
  color: #fff !important;
}
.text-white code {
  background-color: white;
}
.text-white .heading-elements i,
.text-white .heading-elements svg {
  color: #fff;
}

.overlay-img-card .card-img-overlay,
.overlay-img-card img {
  max-height: 34.64rem;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .card-body,
.card-content {
    min-height: 1px;
  }
}
.card-congratulations {
  background: linear-gradient(118deg, #bd832b, rgba(189, 131, 43, 0.7));
  color: #fff;
}
.card-congratulations .congratulations-img-left {
  width: 200px;
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 575.98px) {
  .card-congratulations .congratulations-img-left {
    width: 140px;
  }
}
.card-congratulations .congratulations-img-right {
  width: 175px;
  position: absolute;
  top: 0;
  right: 0;
}
@media (max-width: 575.98px) {
  .card-congratulations .congratulations-img-right {
    width: 140px;
  }
}
.card-congratulations .avatar {
  margin-bottom: 2rem;
}

.congratulation-medal {
  position: absolute;
  top: 0;
  right: 37px;
}

.card-deposits .deposits-divider {
  margin-top: 1.8rem;
  margin-bottom: 1.4rem;
}

.card-employee-task .employee-task:not(:last-child) {
  margin-bottom: 1.5rem;
}

.card-developer-meetup .meetup-img-wrapper {
  background-color: rgba(189, 131, 43, 0.1);
}
.card-developer-meetup .meetup-header {
  margin-bottom: 2rem;
}
.card-developer-meetup .meetup-header .meetup-day {
  text-align: center;
  border-right: 1px solid #d8d6de;
  padding-right: 1.3rem;
  margin-right: 1.3rem;
}
.card-developer-meetup .meetings .avatar .avatar-content {
  width: 34px;
  height: 34px;
}
.card-developer-meetup .meetings + .meetings {
  margin-top: 1.2rem;
}
.card-developer-meetup .avatar-group {
  margin-top: 2rem;
}

.card-profile {
  text-align: center;
}
.card-profile .card-body {
  position: relative;
  padding: 5rem 2.3rem 1.5rem 2.3rem;
}
.card-profile .card-body .profile-image-wrapper {
  display: flex;
  justify-content: center;
}
.card-profile .card-body .profile-image-wrapper .profile-image {
  position: absolute;
  top: -4rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(34, 41, 47, 0.14);
  margin-bottom: 1.15rem;
}
.card-profile .card-body .profile-image-wrapper .profile-image img {
  width: 100px;
  height: 100px;
}
.card-profile .card-body .profile-badge {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.card-apply-job .apply-job-package {
  padding: 1.2rem;
  margin-bottom: 1.15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-apply-job .apply-job-package sup {
  top: -0.8rem;
}

.card-transaction .transaction-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-transaction .transaction-item:not(:last-child) {
  margin-bottom: 1.2rem;
}
.card-transaction .transaction-item .avatar {
  margin-right: 1.2rem;
}
.card-transaction .transaction-item .avatar .avatar-content {
  width: 42px;
  height: 42px;
}
.card-transaction .transaction-item .transaction-title {
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.card-user-timeline .user-timeline-title-icon {
  width: 1.714rem;
  height: 1.714rem;
  margin-right: 1.3rem;
}
.card-user-timeline .timeline .timeline-item:last-child .timeline-event {
  min-height: auto;
}
.card-user-timeline .timeline .timeline-item:not(:last-child) {
  padding-bottom: 2.3rem;
}

.business-card .business-items:not(:last-child) {
  margin-bottom: 1.3rem;
}
.business-card .business-items .business-item {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
  padding: 1rem 1rem 1rem 1.3rem;
}
.business-card .business-items .business-item:not(:last-child) {
  margin-bottom: 0.85rem;
}

.card-browser-states .browser-states {
  margin-top: 2.14rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-app-design .design-group {
  margin-bottom: 2rem;
}
.card-app-design .design-group .avatar:not(:last-child) {
  margin-right: 0.6rem;
}
.card-app-design .design-planning-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-app-design .design-planning-wrapper:not(:last-child) {
  margin-bottom: 1.3rem;
}
.card-app-design .design-planning-wrapper .design-planning {
  padding: 0.5rem;
  margin-bottom: 0.7rem;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 0.357rem;
  min-width: 6.4rem;
}
.card-app-design .design-planning-wrapper .design-planning:not(:last-child) {
  margin-right: 0.7rem;
}

.card-statistics .card-header {
  padding-left: 2.4rem;
}
.card-statistics .statistics-body {
  padding: 2rem 2.4rem 2.8rem !important;
}
.card-statistics .statistics-body .avatar .avatar-content {
  width: 48px;
  height: 48px;
}
.card-statistics .statistics-body .avatar .avatar-content .avatar-icon {
  width: 24px;
  height: 24px;
}

.chart-dropdown .btn {
  font-size: 1rem;
  font-weight: 400;
}
.chart-dropdown .btn:after {
  display: none;
}

.card-revenue-budget .revenue-report-wrapper {
  padding: 1.286rem 1.5rem;
}
@media (min-width: 768px) {
  .card-revenue-budget .revenue-report-wrapper {
    border-right: 1px solid #ebe9f1;
  }
}
.card-revenue-budget .revenue-report-wrapper #revenue-report-chart .apexcharts-series[seriesName=Earning] {
  transform: scaleY(0.965);
}
.card-revenue-budget .revenue-report-wrapper #revenue-report-chart .apexcharts-series[seriesName=Expense] {
  transform: scaleY(1.035);
}
.card-revenue-budget .budget-wrapper {
  padding: 2rem 3rem;
  text-align: center;
}
@media (max-width: 767.98px) {
  .card-revenue-budget .budget-wrapper {
    padding-top: 0;
  }
}
.card-revenue-budget .budget-wrapper .budget-dropdown {
  margin-bottom: 2rem;
}
.card-revenue-budget .budget-wrapper #budget-chart {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.earnings-card .apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label {
  font-size: 0.8rem;
  fill: #6e6b7b !important;
}
.earnings-card .apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value {
  fill: #5e5873;
  font-size: 1.286rem;
  font-weight: 500;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #dashboard-analytics .avatar .avatar-content,
#statistics-card .avatar .avatar-content {
    padding: 0 !important;
  }
}
.carousel-dark .carousel-caption h1, .carousel-dark .carousel-caption .h1, .carousel-dark .carousel-caption h2, .carousel-dark .carousel-caption .h2, .carousel-dark .carousel-caption h3, .carousel-dark .carousel-caption .h3, .carousel-dark .carousel-caption h4, .carousel-dark .carousel-caption .h4, .carousel-dark .carousel-caption h5, .carousel-dark .carousel-caption .h5, .carousel-dark .carousel-caption h6, .carousel-dark .carousel-caption .h6 {
  color: #22292f;
}

.breadcrumb:not([class*=breadcrumb-]) .breadcrumb-item + .breadcrumb-item:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  color: #6e6b7b;
  margin-right: 0.6rem;
  background-size: 14px;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  height: 20px;
}

/* Component Specific */
.breadcrumb-slash.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "/";
}

.breadcrumb-dots.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: ".";
  position: relative;
  top: -4px;
}

.breadcrumb-dashes.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "-";
}

.breadcrumb-pipes.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "|";
}

.breadcrumb-chevron.breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  color: #6e6b7b;
  margin-right: 0.6rem;
  background-size: 14px;
}

.content-header .breadcrumb {
  padding-left: 1rem;
}

@media (max-width: 648px) {
  .content-header .breadcrumb {
    display: none;
  }

  .breadcrumbs-top .content-header-title {
    display: contents !important;
  }
}
.badge[class*=bg-] [class*=icon-] {
  line-height: 1;
}
.badge[class*=bg-] a {
  color: #fff;
}
.badge[class*=bg-] .dropdown-toggle span,
.badge[class*=bg-] .dropdown-toggle i,
.badge[class*=bg-] .dropdown-toggle svg, .badge[class*=bg-].dropdown-toggle span,
.badge[class*=bg-].dropdown-toggle i,
.badge[class*=bg-].dropdown-toggle svg {
  vertical-align: text-top;
}
.badge[class*=bg-] .dropdown-toggle i,
.badge[class*=bg-] .dropdown-toggle svg, .badge[class*=bg-].dropdown-toggle i,
.badge[class*=bg-].dropdown-toggle svg {
  padding-left: 0.2rem;
}
.badge[class*=bg-] .dropdown-toggle::after, .badge[class*=bg-].dropdown-toggle::after {
  position: relative;
  top: 0;
  left: 0;
  font-size: 1rem;
}
.badge[class*=bg-] .dropdown-menu a {
  color: #6e6b7b;
}
.badge i,
.badge svg {
  height: 12px;
  width: 11px;
  font-size: 12px;
  stroke-width: 3;
  vertical-align: top;
}
.badge.badge-square {
  border-radius: 0;
}
.badge.badge-up {
  position: absolute;
  top: -11px;
  right: -9px;
  min-width: 1.429rem;
  min-height: 1.429rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.786rem;
  line-height: 0.786;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.badge.badge-up.badge-sm {
  top: -0.5rem;
  right: -0.5rem;
}

.badge-icon i,
.badge-icon svg {
  font-size: 100%;
  margin-right: 5px;
}

.dropup .badge {
  cursor: pointer;
}

.nav {
  border-radius: 0.25rem;
}
.nav.wrap-border {
  border: 1px solid #ddd;
}
.nav.wrap-border li.nav-header {
  margin: 0 0.5rem;
}
.nav.wrap-border li.nav-item,
.nav.wrap-border div {
  padding: 2px 0.714rem;
}
.nav.nav-left .nav-item .nav-link {
  justify-content: flex-start;
}
.nav.nav-right .nav-item .nav-link {
  justify-content: flex-end;
}
.nav.square-border {
  border-radius: 0;
}
.nav.square-border .nav-item .nav-link.active {
  border-radius: 0;
}
.nav .modern-nav-toggle {
  padding: 0;
  margin: 1.571rem 0;
}
.nav .dropdown.show .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.nav .dropdown-toggle:not(.active)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.nav-pills .nav-link,
.nav-tabs .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-pills .nav-link i,
.nav-pills .nav-link svg,
.nav-tabs .nav-link i,
.nav-tabs .nav-link svg {
  margin-right: 0.5rem;
}

.nav-pills {
  margin-bottom: 1rem;
}
.nav-pills .nav-link {
  padding: 0.786rem 1.5rem;
  font-size: 1rem;
  line-height: 1rem;
  border: 1px solid transparent;
  color: #5e5873;
}
.nav-pills .nav-link.active {
  border-color: #bd832b;
  box-shadow: 0 4px 18px -4px rgba(189, 131, 43, 0.65);
}
.nav-pills .nav-link.disabled {
  color: #b9b9c3;
}
.nav-pills .nav-link.dropdown-toggle::after {
  top: 1px;
  left: 1px;
}
.nav-pills.dropdown.show .nav-link {
  color: #fff;
}
.nav-pills.dropdown.show .dropdown-item.active:hover {
  color: #bd832b;
}
.nav-pills.nav-justified {
  width: 100%;
}
.nav-pills.nav-justified .nav-item {
  float: none;
}
.nav-pills.nav-justified .nav-link {
  text-align: center;
  margin-bottom: 5px;
}
.nav-pills.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 576px) {
  .nav-pills.nav-justified .nav-item {
    display: block;
    width: 1%;
  }
  .nav-pills.nav-justified .nav-link {
    margin-bottom: 0;
  }
}
.nav-pills.nav-justified .nav-link {
  margin-right: 0;
  border-radius: 0.357rem;
}
@media (min-width: 576px) {
  .nav-pills.nav-justified .nav-link.active,
.nav-pills.nav-justified .nav-link.active:hover,
.nav-pills.nav-justified .nav-link.active:focus {
    border-bottom-color: transparent;
  }
}
.nav-pills.nav-justified .nav-link {
  display: block;
}
.nav-pills.nav-justified .nav-link.active {
  border: none;
}
.nav-pills.nav-justified .nav-link.active:hover, .nav-pills.nav-justified .nav-link.active:focus {
  border: none;
}

.nav-tabs {
  margin-bottom: 1rem;
  position: relative;
}
.nav-tabs .nav-item {
  position: relative;
}
.nav-tabs .nav-link {
  color: #6e6b7b;
  border: none;
  min-width: auto;
  font-weight: 450;
  padding: 0.61rem 1.2rem;
  border-radius: 0;
  position: relative;
  overflow: hidden;
}
.nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(30deg, #bd832b, rgba(189, 131, 43, 0.5)) !important;
  transition: transform 0.3s;
  transform: translate3d(0, 150%, 0);
}
.nav-tabs .nav-link.active {
  position: relative;
  color: #bd832b;
}
.nav-tabs .nav-link.active:after {
  transform: translate3d(0, 0, 0);
}
.nav-tabs .nav-link.dropdown-toggle::after {
  top: 1px;
  left: 1px;
}
.nav-tabs.nav-justified {
  width: 100%;
}
.nav-tabs.nav-justified .nav-item {
  float: none;
}
.nav-tabs.nav-justified .nav-link {
  text-align: center;
  margin-bottom: 5px;
}
.nav-tabs.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto;
}
@media (min-width: 576px) {
  .nav-tabs.nav-justified .nav-item {
    display: block;
    width: 1%;
  }
  .nav-tabs.nav-justified .nav-link {
    margin-bottom: 0;
  }
}
.nav-tabs.nav-justified .nav-link {
  margin-right: 0;
  border-radius: 0.357rem;
}
@media (min-width: 576px) {
  .nav-tabs.nav-justified .nav-link.active,
.nav-tabs.nav-justified .nav-link.active:hover,
.nav-tabs.nav-justified .nav-link.active:focus {
    border-bottom-color: transparent;
  }
}
.nav-tabs.nav-justified .nav-item a.nav-link {
  display: block;
  border-radius: 0;
}
.nav-tabs.nav-justified .nav-item a.nav-link.active {
  border: none;
}
.nav-tabs.nav-justified .nav-item a.nav-link:hover {
  border-color: transparent;
}

.nav-vertical {
  overflow: hidden;
}
.nav-vertical .nav.nav-tabs .nav-item .nav-link {
  margin-bottom: 0;
}
.nav-vertical .nav.nav-tabs .nav-item .nav-link:after {
  transform: rotate(90deg) translate3d(0, 150%, 0);
  left: 70%;
}
.nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after {
  left: auto;
  right: -1.4rem;
  transform: rotate(90deg) translate3d(0, 225%, 0);
  top: 1.25rem;
  width: 2.14rem;
}
.nav-vertical .nav.nav-tabs.nav-left {
  float: left;
  display: table;
  margin-right: 1rem;
}
.nav-vertical .nav.nav-tabs.nav-left ~ .tab-content .tab-pane {
  display: none;
  overflow-y: auto;
  padding-left: 1rem;
}
.nav-vertical .nav.nav-tabs.nav-left ~ .tab-content .tab-pane.active {
  display: block;
}
.nav-vertical .nav.nav-tabs.nav-right {
  float: right;
  display: table;
  margin-left: 1rem;
}
.nav-vertical .nav.nav-tabs.nav-right .nav-item .nav-link.active:after {
  left: -0.9rem;
  transform: rotate(90deg) translate3d(0, 10%, 0);
}
.nav-vertical .nav.nav-tabs.nav-right ~ .tab-content .tab-pane {
  display: none;
  overflow-y: auto;
  padding-right: 1rem;
}
.nav-vertical .nav.nav-tabs.nav-right ~ .tab-content .tab-pane.active {
  display: block;
}

.alert {
  font-weight: 500;
  padding: 0;
}
.alert.alert-dismissible .btn-close {
  padding: 1rem 1rem;
  background-color: transparent !important;
  box-shadow: none !important;
}
.alert.alert-dismissible .alert-body {
  padding: 0.71rem 2rem 0.71rem 1rem;
}
.alert .btn-close:focus {
  outline: 0;
}
.alert .alert-link:hover {
  text-decoration: underline;
}
.alert p {
  font-weight: 500;
  padding: 2px 0;
  margin-bottom: 0;
  vertical-align: middle;
}
.alert .alert-heading {
  font-weight: 600;
  font-size: 1rem;
  padding: 0.71rem 1rem;
  margin-bottom: 0;
}
.alert .alert-body {
  padding: 0.71rem 1rem;
}
.alert.alert-dark .alert-heading {
  box-shadow: rgba(75, 75, 75, 0.4) 0px 6px 15px -7px;
}

/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
  ----------------------------------------------------------------------------------------
==========================================================================================*/
.progress .progress-bar + .progress-bar:not(:last-child) {
  border-radius: 0;
}
.progress .progress-bar:last-child {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

/* ===============================================================================================
  File Name: list-group.scss
  Description: Contain list item, list group related extended SCSS.
  ----------------------------------------------------------------------------------------------
  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/
ul.list-inline li {
  display: inline-block;
}
ul.list-inline.list-inline-pipe > li + li:before {
  content: " | ";
  padding-right: 2px;
}

.list-group .list-group-item {
  line-height: 1.5;
}
.list-group .list-group-item i,
.list-group .list-group-item svg {
  position: relative;
}
.list-group .list-group-item-action:focus {
  outline: 0;
}
.list-group .list-group-item-action.active h1, .list-group .list-group-item-action.active .h1, .list-group .list-group-item-action.active h2, .list-group .list-group-item-action.active .h2, .list-group .list-group-item-action.active h3, .list-group .list-group-item-action.active .h3, .list-group .list-group-item-action.active h4, .list-group .list-group-item-action.active .h4, .list-group .list-group-item-action.active h5, .list-group .list-group-item-action.active .h5, .list-group .list-group-item-action.active h6, .list-group .list-group-item-action.active .h6, .list-group .list-group-item-action:active h1, .list-group .list-group-item-action:active .h1, .list-group .list-group-item-action:active h2, .list-group .list-group-item-action:active .h2, .list-group .list-group-item-action:active h3, .list-group .list-group-item-action:active .h3, .list-group .list-group-item-action:active h4, .list-group .list-group-item-action:active .h4, .list-group .list-group-item-action:active h5, .list-group .list-group-item-action:active .h5, .list-group .list-group-item-action:active h6, .list-group .list-group-item-action:active .h6 {
  color: #fff;
}
.list-group .list-group-item-action small, .list-group .list-group-item-action .small {
  color: #b9b9c3 !important;
}
.list-group.list-group-circle {
  border: none;
}
.list-group.list-group-circle .list-group-item {
  border: none;
  position: relative;
  padding-left: 1.5rem;
}
.list-group.list-group-circle .list-group-item:after {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  color: #6e6b7b;
  background-size: 10px;
  position: absolute;
  height: 10px;
  width: 10px;
  top: 1.15rem;
  left: 0;
}
.list-group.list-group-circle .list-group-item:hover {
  background-color: transparent;
}

.list-group-item.gu-mirror {
  border-radius: 0;
}

.toast.show {
  z-index: 1030;
}
.toast .btn-close:focus {
  box-shadow: none;
}

@media (max-width: 420.98px) {
  .toast {
    width: auto;
  }
}
.accordion [data-bs-toggle=collapse] {
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.45;
}
.accordion .accordion-item {
  margin-bottom: 0;
}
.accordion .accordion-item:last-of-type {
  margin-bottom: 0;
}
.accordion .accordion-item:not(:last-of-type) {
  border-bottom: 1px solid #ebe9f1;
}
.accordion .accordion-body {
  padding-top: 0.42rem;
}

.accordion.accordion-without-arrow .accordion-button::after {
  background-image: none !important;
}

.accordion-border .accordion-item {
  border: 1px solid #ebe9f1;
  border-radius: 0.428rem;
}
.accordion-border .accordion-item:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-border .accordion-item:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-margin .accordion-item {
  margin-top: 0.71rem;
  margin-bottom: 0.71rem;
  box-shadow: 0 2px 15px 0 rgba(34, 41, 47, 0.05) !important;
  border-radius: 0.357rem;
  border-bottom: 0 solid transparent !important;
}

.card.accordion-item .accordion-button {
  border-radius: 0.357rem;
}

.page-item {
  border-radius: 5rem;
}
.page-item:not(.prev-item) .page-link, .page-item:not(.next-item) .page-link, .page-item:not(.first) .page-link, .page-item:not(.last) .page-link, .page-item:not(.active) .page-link {
  line-height: 1.3;
}
.page-item .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-item.previous .page-link, .page-item.prev .page-link, .page-item.next .page-link, .page-item.first .page-link, .page-item.last .page-link {
  width: auto;
}
.page-item.prev-item .page-link, .page-item.next-item .page-link {
  transition: all 0.2s ease-out;
}
.page-item.prev-item {
  margin-right: 0.3571rem;
}
.page-item.prev-item .page-link {
  border-radius: 50%;
}
.page-item.prev-item .page-link:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  color: #6e6b7b;
  width: 8px;
  height: 18px;
  content: "";
}
.page-item.prev-item .page-link:hover {
  background: #f3f2f7;
  color: #bd832b;
}
.page-item.prev-item .page-link:active, .page-item.prev-item .page-link:hover {
  background-color: #bd832b !important;
}
.page-item.prev-item .page-link:active:before, .page-item.prev-item .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.page-item.prev-item.disabled .page-link:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b9b9c3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
}
.page-item.prev-item ~ .page-item:nth-child(2) {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}
.page-item.prev-item ~ .page-item:nth-child(2) .page-link {
  border-top-left-radius: 5rem !important;
  border-bottom-left-radius: 5rem !important;
}
.page-item.prev-item ~ .page-item:nth-last-child(2) {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}
.page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
  border-top-right-radius: 5rem !important;
  border-bottom-right-radius: 5rem !important;
}
.page-item.next-item {
  margin-left: 0.3571rem;
}
.page-item.next-item .page-link {
  border-radius: 50%;
}
.page-item.next-item .page-link:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  width: 8px;
  height: 18px;
  content: "";
}
.page-item.next-item .page-link:hover {
  background: #f3f2f7;
  color: #bd832b;
}
.page-item.next-item .page-link:active, .page-item.next-item .page-link:hover {
  background-color: #bd832b !important;
}
.page-item.next-item .page-link:active:after, .page-item.next-item .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.page-item.next-item.disabled .page-link:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b9b9c3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}
.page-item.prev .page-link:before, .page-item.previous .page-link:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  color: #6e6b7b;
  width: 12px;
  height: 18px;
  content: "";
}
.page-item.prev .page-link:hover:before, .page-item.previous .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bd832b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.page-item.prev.disabled .page-link:before, .page-item.previous.disabled .page-link:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b9b9c3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E");
}
.page-item.next .page-link:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 14px;
  color: #6e6b7b;
  width: 12px;
  height: 18px;
  content: "";
}
.page-item.next .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bd832b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.page-item.next.disabled .page-link:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b9b9c3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
}
.page-item.disabled .page-link {
  color: #b9b9c3;
}
.page-item .page-link {
  margin: 0;
  margin-left: 0;
  font-size: 1rem;
  min-width: 2.286rem;
}
.page-item .page-link:focus {
  box-shadow: none;
}
.page-item .page-link:hover {
  color: #bd832b;
}
.page-item.active {
  background-color: #f3f2f7;
  border-radius: 0;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #bd832b;
  color: #fff !important;
  font-weight: 600;
}
.page-item:first-child:not(.prev-item).active {
  border-top-left-radius: 1.428rem;
  border-bottom-left-radius: 1.428rem;
}
.page-item:first-child:not(.prev-item).active .page-link {
  border-radius: 5rem;
}
.page-item:first-child:not(.prev-item) .page-link {
  border-top-left-radius: 1.428rem;
  border-bottom-left-radius: 1.428rem;
}
.page-item:last-child:not(.next-item).active {
  border-top-right-radius: 1.428rem;
  border-bottom-right-radius: 1.428rem;
}
.page-item:last-child:not(.next-item).active .page-link {
  border-radius: 5rem;
}
.page-item:last-child:not(.next-item) .page-link {
  border-top-right-radius: 1.428rem;
  border-bottom-right-radius: 1.428rem;
}

.pagination.pagination-lg .page-item .page-link {
  font-size: 1.14rem;
  min-width: 2.6rem;
}
.pagination.pagination-sm .page-item .page-link {
  font-size: 0.857rem;
  min-width: 2rem;
}

.modal .modal-header {
  background-color: #f8f8f8;
}
.modal .modal-header .btn-close {
  padding: 0.8rem;
  box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.357rem;
  background-color: #fff;
  opacity: 1;
  transition: all 0.23s ease 0.1s;
  position: relative;
  transform: translate(18px, -10px);
}
.modal .modal-header .btn-close:hover, .modal .modal-header .btn-close:focus, .modal .modal-header .btn-close:active {
  opacity: 1;
  outline: none;
  transform: translate(15px, -2px);
  box-shadow: none;
}
.modal .modal-fullscreen .modal-header .btn-close {
  transform: translate(0, 0);
}
.modal .modal-content {
  overflow: visible;
  box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1);
}
.modal .modal-footer {
  padding: 0.8rem 1.4rem;
}
.modal.modal-sticky {
  bottom: 0;
  right: 0;
  top: auto;
  left: auto;
  height: auto;
  position: fixed;
}
.modal.modal-sticky .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal.modal-sticky .modal-dialog {
  margin-right: 2rem !important;
  box-shadow: 0px 0px 24px 0 rgba(34, 41, 47, 0.25);
  border-radius: 0.357rem;
}
.modal.modal-sticky .modal-actions {
  background: transparent;
  box-shadow: none;
  line-height: 0;
}

.modal-xs {
  max-width: 300px;
}

@media (min-width: 1200px) {
  .modal-xl {
    margin-left: 3%;
    margin-right: 3%;
  }
}
.modal-slide-in,
.modal-slide-in .modal {
  padding: 0 !important;
  overflow: hidden !important;
}

.modal-slide-in .modal-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  margin: 0;
  max-width: none;
  width: 20rem;
}
.modal-slide-in .modal-dialog .modal-content {
  padding-bottom: 1.8875rem;
  padding-top: 1.8875rem;
  overflow: auto;
  border-radius: 0;
  height: 100%;
}
.modal-slide-in .modal-dialog .modal-body {
  padding-bottom: 0;
  padding-top: 0;
  margin: auto 0;
  flex-grow: 0;
}
@media (min-width: 576px) {
  .modal-slide-in .modal-dialog {
    width: 28rem;
  }
}
.modal-slide-in .modal-dialog.sidebar-sm {
  width: 25rem;
}
@media (min-width: 576px) {
  .modal-slide-in .modal-dialog.sidebar-lg {
    width: 30rem;
  }
}
.modal-slide-in .btn-close {
  top: 0.4rem;
  z-index: 10;
  transform: none;
  position: absolute;
  top: 1.5rem;
  right: 1.4rem;
  margin: 0;
  padding: 0 !important;
  line-height: 0.5;
  transform: translate(0, -50%);
}

.modal-slide-in.fade .modal-dialog,
.modal-slide-in .modal.fade .modal-dialog {
  transform: translateX(100%);
  transition: transform 0.1s ease-out;
}

.modal-slide-in.show .modal-dialog,
.modal-slide-in .modal.show .modal-dialog {
  transform: translateX(0) !important;
  transition: transform 0.1s ease-out;
}

@media (max-width: 576px) {
  .modal:not(.modal-slide-in) .modal-dialog:not(.modal-fullscreen) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .modal .modal-xs,
.modal .modal-sm {
    max-width: unset;
  }
  .modal.modal-sticky .modal-dialog {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem;
    margin-bottom: 3rem;
  }
}
.modal-refer-earn .modal-refer-earn-step svg {
  height: 2.5rem;
  width: 2.5rem;
}

@media (max-width: 991.98px) {
  #shareProject .list-group .dropdown-toggle {
    padding: 0.5rem;
  }
  #shareProject .list-group .dropdown-toggle::after {
    left: 0;
  }
}
.popover {
  box-shadow: 0 0 10px 0 rgba(34, 41, 47, 0.1);
}
.popover .popover-header {
  font-size: 1.07rem;
  border: 1px solid #bd832b;
}
.popover .popover-body {
  border: 1px solid #ebe9f1;
  border-top-color: #fff;
  border-bottom-left-radius: 0.358rem;
  border-bottom-right-radius: 0.358rem;
}
.popover.bs-popover-bottom .popover-arrow:after, .popover.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow:after {
  border-bottom-color: #bd832b;
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
}

.font-small-1 {
  font-size: 0.7rem !important;
}
svg.font-small-1 {
  height: 0.7rem !important;
  width: 0.7rem !important;
}

.font-small-2 {
  font-size: 0.8rem !important;
}
svg.font-small-2 {
  height: 0.8rem !important;
  width: 0.8rem !important;
}

.font-small-3 {
  font-size: 0.9rem !important;
}
svg.font-small-3 {
  height: 0.9rem !important;
  width: 0.9rem !important;
}

.font-small-4 {
  font-size: 1rem !important;
}
svg.font-small-4 {
  height: 1rem !important;
  width: 1rem !important;
}

.font-medium-1 {
  font-size: 1.1rem !important;
}
svg.font-medium-1 {
  height: 1.1rem !important;
  width: 1.1rem !important;
}

.font-medium-2 {
  font-size: 1.2rem !important;
}
svg.font-medium-2 {
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.font-medium-3 {
  font-size: 1.3rem !important;
}
svg.font-medium-3 {
  height: 1.3rem !important;
  width: 1.3rem !important;
}

.font-medium-4 {
  font-size: 1.4rem !important;
}
svg.font-medium-4 {
  height: 1.4rem !important;
  width: 1.4rem !important;
}

.font-medium-5 {
  font-size: 1.5rem !important;
}
svg.font-medium-5 {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.font-large-1 {
  font-size: 2rem !important;
}
svg.font-large-1 {
  height: 2rem !important;
  width: 2rem !important;
}

.font-large-2 {
  font-size: 3rem !important;
}
svg.font-large-2 {
  height: 3rem !important;
  width: 3rem !important;
}

.font-large-3 {
  font-size: 4rem !important;
}
svg.font-large-3 {
  height: 4rem !important;
  width: 4rem !important;
}

.font-large-4 {
  font-size: 5rem !important;
}
svg.font-large-4 {
  height: 5rem !important;
  width: 5rem !important;
}

.font-large-5 {
  font-size: 6rem !important;
}
svg.font-large-5 {
  height: 6rem !important;
  width: 6rem !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-condensed {
  line-height: 1.5 !important;
}

.line-height-inherit {
  line-height: inherit !important;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-45-inverse {
  transform: rotate(-45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-90-inverse {
  transform: rotate(-90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-180-inverse {
  transform: rotate(-180deg);
}

.border-2 {
  border-width: 2px !important;
}

.border-top-2 {
  border-top-width: 2px !important;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
}

.border-start-2 {
  border-left-width: 2px !important;
}

.border-end-2 {
  border-right-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-top-3 {
  border-top-width: 3px !important;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
}

.border-start-3 {
  border-left-width: 3px !important;
}

.border-end-3 {
  border-right-width: 3px !important;
}

.round {
  border-radius: 1.5rem;
}

.square {
  border-radius: 0;
}

.fit {
  max-width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.height-50 {
  height: 50px !important;
}

.width-50 {
  width: 50px !important;
}

.height-100 {
  height: 100px !important;
}

.width-100 {
  width: 100px !important;
}

.height-150 {
  height: 150px !important;
}

.width-150 {
  width: 150px !important;
}

.height-200 {
  height: 200px !important;
}

.width-200 {
  width: 200px !important;
}

.height-250 {
  height: 250px !important;
}

.width-250 {
  width: 250px !important;
}

.height-300 {
  height: 300px !important;
}

.width-300 {
  width: 300px !important;
}

.height-350 {
  height: 350px !important;
}

.width-350 {
  width: 350px !important;
}

.height-400 {
  height: 405px !important;
}

.width-400 {
  width: 405px !important;
}

.height-450 {
  height: 455px !important;
}

.width-450 {
  width: 455px !important;
}

.height-500 {
  height: 500px !important;
}

.width-500 {
  width: 500px !important;
}

.height-550 {
  height: 550px !important;
}

.width-550 {
  width: 550px !important;
}

.height-600 {
  height: 600px !important;
}

.width-600 {
  width: 600px !important;
}

.height-650 {
  height: 650px !important;
}

.width-650 {
  width: 650px !important;
}

.height-700 {
  height: 700px !important;
}

.width-700 {
  width: 700px !important;
}

.height-750 {
  height: 750px !important;
}

.width-750 {
  width: 750px !important;
}

.height-800 {
  height: 800px !important;
}

.width-800 {
  width: 800px !important;
}

.height-5-per {
  height: 5% !important;
}

.width-5-per {
  width: 5% !important;
}

.height-10-per {
  height: 10% !important;
}

.width-10-per {
  width: 10% !important;
}

.height-15-per {
  height: 15% !important;
}

.width-15-per {
  width: 15% !important;
}

.height-20-per {
  height: 20% !important;
}

.width-20-per {
  width: 20% !important;
}

.height-25-per {
  height: 25% !important;
}

.width-25-per {
  width: 25% !important;
}

.height-30-per {
  height: 30% !important;
}

.width-30-per {
  width: 30% !important;
}

.height-35-per {
  height: 35% !important;
}

.width-35-per {
  width: 35% !important;
}

.height-40-per {
  height: 40% !important;
}

.width-40-per {
  width: 40% !important;
}

.height-45-per {
  height: 45% !important;
}

.width-45-per {
  width: 45% !important;
}

.height-50-per {
  height: 50% !important;
}

.width-50-per {
  width: 50% !important;
}

.height-55-per {
  height: 55% !important;
}

.width-55-per {
  width: 55% !important;
}

.height-60-per {
  height: 60% !important;
}

.width-60-per {
  width: 60% !important;
}

.height-65-per {
  height: 65% !important;
}

.width-65-per {
  width: 65% !important;
}

.height-70-per {
  height: 70% !important;
}

.width-70-per {
  width: 70% !important;
}

.height-75-per {
  height: 75% !important;
}

.width-75-per {
  width: 75% !important;
}

.height-80-per {
  height: 80% !important;
}

.width-80-per {
  width: 80% !important;
}

.height-85-per {
  height: 85% !important;
}

.width-85-per {
  width: 85% !important;
}

.height-90-per {
  height: 90% !important;
}

.width-90-per {
  width: 90% !important;
}

.height-95-per {
  height: 95% !important;
}

.width-95-per {
  width: 95% !important;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow: scroll;
}

.overflow-y-scroll {
  overflow: scroll;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-default {
  cursor: default;
}

.cursor-progress {
  cursor: progress;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.zindex-1 {
  z-index: 1 !important;
}

.zindex-2 {
  z-index: 2 !important;
}

.zindex-3 {
  z-index: 3 !important;
}

.zindex-4 {
  z-index: 4 !important;
}

.zindex-0 {
  z-index: 0 !important;
}

.zindex-minus-1 {
  z-index: -1 !important;
}

.zindex-minus-2 {
  z-index: -2 !important;
}

.zindex-minus-3 {
  z-index: -3 !important;
}

.zindex-minus-4 {
  z-index: -4 !important;
}

.box-shadow-0 {
  box-shadow: none !important;
}

.box-shadow-1 {
  box-shadow: 0 2px 5px 0 rgba(34, 41, 47, 0.16), 0 2px 10px 0 rgba(34, 41, 47, 0.12);
}

.box-shadow-2 {
  box-shadow: 0 8px 17px 0 rgba(34, 41, 47, 0.2), 0 6px 20px 0 rgba(34, 41, 47, 0.19);
}

.box-shadow-3 {
  box-shadow: 0 12px 15px 0 rgba(34, 41, 47, 0.24), 0 17px 50px 0 rgba(34, 41, 47, 0.19);
}

.box-shadow-4 {
  box-shadow: 0 16px 28px 0 rgba(34, 41, 47, 0.22), 0 25px 55px 0 rgba(34, 41, 47, 0.21);
}

.box-shadow-5 {
  box-shadow: 0 27px 24px 0 rgba(34, 41, 47, 0.2), 0 40px 77px 0 rgba(34, 41, 47, 0.22);
}

.box-shadow-6 {
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12), 0 2px 4px 0 rgba(34, 41, 47, 0.08);
}

.outline-none {
  outline: none !important;
}

.list-style-inside {
  list-style: inside;
}

.list-style-circle {
  list-style: circle;
}

.list-style-square {
  list-style: square;
}

.bg-cover {
  background-size: cover !important;
}

.background-repeat {
  background-repeat: repeat !important;
}

.background-no-repeat {
  background-repeat: no-repeat !important;
}

.icon-bg-circle {
  color: #fff;
  padding: 0.5rem;
  border-radius: 50%;
}

.icon-left {
  margin-right: 0.5rem;
}

.icon-right {
  margin-right: 0.5rem;
}

.blockOverlay {
  z-index: 1050 !important;
}

.blockElement,
.blockPage {
  z-index: 1051 !important;
}

.hidden {
  display: none;
  visibility: hidden;
}

a:hover {
  cursor: pointer;
}

.table-middle td {
  vertical-align: middle;
}

.fill-current {
  fill: currentColor;
}

.pointer-events-none {
  pointer-events: none;
}

.bg-white .card-header,
.bg-white .card-footer {
  background-color: transparent;
}

.border-white {
  border: 1px solid #ffffff !important;
}

.border-top-white {
  border-top: 1px solid #ffffff;
}

.border-bottom-white {
  border-bottom: 1px solid #ffffff;
}

.border-start-white {
  border-left: 1px solid #ffffff;
}

.border-end-white {
  border-right: 1px solid #ffffff;
}

.bg-white.badge-glow,
.border-white.badge-glow {
  box-shadow: 0px 0px 10px #ffffff;
}

.overlay-white {
  background: #ffffff;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.6);
}

input:focus ~ .bg-white {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffffff !important;
}

.bg-black .card-header,
.bg-black .card-footer {
  background-color: transparent;
}

.border-black {
  border: 1px solid #000000 !important;
}

.border-top-black {
  border-top: 1px solid #000000;
}

.border-bottom-black {
  border-bottom: 1px solid #000000;
}

.border-start-black {
  border-left: 1px solid #000000;
}

.border-end-black {
  border-right: 1px solid #000000;
}

.bg-black.badge-glow,
.border-black.badge-glow {
  box-shadow: 0px 0px 10px #000000;
}

.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.6);
}

input:focus ~ .bg-black {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important;
}

.bg-dark .card-header,
.bg-dark .card-footer {
  background-color: transparent;
}

.alert-dark {
  background: rgba(75, 75, 75, 0.12) !important;
  color: #4b4b4b !important;
}
.alert-dark .alert-heading {
  box-shadow: rgba(75, 75, 75, 0.4) 0px 6px 15px -7px;
}
.alert-dark .alert-link {
  color: #3e3e3e !important;
}
.alert-dark .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234b4b4b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  color: #4b4b4b !important;
}

.border-dark {
  border: 1px solid #4b4b4b !important;
}

.border-top-dark {
  border-top: 1px solid #4b4b4b;
}

.border-bottom-dark {
  border-bottom: 1px solid #4b4b4b;
}

.border-start-dark {
  border-left: 1px solid #4b4b4b;
}

.border-end-dark {
  border-right: 1px solid #4b4b4b;
}

.bg-dark.badge-glow,
.border-dark.badge-glow {
  box-shadow: 0px 0px 10px #4b4b4b;
}

.badge.badge-light-dark {
  background-color: rgba(75, 75, 75, 0.12);
  color: #4b4b4b !important;
}

.overlay-dark {
  background: #4b4b4b;
  /* The Fallback */
  background: rgba(75, 75, 75, 0.6);
}

.btn-dark {
  border-color: #4b4b4b !important;
  background-color: #4b4b4b !important;
  color: #fff !important;
}
.btn-dark:focus, .btn-dark:active, .btn-dark.active {
  color: #fff;
  background-color: #343434 !important;
}
.btn-dark:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #4b4b4b;
}
.btn-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark {
  color: #fff;
  background-color: #343434 !important;
}

.btn-flat-dark {
  background-color: transparent;
  color: #4b4b4b;
}
.btn-flat-dark:hover {
  color: #4b4b4b;
}
.btn-flat-dark:hover:not(.disabled):not(:disabled) {
  background-color: rgba(75, 75, 75, 0.12);
}
.btn-flat-dark:active, .btn-flat-dark.active, .btn-flat-dark:focus {
  background-color: rgba(75, 75, 75, 0.2);
  color: #4b4b4b;
}
.btn-flat-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234b4b4b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-relief-dark {
  background-color: #4b4b4b;
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
  color: #fff;
  transition: all 0.2s ease;
}
.btn-relief-dark:hover:not(.disabled):not(:disabled) {
  background-color: #626262;
}
.btn-relief-dark:active, .btn-relief-dark.active, .btn-relief-dark:focus {
  background-color: #343434;
}
.btn-relief-dark:hover {
  color: #fff;
}
.btn-relief-dark:active, .btn-relief-dark.active {
  outline: none;
  box-shadow: none;
  transform: translateY(3px);
}

.btn-outline-dark {
  border: 1px solid #4b4b4b !important;
  background-color: transparent;
  color: #4b4b4b;
}
.btn-outline-dark:hover:not(.disabled):not(:disabled) {
  background-color: rgba(75, 75, 75, 0.04);
  color: #4b4b4b;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):focus {
  background-color: rgba(75, 75, 75, 0.2);
  color: #4b4b4b;
}
.btn-outline-dark.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234b4b4b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.btn-outline-dark.show.dropdown-toggle {
  background-color: rgba(75, 75, 75, 0.2);
  color: #4b4b4b;
}

.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark {
  color: #4b4b4b;
  background-color: rgba(75, 75, 75, 0.2) !important;
}

.btn-outline-dark.waves-effect .waves-ripple,
.btn-flat-dark.waves-effect .waves-ripple {
  background: radial-gradient(rgba(75, 75, 75, 0.2) 0, rgba(75, 75, 75, 0.3) 40%, rgba(75, 75, 75, 0.4) 50%, rgba(75, 75, 75, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.bullet.bullet-dark {
  background-color: #4b4b4b;
}

.modal.modal-dark .modal-header .modal-title {
  color: #4b4b4b;
}
.modal.modal-dark .modal-header .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234b4b4b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat !important;
  color: #4b4b4b !important;
}

.progress-bar-dark {
  background-color: rgba(75, 75, 75, 0.12);
}
.progress-bar-dark .progress-bar {
  background-color: #4b4b4b;
}

.timeline .timeline-point-dark {
  border-color: #4b4b4b !important;
}
.timeline .timeline-point-dark i,
.timeline .timeline-point-dark svg {
  stroke: #4b4b4b !important;
}
.timeline .timeline-point-dark.timeline-point-indicator {
  background-color: #4b4b4b !important;
}
.timeline .timeline-point-dark.timeline-point-indicator:before {
  background: rgba(75, 75, 75, 0.12) !important;
}

.divider.divider-dark .divider-text:before, .divider.divider-dark .divider-text:after {
  border-color: #4b4b4b !important;
}

input:focus ~ .bg-dark {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4b4b4b !important;
}

.form-check-dark .form-check-input:checked {
  border-color: #4b4b4b;
  background-color: #4b4b4b;
}
.form-check-dark .form-check-input:not(:disabled):checked, .form-check-dark .form-check-input:not(:disabled):focus {
  border-color: #4b4b4b;
  box-shadow: 0 2px 4px 0 rgba(75, 75, 75, 0.4);
}

.select2-dark .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #4b4b4b !important;
  border-color: #4b4b4b !important;
}

.bg-light .card-header,
.bg-light .card-footer {
  background-color: transparent;
}

.border-light {
  border: 1px solid #f6f6f6 !important;
}

.border-top-light {
  border-top: 1px solid #f6f6f6;
}

.border-bottom-light {
  border-bottom: 1px solid #f6f6f6;
}

.border-start-light {
  border-left: 1px solid #f6f6f6;
}

.border-end-light {
  border-right: 1px solid #f6f6f6;
}

.bg-light.badge-glow,
.border-light.badge-glow {
  box-shadow: 0px 0px 10px #f6f6f6;
}

.overlay-light {
  background: #f6f6f6;
  /* The Fallback */
  background: rgba(246, 246, 246, 0.6);
}

input:focus ~ .bg-light {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f6f6f6 !important;
}

.bg-primary .card-header,
.bg-primary .card-footer {
  background-color: transparent;
}

.alert-primary {
  background: rgba(189, 131, 43, 0.12) !important;
  color: #bd832b !important;
}
.alert-primary .alert-heading {
  box-shadow: rgba(189, 131, 43, 0.4) 0px 6px 15px -7px;
}
.alert-primary .alert-link {
  color: #a87526 !important;
}
.alert-primary .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23bd832b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  color: #bd832b !important;
}

.bg-light-primary {
  background: rgba(189, 131, 43, 0.12) !important;
  color: #bd832b !important;
}
.bg-light-primary.fc-h-event, .bg-light-primary.fc-v-event {
  border-color: rgba(189, 131, 43, 0.1);
}
.bg-light-primary .fc-list-event-dot {
  border-color: #bd832b !important;
}
.bg-light-primary.fc-list-event:hover td {
  background: rgba(189, 131, 43, 0.1) !important;
}
.bg-light-primary.fc-list-event .fc-list-event-title {
  color: #6e6b7b;
}

.avatar.bg-light-primary {
  color: #bd832b !important;
}

.border-primary {
  border: 1px solid #bd832b !important;
}

.border-top-primary {
  border-top: 1px solid #bd832b;
}

.border-bottom-primary {
  border-bottom: 1px solid #bd832b;
}

.border-start-primary {
  border-left: 1px solid #bd832b;
}

.border-end-primary {
  border-right: 1px solid #bd832b;
}

.bg-primary.badge-glow,
.border-primary.badge-glow {
  box-shadow: 0px 0px 10px #bd832b;
}

.badge.badge-light-primary {
  background-color: rgba(189, 131, 43, 0.12);
  color: #bd832b !important;
}

.overlay-primary {
  background: #bd832b;
  /* The Fallback */
  background: rgba(189, 131, 43, 0.6);
}

.btn-primary {
  border-color: #bd832b !important;
  background-color: #bd832b !important;
  color: #fff !important;
}
.btn-primary:focus, .btn-primary:active, .btn-primary.active {
  color: #fff;
  background-color: #a87526 !important;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #bd832b;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary {
  color: #fff;
  background-color: #a87526 !important;
}

.btn-flat-primary {
  background-color: transparent;
  color: #bd832b;
}
.btn-flat-primary:hover {
  color: #bd832b;
}
.btn-flat-primary:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 131, 43, 0.12);
}
.btn-flat-primary:active, .btn-flat-primary.active, .btn-flat-primary:focus {
  background-color: rgba(189, 131, 43, 0.2);
  color: #bd832b;
}
.btn-flat-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bd832b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-relief-primary {
  background-color: #bd832b;
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
  color: #fff;
  transition: all 0.2s ease;
}
.btn-relief-primary:hover:not(.disabled):not(:disabled) {
  background-color: #d09131;
}
.btn-relief-primary:active, .btn-relief-primary.active, .btn-relief-primary:focus {
  background-color: #a87526;
}
.btn-relief-primary:hover {
  color: #fff;
}
.btn-relief-primary:active, .btn-relief-primary.active {
  outline: none;
  box-shadow: none;
  transform: translateY(3px);
}

.btn-outline-primary {
  border: 1px solid #bd832b !important;
  background-color: transparent;
  color: #bd832b;
}
.btn-outline-primary:hover:not(.disabled):not(:disabled) {
  background-color: rgba(189, 131, 43, 0.04);
  color: #bd832b;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):focus {
  background-color: rgba(189, 131, 43, 0.2);
  color: #bd832b;
}
.btn-outline-primary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bd832b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.btn-outline-primary.show.dropdown-toggle {
  background-color: rgba(189, 131, 43, 0.2);
  color: #bd832b;
}

.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary {
  color: #bd832b;
  background-color: rgba(189, 131, 43, 0.2) !important;
}

.btn-outline-primary.waves-effect .waves-ripple,
.btn-flat-primary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(189, 131, 43, 0.2) 0, rgba(189, 131, 43, 0.3) 40%, rgba(189, 131, 43, 0.4) 50%, rgba(189, 131, 43, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.bullet.bullet-primary {
  background-color: #bd832b;
}

.modal.modal-primary .modal-header .modal-title {
  color: #bd832b;
}
.modal.modal-primary .modal-header .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23bd832b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat !important;
  color: #bd832b !important;
}

.pagination-primary .page-item.active .page-link {
  background: #bd832b !important;
  color: #fff;
}
.pagination-primary .page-item.active .page-link:hover {
  color: #fff;
}
.pagination-primary .page-item .page-link:hover {
  color: #bd832b;
}
.pagination-primary .page-item.prev-item .page-link:hover, .pagination-primary .page-item.next-item .page-link:hover {
  background: #bd832b;
  color: #fff;
}
.pagination-primary .page-item.next-item .page-link:active:after, .pagination-primary .page-item.next-item .page-link:hover:after, .pagination-primary .page-item.next .page-link:active:after, .pagination-primary .page-item.next .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bd832b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.pagination-primary .page-item.prev-item .page-link:active:before, .pagination-primary .page-item.prev-item .page-link:hover:before, .pagination-primary .page-item.prev .page-link:active:before, .pagination-primary .page-item.prev .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bd832b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.nav-pill-primary .nav-item .nav-link.active {
  color: #fff;
  background-color: #bd832b !important;
  border-color: #bd832b;
  box-shadow: 0 4px 18px -4px rgba(189, 131, 43, 0.65);
}

.progress-bar-primary {
  background-color: rgba(189, 131, 43, 0.12);
}
.progress-bar-primary .progress-bar {
  background-color: #bd832b;
}

.timeline .timeline-point-primary {
  border-color: #bd832b !important;
}
.timeline .timeline-point-primary i,
.timeline .timeline-point-primary svg {
  stroke: #bd832b !important;
}
.timeline .timeline-point-primary.timeline-point-indicator {
  background-color: #bd832b !important;
}
.timeline .timeline-point-primary.timeline-point-indicator:before {
  background: rgba(189, 131, 43, 0.12) !important;
}

.divider.divider-primary .divider-text:before, .divider.divider-primary .divider-text:after {
  border-color: #bd832b !important;
}

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bd832b !important;
}

.form-check-primary .form-check-input:checked {
  border-color: #bd832b;
  background-color: #bd832b;
}
.form-check-primary .form-check-input:not(:disabled):checked, .form-check-primary .form-check-input:not(:disabled):focus {
  border-color: #bd832b;
  box-shadow: 0 2px 4px 0 rgba(189, 131, 43, 0.4);
}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #bd832b !important;
  border-color: #bd832b !important;
}

.bg-secondary .card-header,
.bg-secondary .card-footer {
  background-color: transparent;
}

.alert-secondary {
  background: rgba(130, 134, 139, 0.12) !important;
  color: #82868b !important;
}
.alert-secondary .alert-heading {
  box-shadow: rgba(130, 134, 139, 0.4) 0px 6px 15px -7px;
}
.alert-secondary .alert-link {
  color: #75797e !important;
}
.alert-secondary .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2382868b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  color: #82868b !important;
}

.bg-light-secondary {
  background: rgba(130, 134, 139, 0.12) !important;
  color: #82868b !important;
}
.bg-light-secondary.fc-h-event, .bg-light-secondary.fc-v-event {
  border-color: rgba(130, 134, 139, 0.1);
}
.bg-light-secondary .fc-list-event-dot {
  border-color: #82868b !important;
}
.bg-light-secondary.fc-list-event:hover td {
  background: rgba(130, 134, 139, 0.1) !important;
}
.bg-light-secondary.fc-list-event .fc-list-event-title {
  color: #6e6b7b;
}

.avatar.bg-light-secondary {
  color: #82868b !important;
}

.border-secondary {
  border: 1px solid #82868b !important;
}

.border-top-secondary {
  border-top: 1px solid #82868b;
}

.border-bottom-secondary {
  border-bottom: 1px solid #82868b;
}

.border-start-secondary {
  border-left: 1px solid #82868b;
}

.border-end-secondary {
  border-right: 1px solid #82868b;
}

.bg-secondary.badge-glow,
.border-secondary.badge-glow {
  box-shadow: 0px 0px 10px #82868b;
}

.badge.badge-light-secondary {
  background-color: rgba(130, 134, 139, 0.12);
  color: #82868b !important;
}

.overlay-secondary {
  background: #82868b;
  /* The Fallback */
  background: rgba(130, 134, 139, 0.6);
}

.btn-secondary {
  border-color: #82868b !important;
  background-color: #82868b !important;
  color: #fff !important;
}
.btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
  color: #fff;
  background-color: #75797e !important;
}
.btn-secondary:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #82868b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary {
  color: #fff;
  background-color: #75797e !important;
}

.btn-flat-secondary {
  background-color: transparent;
  color: #82868b;
}
.btn-flat-secondary:hover {
  color: #82868b;
}
.btn-flat-secondary:hover:not(.disabled):not(:disabled) {
  background-color: rgba(130, 134, 139, 0.12);
}
.btn-flat-secondary:active, .btn-flat-secondary.active, .btn-flat-secondary:focus {
  background-color: rgba(130, 134, 139, 0.2);
  color: #82868b;
}
.btn-flat-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-relief-secondary {
  background-color: #82868b;
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
  color: #fff;
  transition: all 0.2s ease;
}
.btn-relief-secondary:hover:not(.disabled):not(:disabled) {
  background-color: #8f9397;
}
.btn-relief-secondary:active, .btn-relief-secondary.active, .btn-relief-secondary:focus {
  background-color: #75797e;
}
.btn-relief-secondary:hover {
  color: #fff;
}
.btn-relief-secondary:active, .btn-relief-secondary.active {
  outline: none;
  box-shadow: none;
  transform: translateY(3px);
}

.btn-outline-secondary {
  border: 1px solid #82868b !important;
  background-color: transparent;
  color: #82868b;
}
.btn-outline-secondary:hover:not(.disabled):not(:disabled) {
  background-color: rgba(130, 134, 139, 0.04);
  color: #82868b;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):focus {
  background-color: rgba(130, 134, 139, 0.2);
  color: #82868b;
}
.btn-outline-secondary.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.btn-outline-secondary.show.dropdown-toggle {
  background-color: rgba(130, 134, 139, 0.2);
  color: #82868b;
}

.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary {
  color: #82868b;
  background-color: rgba(130, 134, 139, 0.2) !important;
}

.btn-outline-secondary.waves-effect .waves-ripple,
.btn-flat-secondary.waves-effect .waves-ripple {
  background: radial-gradient(rgba(130, 134, 139, 0.2) 0, rgba(130, 134, 139, 0.3) 40%, rgba(130, 134, 139, 0.4) 50%, rgba(130, 134, 139, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.bullet.bullet-secondary {
  background-color: #82868b;
}

.modal.modal-secondary .modal-header .modal-title {
  color: #82868b;
}
.modal.modal-secondary .modal-header .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2382868b'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat !important;
  color: #82868b !important;
}

.pagination-secondary .page-item.active .page-link {
  background: #82868b !important;
  color: #fff;
}
.pagination-secondary .page-item.active .page-link:hover {
  color: #fff;
}
.pagination-secondary .page-item .page-link:hover {
  color: #82868b;
}
.pagination-secondary .page-item.prev-item .page-link:hover, .pagination-secondary .page-item.next-item .page-link:hover {
  background: #82868b;
  color: #fff;
}
.pagination-secondary .page-item.next-item .page-link:active:after, .pagination-secondary .page-item.next-item .page-link:hover:after, .pagination-secondary .page-item.next .page-link:active:after, .pagination-secondary .page-item.next .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.pagination-secondary .page-item.prev-item .page-link:active:before, .pagination-secondary .page-item.prev-item .page-link:hover:before, .pagination-secondary .page-item.prev .page-link:active:before, .pagination-secondary .page-item.prev .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.nav-pill-secondary .nav-item .nav-link.active {
  color: #fff;
  background-color: #82868b !important;
  border-color: #82868b;
  box-shadow: 0 4px 18px -4px rgba(130, 134, 139, 0.65);
}

.progress-bar-secondary {
  background-color: rgba(130, 134, 139, 0.12);
}
.progress-bar-secondary .progress-bar {
  background-color: #82868b;
}

.timeline .timeline-point-secondary {
  border-color: #82868b !important;
}
.timeline .timeline-point-secondary i,
.timeline .timeline-point-secondary svg {
  stroke: #82868b !important;
}
.timeline .timeline-point-secondary.timeline-point-indicator {
  background-color: #82868b !important;
}
.timeline .timeline-point-secondary.timeline-point-indicator:before {
  background: rgba(130, 134, 139, 0.12) !important;
}

.divider.divider-secondary .divider-text:before, .divider.divider-secondary .divider-text:after {
  border-color: #82868b !important;
}

input:focus ~ .bg-secondary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #82868b !important;
}

.form-check-secondary .form-check-input:checked {
  border-color: #82868b;
  background-color: #82868b;
}
.form-check-secondary .form-check-input:not(:disabled):checked, .form-check-secondary .form-check-input:not(:disabled):focus {
  border-color: #82868b;
  box-shadow: 0 2px 4px 0 rgba(130, 134, 139, 0.4);
}

.select2-secondary .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #82868b !important;
  border-color: #82868b !important;
}

.bg-success .card-header,
.bg-success .card-footer {
  background-color: transparent;
}

.alert-success {
  background: rgba(40, 199, 111, 0.12) !important;
  color: #28c76f !important;
}
.alert-success .alert-heading {
  box-shadow: rgba(40, 199, 111, 0.4) 0px 6px 15px -7px;
}
.alert-success .alert-link {
  color: #24b263 !important;
}
.alert-success .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2328c76f'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  color: #28c76f !important;
}

.bg-light-success {
  background: rgba(40, 199, 111, 0.12) !important;
  color: #28c76f !important;
}
.bg-light-success.fc-h-event, .bg-light-success.fc-v-event {
  border-color: rgba(40, 199, 111, 0.1);
}
.bg-light-success .fc-list-event-dot {
  border-color: #28c76f !important;
}
.bg-light-success.fc-list-event:hover td {
  background: rgba(40, 199, 111, 0.1) !important;
}
.bg-light-success.fc-list-event .fc-list-event-title {
  color: #6e6b7b;
}

.avatar.bg-light-success {
  color: #28c76f !important;
}

.border-success {
  border: 1px solid #28c76f !important;
}

.border-top-success {
  border-top: 1px solid #28c76f;
}

.border-bottom-success {
  border-bottom: 1px solid #28c76f;
}

.border-start-success {
  border-left: 1px solid #28c76f;
}

.border-end-success {
  border-right: 1px solid #28c76f;
}

.bg-success.badge-glow,
.border-success.badge-glow {
  box-shadow: 0px 0px 10px #28c76f;
}

.badge.badge-light-success {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f !important;
}

.overlay-success {
  background: #28c76f;
  /* The Fallback */
  background: rgba(40, 199, 111, 0.6);
}

.btn-success {
  border-color: #28c76f !important;
  background-color: #28c76f !important;
  color: #fff !important;
}
.btn-success:focus, .btn-success:active, .btn-success.active {
  color: #fff;
  background-color: #24b263 !important;
}
.btn-success:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #28c76f;
}
.btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-check:checked + .btn-success, .btn-check:active + .btn-success {
  color: #fff;
  background-color: #24b263 !important;
}

.btn-flat-success {
  background-color: transparent;
  color: #28c76f;
}
.btn-flat-success:hover {
  color: #28c76f;
}
.btn-flat-success:hover:not(.disabled):not(:disabled) {
  background-color: rgba(40, 199, 111, 0.12);
}
.btn-flat-success:active, .btn-flat-success.active, .btn-flat-success:focus {
  background-color: rgba(40, 199, 111, 0.2);
  color: #28c76f;
}
.btn-flat-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-relief-success {
  background-color: #28c76f;
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
  color: #fff;
  transition: all 0.2s ease;
}
.btn-relief-success:hover:not(.disabled):not(:disabled) {
  background-color: #33d67c;
}
.btn-relief-success:active, .btn-relief-success.active, .btn-relief-success:focus {
  background-color: #24b263;
}
.btn-relief-success:hover {
  color: #fff;
}
.btn-relief-success:active, .btn-relief-success.active {
  outline: none;
  box-shadow: none;
  transform: translateY(3px);
}

.btn-outline-success {
  border: 1px solid #28c76f !important;
  background-color: transparent;
  color: #28c76f;
}
.btn-outline-success:hover:not(.disabled):not(:disabled) {
  background-color: rgba(40, 199, 111, 0.04);
  color: #28c76f;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):focus {
  background-color: rgba(40, 199, 111, 0.2);
  color: #28c76f;
}
.btn-outline-success.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.btn-outline-success.show.dropdown-toggle {
  background-color: rgba(40, 199, 111, 0.2);
  color: #28c76f;
}

.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success {
  color: #28c76f;
  background-color: rgba(40, 199, 111, 0.2) !important;
}

.btn-outline-success.waves-effect .waves-ripple,
.btn-flat-success.waves-effect .waves-ripple {
  background: radial-gradient(rgba(40, 199, 111, 0.2) 0, rgba(40, 199, 111, 0.3) 40%, rgba(40, 199, 111, 0.4) 50%, rgba(40, 199, 111, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.bullet.bullet-success {
  background-color: #28c76f;
}

.modal.modal-success .modal-header .modal-title {
  color: #28c76f;
}
.modal.modal-success .modal-header .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2328c76f'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat !important;
  color: #28c76f !important;
}

.pagination-success .page-item.active .page-link {
  background: #28c76f !important;
  color: #fff;
}
.pagination-success .page-item.active .page-link:hover {
  color: #fff;
}
.pagination-success .page-item .page-link:hover {
  color: #28c76f;
}
.pagination-success .page-item.prev-item .page-link:hover, .pagination-success .page-item.next-item .page-link:hover {
  background: #28c76f;
  color: #fff;
}
.pagination-success .page-item.next-item .page-link:active:after, .pagination-success .page-item.next-item .page-link:hover:after, .pagination-success .page-item.next .page-link:active:after, .pagination-success .page-item.next .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.pagination-success .page-item.prev-item .page-link:active:before, .pagination-success .page-item.prev-item .page-link:hover:before, .pagination-success .page-item.prev .page-link:active:before, .pagination-success .page-item.prev .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.nav-pill-success .nav-item .nav-link.active {
  color: #fff;
  background-color: #28c76f !important;
  border-color: #28c76f;
  box-shadow: 0 4px 18px -4px rgba(40, 199, 111, 0.65);
}

.progress-bar-success {
  background-color: rgba(40, 199, 111, 0.12);
}
.progress-bar-success .progress-bar {
  background-color: #28c76f;
}

.timeline .timeline-point-success {
  border-color: #28c76f !important;
}
.timeline .timeline-point-success i,
.timeline .timeline-point-success svg {
  stroke: #28c76f !important;
}
.timeline .timeline-point-success.timeline-point-indicator {
  background-color: #28c76f !important;
}
.timeline .timeline-point-success.timeline-point-indicator:before {
  background: rgba(40, 199, 111, 0.12) !important;
}

.divider.divider-success .divider-text:before, .divider.divider-success .divider-text:after {
  border-color: #28c76f !important;
}

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #28c76f !important;
}

.form-check-success .form-check-input:checked {
  border-color: #28c76f;
  background-color: #28c76f;
}
.form-check-success .form-check-input:not(:disabled):checked, .form-check-success .form-check-input:not(:disabled):focus {
  border-color: #28c76f;
  box-shadow: 0 2px 4px 0 rgba(40, 199, 111, 0.4);
}

.select2-success .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #28c76f !important;
  border-color: #28c76f !important;
}

.bg-info .card-header,
.bg-info .card-footer {
  background-color: transparent;
}

.alert-info {
  background: rgba(0, 207, 232, 0.12) !important;
  color: #00cfe8 !important;
}
.alert-info .alert-heading {
  box-shadow: rgba(0, 207, 232, 0.4) 0px 6px 15px -7px;
}
.alert-info .alert-link {
  color: #00b8cf !important;
}
.alert-info .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300cfe8'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  color: #00cfe8 !important;
}

.bg-light-info {
  background: rgba(0, 207, 232, 0.12) !important;
  color: #00cfe8 !important;
}
.bg-light-info.fc-h-event, .bg-light-info.fc-v-event {
  border-color: rgba(0, 207, 232, 0.1);
}
.bg-light-info .fc-list-event-dot {
  border-color: #00cfe8 !important;
}
.bg-light-info.fc-list-event:hover td {
  background: rgba(0, 207, 232, 0.1) !important;
}
.bg-light-info.fc-list-event .fc-list-event-title {
  color: #6e6b7b;
}

.avatar.bg-light-info {
  color: #00cfe8 !important;
}

.border-info {
  border: 1px solid #00cfe8 !important;
}

.border-top-info {
  border-top: 1px solid #00cfe8;
}

.border-bottom-info {
  border-bottom: 1px solid #00cfe8;
}

.border-start-info {
  border-left: 1px solid #00cfe8;
}

.border-end-info {
  border-right: 1px solid #00cfe8;
}

.bg-info.badge-glow,
.border-info.badge-glow {
  box-shadow: 0px 0px 10px #00cfe8;
}

.badge.badge-light-info {
  background-color: rgba(0, 207, 232, 0.12);
  color: #00cfe8 !important;
}

.overlay-info {
  background: #00cfe8;
  /* The Fallback */
  background: rgba(0, 207, 232, 0.6);
}

.btn-info {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
  color: #fff !important;
}
.btn-info:focus, .btn-info:active, .btn-info.active {
  color: #fff;
  background-color: #00b8cf !important;
}
.btn-info:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #00cfe8;
}
.btn-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-check:checked + .btn-info, .btn-check:active + .btn-info {
  color: #fff;
  background-color: #00b8cf !important;
}

.btn-flat-info {
  background-color: transparent;
  color: #00cfe8;
}
.btn-flat-info:hover {
  color: #00cfe8;
}
.btn-flat-info:hover:not(.disabled):not(:disabled) {
  background-color: rgba(0, 207, 232, 0.12);
}
.btn-flat-info:active, .btn-flat-info.active, .btn-flat-info:focus {
  background-color: rgba(0, 207, 232, 0.2);
  color: #00cfe8;
}
.btn-flat-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-relief-info {
  background-color: #00cfe8;
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
  color: #fff;
  transition: all 0.2s ease;
}
.btn-relief-info:hover:not(.disabled):not(:disabled) {
  background-color: #03e4ff;
}
.btn-relief-info:active, .btn-relief-info.active, .btn-relief-info:focus {
  background-color: #00b8cf;
}
.btn-relief-info:hover {
  color: #fff;
}
.btn-relief-info:active, .btn-relief-info.active {
  outline: none;
  box-shadow: none;
  transform: translateY(3px);
}

.btn-outline-info {
  border: 1px solid #00cfe8 !important;
  background-color: transparent;
  color: #00cfe8;
}
.btn-outline-info:hover:not(.disabled):not(:disabled) {
  background-color: rgba(0, 207, 232, 0.04);
  color: #00cfe8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):focus {
  background-color: rgba(0, 207, 232, 0.2);
  color: #00cfe8;
}
.btn-outline-info.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.btn-outline-info.show.dropdown-toggle {
  background-color: rgba(0, 207, 232, 0.2);
  color: #00cfe8;
}

.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info {
  color: #00cfe8;
  background-color: rgba(0, 207, 232, 0.2) !important;
}

.btn-outline-info.waves-effect .waves-ripple,
.btn-flat-info.waves-effect .waves-ripple {
  background: radial-gradient(rgba(0, 207, 232, 0.2) 0, rgba(0, 207, 232, 0.3) 40%, rgba(0, 207, 232, 0.4) 50%, rgba(0, 207, 232, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.bullet.bullet-info {
  background-color: #00cfe8;
}

.modal.modal-info .modal-header .modal-title {
  color: #00cfe8;
}
.modal.modal-info .modal-header .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300cfe8'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat !important;
  color: #00cfe8 !important;
}

.pagination-info .page-item.active .page-link {
  background: #00cfe8 !important;
  color: #fff;
}
.pagination-info .page-item.active .page-link:hover {
  color: #fff;
}
.pagination-info .page-item .page-link:hover {
  color: #00cfe8;
}
.pagination-info .page-item.prev-item .page-link:hover, .pagination-info .page-item.next-item .page-link:hover {
  background: #00cfe8;
  color: #fff;
}
.pagination-info .page-item.next-item .page-link:active:after, .pagination-info .page-item.next-item .page-link:hover:after, .pagination-info .page-item.next .page-link:active:after, .pagination-info .page-item.next .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.pagination-info .page-item.prev-item .page-link:active:before, .pagination-info .page-item.prev-item .page-link:hover:before, .pagination-info .page-item.prev .page-link:active:before, .pagination-info .page-item.prev .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.nav-pill-info .nav-item .nav-link.active {
  color: #fff;
  background-color: #00cfe8 !important;
  border-color: #00cfe8;
  box-shadow: 0 4px 18px -4px rgba(0, 207, 232, 0.65);
}

.progress-bar-info {
  background-color: rgba(0, 207, 232, 0.12);
}
.progress-bar-info .progress-bar {
  background-color: #00cfe8;
}

.timeline .timeline-point-info {
  border-color: #00cfe8 !important;
}
.timeline .timeline-point-info i,
.timeline .timeline-point-info svg {
  stroke: #00cfe8 !important;
}
.timeline .timeline-point-info.timeline-point-indicator {
  background-color: #00cfe8 !important;
}
.timeline .timeline-point-info.timeline-point-indicator:before {
  background: rgba(0, 207, 232, 0.12) !important;
}

.divider.divider-info .divider-text:before, .divider.divider-info .divider-text:after {
  border-color: #00cfe8 !important;
}

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00cfe8 !important;
}

.form-check-info .form-check-input:checked {
  border-color: #00cfe8;
  background-color: #00cfe8;
}
.form-check-info .form-check-input:not(:disabled):checked, .form-check-info .form-check-input:not(:disabled):focus {
  border-color: #00cfe8;
  box-shadow: 0 2px 4px 0 rgba(0, 207, 232, 0.4);
}

.select2-info .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #00cfe8 !important;
  border-color: #00cfe8 !important;
}

.bg-warning .card-header,
.bg-warning .card-footer {
  background-color: transparent;
}

.alert-warning {
  background: rgba(255, 159, 67, 0.12) !important;
  color: #ff9f43 !important;
}
.alert-warning .alert-heading {
  box-shadow: rgba(255, 159, 67, 0.4) 0px 6px 15px -7px;
}
.alert-warning .alert-link {
  color: #ff922a !important;
}
.alert-warning .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff9f43'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  color: #ff9f43 !important;
}

.bg-light-warning {
  background: rgba(255, 159, 67, 0.12) !important;
  color: #ff9f43 !important;
}
.bg-light-warning.fc-h-event, .bg-light-warning.fc-v-event {
  border-color: rgba(255, 159, 67, 0.1);
}
.bg-light-warning .fc-list-event-dot {
  border-color: #ff9f43 !important;
}
.bg-light-warning.fc-list-event:hover td {
  background: rgba(255, 159, 67, 0.1) !important;
}
.bg-light-warning.fc-list-event .fc-list-event-title {
  color: #6e6b7b;
}

.avatar.bg-light-warning {
  color: #ff9f43 !important;
}

.border-warning {
  border: 1px solid #ff9f43 !important;
}

.border-top-warning {
  border-top: 1px solid #ff9f43;
}

.border-bottom-warning {
  border-bottom: 1px solid #ff9f43;
}

.border-start-warning {
  border-left: 1px solid #ff9f43;
}

.border-end-warning {
  border-right: 1px solid #ff9f43;
}

.bg-warning.badge-glow,
.border-warning.badge-glow {
  box-shadow: 0px 0px 10px #ff9f43;
}

.badge.badge-light-warning {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43 !important;
}

.overlay-warning {
  background: #ff9f43;
  /* The Fallback */
  background: rgba(255, 159, 67, 0.6);
}

.btn-warning {
  border-color: #ff9f43 !important;
  background-color: #ff9f43 !important;
  color: #fff !important;
}
.btn-warning:focus, .btn-warning:active, .btn-warning.active {
  color: #fff;
  background-color: #ff922a !important;
}
.btn-warning:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #ff9f43;
}
.btn-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning {
  color: #fff;
  background-color: #ff922a !important;
}

.btn-flat-warning {
  background-color: transparent;
  color: #ff9f43;
}
.btn-flat-warning:hover {
  color: #ff9f43;
}
.btn-flat-warning:hover:not(.disabled):not(:disabled) {
  background-color: rgba(255, 159, 67, 0.12);
}
.btn-flat-warning:active, .btn-flat-warning.active, .btn-flat-warning:focus {
  background-color: rgba(255, 159, 67, 0.2);
  color: #ff9f43;
}
.btn-flat-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-relief-warning {
  background-color: #ff9f43;
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
  color: #fff;
  transition: all 0.2s ease;
}
.btn-relief-warning:hover:not(.disabled):not(:disabled) {
  background-color: #ffac5d;
}
.btn-relief-warning:active, .btn-relief-warning.active, .btn-relief-warning:focus {
  background-color: #ff922a;
}
.btn-relief-warning:hover {
  color: #fff;
}
.btn-relief-warning:active, .btn-relief-warning.active {
  outline: none;
  box-shadow: none;
  transform: translateY(3px);
}

.btn-outline-warning {
  border: 1px solid #ff9f43 !important;
  background-color: transparent;
  color: #ff9f43;
}
.btn-outline-warning:hover:not(.disabled):not(:disabled) {
  background-color: rgba(255, 159, 67, 0.04);
  color: #ff9f43;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):focus {
  background-color: rgba(255, 159, 67, 0.2);
  color: #ff9f43;
}
.btn-outline-warning.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.btn-outline-warning.show.dropdown-toggle {
  background-color: rgba(255, 159, 67, 0.2);
  color: #ff9f43;
}

.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning {
  color: #ff9f43;
  background-color: rgba(255, 159, 67, 0.2) !important;
}

.btn-outline-warning.waves-effect .waves-ripple,
.btn-flat-warning.waves-effect .waves-ripple {
  background: radial-gradient(rgba(255, 159, 67, 0.2) 0, rgba(255, 159, 67, 0.3) 40%, rgba(255, 159, 67, 0.4) 50%, rgba(255, 159, 67, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.bullet.bullet-warning {
  background-color: #ff9f43;
}

.modal.modal-warning .modal-header .modal-title {
  color: #ff9f43;
}
.modal.modal-warning .modal-header .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff9f43'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat !important;
  color: #ff9f43 !important;
}

.pagination-warning .page-item.active .page-link {
  background: #ff9f43 !important;
  color: #fff;
}
.pagination-warning .page-item.active .page-link:hover {
  color: #fff;
}
.pagination-warning .page-item .page-link:hover {
  color: #ff9f43;
}
.pagination-warning .page-item.prev-item .page-link:hover, .pagination-warning .page-item.next-item .page-link:hover {
  background: #ff9f43;
  color: #fff;
}
.pagination-warning .page-item.next-item .page-link:active:after, .pagination-warning .page-item.next-item .page-link:hover:after, .pagination-warning .page-item.next .page-link:active:after, .pagination-warning .page-item.next .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.pagination-warning .page-item.prev-item .page-link:active:before, .pagination-warning .page-item.prev-item .page-link:hover:before, .pagination-warning .page-item.prev .page-link:active:before, .pagination-warning .page-item.prev .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.nav-pill-warning .nav-item .nav-link.active {
  color: #fff;
  background-color: #ff9f43 !important;
  border-color: #ff9f43;
  box-shadow: 0 4px 18px -4px rgba(255, 159, 67, 0.65);
}

.progress-bar-warning {
  background-color: rgba(255, 159, 67, 0.12);
}
.progress-bar-warning .progress-bar {
  background-color: #ff9f43;
}

.timeline .timeline-point-warning {
  border-color: #ff9f43 !important;
}
.timeline .timeline-point-warning i,
.timeline .timeline-point-warning svg {
  stroke: #ff9f43 !important;
}
.timeline .timeline-point-warning.timeline-point-indicator {
  background-color: #ff9f43 !important;
}
.timeline .timeline-point-warning.timeline-point-indicator:before {
  background: rgba(255, 159, 67, 0.12) !important;
}

.divider.divider-warning .divider-text:before, .divider.divider-warning .divider-text:after {
  border-color: #ff9f43 !important;
}

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9f43 !important;
}

.form-check-warning .form-check-input:checked {
  border-color: #ff9f43;
  background-color: #ff9f43;
}
.form-check-warning .form-check-input:not(:disabled):checked, .form-check-warning .form-check-input:not(:disabled):focus {
  border-color: #ff9f43;
  box-shadow: 0 2px 4px 0 rgba(255, 159, 67, 0.4);
}

.select2-warning .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #ff9f43 !important;
  border-color: #ff9f43 !important;
}

.bg-danger .card-header,
.bg-danger .card-footer {
  background-color: transparent;
}

.alert-danger {
  background: rgba(234, 84, 85, 0.12) !important;
  color: #ea5455 !important;
}
.alert-danger .alert-heading {
  box-shadow: rgba(234, 84, 85, 0.4) 0px 6px 15px -7px;
}
.alert-danger .alert-link {
  color: #e73d3e !important;
}
.alert-danger .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ea5455'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  color: #ea5455 !important;
}

.bg-light-danger {
  background: rgba(234, 84, 85, 0.12) !important;
  color: #ea5455 !important;
}
.bg-light-danger.fc-h-event, .bg-light-danger.fc-v-event {
  border-color: rgba(234, 84, 85, 0.1);
}
.bg-light-danger .fc-list-event-dot {
  border-color: #ea5455 !important;
}
.bg-light-danger.fc-list-event:hover td {
  background: rgba(234, 84, 85, 0.1) !important;
}
.bg-light-danger.fc-list-event .fc-list-event-title {
  color: #6e6b7b;
}

.avatar.bg-light-danger {
  color: #ea5455 !important;
}

.border-danger {
  border: 1px solid #ea5455 !important;
}

.border-top-danger {
  border-top: 1px solid #ea5455;
}

.border-bottom-danger {
  border-bottom: 1px solid #ea5455;
}

.border-start-danger {
  border-left: 1px solid #ea5455;
}

.border-end-danger {
  border-right: 1px solid #ea5455;
}

.bg-danger.badge-glow,
.border-danger.badge-glow {
  box-shadow: 0px 0px 10px #ea5455;
}

.badge.badge-light-danger {
  background-color: rgba(234, 84, 85, 0.12);
  color: #ea5455 !important;
}

.overlay-danger {
  background: #ea5455;
  /* The Fallback */
  background: rgba(234, 84, 85, 0.6);
}

.btn-danger {
  border-color: #ea5455 !important;
  background-color: #ea5455 !important;
  color: #fff !important;
}
.btn-danger:focus, .btn-danger:active, .btn-danger.active {
  color: #fff;
  background-color: #e73d3e !important;
}
.btn-danger:hover:not(.disabled):not(:disabled) {
  box-shadow: 0 8px 25px -8px #ea5455;
}
.btn-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger {
  color: #fff;
  background-color: #e73d3e !important;
}

.btn-flat-danger {
  background-color: transparent;
  color: #ea5455;
}
.btn-flat-danger:hover {
  color: #ea5455;
}
.btn-flat-danger:hover:not(.disabled):not(:disabled) {
  background-color: rgba(234, 84, 85, 0.12);
}
.btn-flat-danger:active, .btn-flat-danger.active, .btn-flat-danger:focus {
  background-color: rgba(234, 84, 85, 0.2);
  color: #ea5455;
}
.btn-flat-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.btn-relief-danger {
  background-color: #ea5455;
  box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, 0.2);
  color: #fff;
  transition: all 0.2s ease;
}
.btn-relief-danger:hover:not(.disabled):not(:disabled) {
  background-color: #ed6b6c;
}
.btn-relief-danger:active, .btn-relief-danger.active, .btn-relief-danger:focus {
  background-color: #e73d3e;
}
.btn-relief-danger:hover {
  color: #fff;
}
.btn-relief-danger:active, .btn-relief-danger.active {
  outline: none;
  box-shadow: none;
  transform: translateY(3px);
}

.btn-outline-danger {
  border: 1px solid #ea5455 !important;
  background-color: transparent;
  color: #ea5455;
}
.btn-outline-danger:hover:not(.disabled):not(:disabled) {
  background-color: rgba(234, 84, 85, 0.04);
  color: #ea5455;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):focus {
  background-color: rgba(234, 84, 85, 0.2);
  color: #ea5455;
}
.btn-outline-danger.dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.btn-outline-danger.show.dropdown-toggle {
  background-color: rgba(234, 84, 85, 0.2);
  color: #ea5455;
}

.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger {
  color: #ea5455;
  background-color: rgba(234, 84, 85, 0.2) !important;
}

.btn-outline-danger.waves-effect .waves-ripple,
.btn-flat-danger.waves-effect .waves-ripple {
  background: radial-gradient(rgba(234, 84, 85, 0.2) 0, rgba(234, 84, 85, 0.3) 40%, rgba(234, 84, 85, 0.4) 50%, rgba(234, 84, 85, 0.5) 60%, rgba(255, 255, 255, 0) 70%);
}

.bullet.bullet-danger {
  background-color: #ea5455;
}

.modal.modal-danger .modal-header .modal-title {
  color: #ea5455;
}
.modal.modal-danger .modal-header .btn-close {
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ea5455'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat !important;
  color: #ea5455 !important;
}

.pagination-danger .page-item.active .page-link {
  background: #ea5455 !important;
  color: #fff;
}
.pagination-danger .page-item.active .page-link:hover {
  color: #fff;
}
.pagination-danger .page-item .page-link:hover {
  color: #ea5455;
}
.pagination-danger .page-item.prev-item .page-link:hover, .pagination-danger .page-item.next-item .page-link:hover {
  background: #ea5455;
  color: #fff;
}
.pagination-danger .page-item.next-item .page-link:active:after, .pagination-danger .page-item.next-item .page-link:hover:after, .pagination-danger .page-item.next .page-link:active:after, .pagination-danger .page-item.next .page-link:hover:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}
.pagination-danger .page-item.prev-item .page-link:active:before, .pagination-danger .page-item.prev-item .page-link:hover:before, .pagination-danger .page-item.prev .page-link:active:before, .pagination-danger .page-item.prev .page-link:hover:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important;
}

.nav-pill-danger .nav-item .nav-link.active {
  color: #fff;
  background-color: #ea5455 !important;
  border-color: #ea5455;
  box-shadow: 0 4px 18px -4px rgba(234, 84, 85, 0.65);
}

.progress-bar-danger {
  background-color: rgba(234, 84, 85, 0.12);
}
.progress-bar-danger .progress-bar {
  background-color: #ea5455;
}

.timeline .timeline-point-danger {
  border-color: #ea5455 !important;
}
.timeline .timeline-point-danger i,
.timeline .timeline-point-danger svg {
  stroke: #ea5455 !important;
}
.timeline .timeline-point-danger.timeline-point-indicator {
  background-color: #ea5455 !important;
}
.timeline .timeline-point-danger.timeline-point-indicator:before {
  background: rgba(234, 84, 85, 0.12) !important;
}

.divider.divider-danger .divider-text:before, .divider.divider-danger .divider-text:after {
  border-color: #ea5455 !important;
}

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ea5455 !important;
}

.form-check-danger .form-check-input:checked {
  border-color: #ea5455;
  background-color: #ea5455;
}
.form-check-danger .form-check-input:not(:disabled):checked, .form-check-danger .form-check-input:not(:disabled):focus {
  border-color: #ea5455;
  box-shadow: 0 2px 4px 0 rgba(234, 84, 85, 0.4);
}

.select2-danger .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background: #ea5455 !important;
  border-color: #ea5455 !important;
}

.bg-gradient-dark,
.btn-gradient-dark {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #4b4b4b, #1e1e1e);
  background-repeat: repeat;
}
.dark-layout .bg-gradient-dark,
.dark-layout .btn-gradient-dark {
  background-image: linear-gradient(47deg, #1e1e1e, #4b4b4b);
  background-repeat: repeat;
}
.bg-gradient-dark:hover, .bg-gradient-dark:active,
.btn-gradient-dark:hover,
.btn-gradient-dark:active {
  color: #fff;
}
.bg-gradient-dark:hover:not(.disabled):not(:disabled),
.btn-gradient-dark:hover:not(.disabled):not(:disabled) {
  transform: translateY(-2px);
}
.bg-gradient-dark:active,
.btn-gradient-dark:active {
  transform: translateY(0);
}
.bg-gradient-dark:active, .bg-gradient-dark:focus,
.btn-gradient-dark:active,
.btn-gradient-dark:focus {
  background-image: linear-gradient(47deg, #1e1e1e, #4b4b4b);
  background-repeat: repeat;
}

.bg-gradient-primary,
.btn-gradient-primary {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #bd832b, #d59c46);
  background-repeat: repeat;
}
.bg-gradient-primary:hover, .bg-gradient-primary:active,
.btn-gradient-primary:hover,
.btn-gradient-primary:active {
  color: #fff;
}
.bg-gradient-primary:hover:not(.disabled):not(:disabled),
.btn-gradient-primary:hover:not(.disabled):not(:disabled) {
  transform: translateY(-2px);
}
.bg-gradient-primary:active,
.btn-gradient-primary:active {
  transform: translateY(0);
}
.bg-gradient-primary:active, .bg-gradient-primary:focus,
.btn-gradient-primary:active,
.btn-gradient-primary:focus {
  background-image: linear-gradient(47deg, #936622, #bd832b);
  background-repeat: repeat;
}

.bg-gradient-secondary,
.btn-gradient-secondary {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #82868b, #9ca0a4);
  background-repeat: repeat;
}
.bg-gradient-secondary:hover, .bg-gradient-secondary:active,
.btn-gradient-secondary:hover,
.btn-gradient-secondary:active {
  color: #fff;
}
.bg-gradient-secondary:hover:not(.disabled):not(:disabled),
.btn-gradient-secondary:hover:not(.disabled):not(:disabled) {
  transform: translateY(-2px);
}
.bg-gradient-secondary:active,
.btn-gradient-secondary:active {
  transform: translateY(0);
}
.bg-gradient-secondary:active, .bg-gradient-secondary:focus,
.btn-gradient-secondary:active,
.btn-gradient-secondary:focus {
  background-image: linear-gradient(47deg, #696d71, #82868b);
  background-repeat: repeat;
}

.bg-gradient-success,
.btn-gradient-success {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #28c76f, #48da89);
  background-repeat: repeat;
}
.bg-gradient-success:hover, .bg-gradient-success:active,
.btn-gradient-success:hover,
.btn-gradient-success:active {
  color: #fff;
}
.bg-gradient-success:hover:not(.disabled):not(:disabled),
.btn-gradient-success:hover:not(.disabled):not(:disabled) {
  transform: translateY(-2px);
}
.bg-gradient-success:active,
.btn-gradient-success:active {
  transform: translateY(0);
}
.bg-gradient-success:active, .bg-gradient-success:focus,
.btn-gradient-success:active,
.btn-gradient-success:focus {
  background-image: linear-gradient(47deg, #1f9d57, #28c76f);
  background-repeat: repeat;
}

.bg-gradient-info,
.btn-gradient-info {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #00cfe8, #1ce7ff);
  background-repeat: repeat;
}
.bg-gradient-info:hover, .bg-gradient-info:active,
.btn-gradient-info:hover,
.btn-gradient-info:active {
  color: #fff;
}
.bg-gradient-info:hover:not(.disabled):not(:disabled),
.btn-gradient-info:hover:not(.disabled):not(:disabled) {
  transform: translateY(-2px);
}
.bg-gradient-info:active,
.btn-gradient-info:active {
  transform: translateY(0);
}
.bg-gradient-info:active, .bg-gradient-info:focus,
.btn-gradient-info:active,
.btn-gradient-info:focus {
  background-image: linear-gradient(47deg, #00a1b5, #00cfe8);
  background-repeat: repeat;
}

.bg-gradient-warning,
.btn-gradient-warning {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ff9f43, #ffb976);
  background-repeat: repeat;
}
.bg-gradient-warning:hover, .bg-gradient-warning:active,
.btn-gradient-warning:hover,
.btn-gradient-warning:active {
  color: #fff;
}
.bg-gradient-warning:hover:not(.disabled):not(:disabled),
.btn-gradient-warning:hover:not(.disabled):not(:disabled) {
  transform: translateY(-2px);
}
.bg-gradient-warning:active,
.btn-gradient-warning:active {
  transform: translateY(0);
}
.bg-gradient-warning:active, .bg-gradient-warning:focus,
.btn-gradient-warning:active,
.btn-gradient-warning:focus {
  background-image: linear-gradient(47deg, #ff8510, #ff9f43);
  background-repeat: repeat;
}

.bg-gradient-danger,
.btn-gradient-danger {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ea5455, #f08182);
  background-repeat: repeat;
}
.bg-gradient-danger:hover, .bg-gradient-danger:active,
.btn-gradient-danger:hover,
.btn-gradient-danger:active {
  color: #fff;
}
.bg-gradient-danger:hover:not(.disabled):not(:disabled),
.btn-gradient-danger:hover:not(.disabled):not(:disabled) {
  transform: translateY(-2px);
}
.bg-gradient-danger:active,
.btn-gradient-danger:active {
  transform: translateY(0);
}
.bg-gradient-danger:active, .bg-gradient-danger:focus,
.btn-gradient-danger:active,
.btn-gradient-danger:focus {
  background-image: linear-gradient(47deg, #e42728, #ea5455);
  background-repeat: repeat;
}

html {
  font-size: 14px;
  height: 100%;
  letter-spacing: 0.01rem;
  /*
    * Blank page
    */
}
html body {
  height: 100%;
  background-color: #f8f8f8;
  direction: ltr;
}
html body p {
  line-height: 1.5rem;
}
html .content {
  padding: 0;
  position: relative;
  transition: 300ms ease all;
  backface-visibility: hidden;
  min-height: calc(100% - 3.35rem);
  margin-left: 260px;
}
html .content.app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 2rem 0;
}
html .content.app-content.show-overlay .content-overlay {
  z-index: 10;
  opacity: 1;
}
html .content.app-content.show-overlay .content-overlay ~ .header-navbar-shadow {
  display: none;
}
html .content.app-content .content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 41, 47, 0.5);
  cursor: pointer;
  transition: all 0.7s, z-index 0s;
  z-index: -1;
}
html .content.app-content .content-area-wrapper {
  display: flex;
  position: relative;
  overflow: hidden;
}
html .content.app-content .content-area-wrapper .content-wrapper,
html .content.app-content .content-area-wrapper .content-body {
  height: 100%;
}
html .content .body-content-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}
html .content .body-content-overlay.show {
  visibility: visible;
  transition: all 0.3s ease;
  opacity: 1;
  background-color: rgba(34, 41, 47, 0.2);
  border-radius: 0.1785rem;
}
html .content .content-wrapper .content-header-title {
  font-weight: 500;
  color: #636363;
  padding-right: 1rem;
  border-right: 1px solid #d6dce1;
}
html .content .content-wrapper .content-header-right .dropdown-toggle::after {
  display: none;
}
html .content .content-wrapper .content-header-right .dropdown-toggle i,
html .content .content-wrapper .content-header-right .dropdown-toggle svg {
  margin-right: 0;
}
html .content .content-wrapper .content-header-right .btn-icon {
  padding: 0.8rem;
}
html .content .content-wrapper .content-header-right .btn-icon i,
html .content .content-wrapper .content-header-right .btn-icon svg {
  height: 1rem;
  width: 1rem;
}
html .navbar-floating.footer-static .app-content .content-area-wrapper,
html .navbar-floating.footer-static .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-floating.footer-static .app-content .content-area-wrapper,
html .navbar-floating.footer-static .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
  }
}
html .navbar-floating.footer-hidden .app-content .content-area-wrapper,
html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 4.45rem + 0rem + 1.3rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-floating.footer-hidden .app-content .content-area-wrapper,
html .navbar-floating.footer-hidden .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 1.3rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 1.3rem + 0rem ) );
  }
}
html .navbar-floating.footer-fixed .app-content .content-area-wrapper,
html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-floating.footer-fixed .app-content .content-area-wrapper,
html .navbar-floating.footer-fixed .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 1.3rem + 0rem ) );
  }
}
html .navbar-hidden .app-content {
  padding: 2rem 2rem 0 2rem !important;
}
html .navbar-hidden.footer-static .app-content .content-area-wrapper,
html .navbar-hidden.footer-static .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 1) + 0rem + 3.35rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 0rem + 3.35rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-hidden.footer-static .app-content .content-area-wrapper,
html .navbar-hidden.footer-static .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 0rem + 3.35rem + 0rem + 0rem ) );
  }
}
html .navbar-hidden.footer-hidden .app-content .content-area-wrapper,
html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 0rem + 0rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 0rem + 0rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-hidden.footer-hidden .app-content .content-area-wrapper,
html .navbar-hidden.footer-hidden .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 0rem + 0rem + 0rem ) );
  }
}
html .navbar-hidden.footer-fixed .app-content .content-area-wrapper,
html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 0rem + 3.35rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 0rem + 3.35rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-hidden.footer-fixed .app-content .content-area-wrapper,
html .navbar-hidden.footer-fixed .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 0rem + 3.35rem + 0rem + 0rem ) );
  }
}
html .navbar-static .main-menu {
  top: 0;
}
html .navbar-static .content {
  min-height: calc(100% - calc(4.45rem + 3.35rem));
}
html .navbar-static .app-content {
  padding: 2rem 2rem 0 2rem;
}
html .navbar-static.footer-static .app-content .content-area-wrapper,
html .navbar-static.footer-static .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-static.footer-static .app-content .content-area-wrapper,
html .navbar-static.footer-static .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  }
}
html .navbar-static.footer-hidden .app-content .content-area-wrapper,
html .navbar-static.footer-hidden .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-static.footer-hidden .app-content .content-area-wrapper,
html .navbar-static.footer-hidden .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem ) );
  }
}
html .navbar-static.footer-fixed .app-content .content-area-wrapper,
html .navbar-static.footer-fixed .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-static.footer-fixed .app-content .content-area-wrapper,
html .navbar-static.footer-fixed .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  }
}
html .navbar-sticky .app-content {
  padding: calc(2rem + 4.45rem) 2rem 0 2rem;
}
html .navbar-sticky.footer-static .app-content .content-area-wrapper,
html .navbar-sticky.footer-static .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 1) + 4.45rem + 3.35rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-sticky.footer-static .app-content .content-area-wrapper,
html .navbar-sticky.footer-static .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 1) - calc(0.8rem * 1)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  }
}
html .navbar-sticky.footer-hidden .app-content .content-area-wrapper,
html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 4.45rem + 0rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-sticky.footer-hidden .app-content .content-area-wrapper,
html .navbar-sticky.footer-hidden .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 0rem + 0rem + 0rem ) );
  }
}
html .navbar-sticky.footer-fixed .app-content .content-area-wrapper,
html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
  height: calc( 100vh - calc( calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  height: calc( var(--vh, 1vh) * 100 - calc( calc(2rem * 2) + 4.45rem + 3.35rem + 0rem + 0rem ) );
}
@media (max-width: 575.98px) {
  html .navbar-sticky.footer-fixed .app-content .content-area-wrapper,
html .navbar-sticky.footer-fixed .app-content .kanban-wrapper {
    height: calc( 100vh - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
    height: calc( var(--vh, 1vh) * 100 - calc( calc(calc(2rem * 2) - calc(0.8rem * 2)) + 4.45rem + 3.35rem + 0rem + 0rem ) );
  }
}
html .navbar-static .nav.header-navbar .navbar-container,
html .navbar-sticky .nav.header-navbar .navbar-container {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}
html .navbar-static .nav.header-navbar .navbar-container .search-input .search-list.show,
html .navbar-sticky .nav.header-navbar .navbar-container .search-input .search-list.show {
  width: 98%;
  left: 1%;
}
html .footer-fixed .content.app-content {
  padding-bottom: 3.35rem !important;
}
html [data-col="1-column"] .header-navbar {
  width: 100%;
  left: 0 !important;
}
html [data-col="1-column"] .header-navbar.floating-nav {
  width: calc( 100vw - (100vw - 100%) - calc(2rem * 2) ) !important;
}
html [data-col="1-column"] .content,
html [data-col="1-column"] .footer {
  margin-left: 0px !important;
}
html [data-col="1-column"].horizontal-layout .header-navbar {
  width: 100% !important;
}
html [data-col="1-column"].horizontal-layout .app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 2rem 0 2rem !important;
}
html [data-col="1-column"].horizontal-layout.footer-fixed .app-content {
  padding: calc(2rem + 4.45rem + 1.3rem) 2rem 3.35rem 2rem !important;
}
html .blank-page .content {
  margin-left: 0;
}
html .blank-page .content.app-content {
  overflow: overlay;
  overflow-x: hidden;
  padding: 0 !important;
  transition: none;
}
html .blank-page .content.app-content .header-navbar-shadow {
  display: none;
}
html .blank-page .content-wrapper {
  padding: 0 !important;
}
html .blank-page .content-wrapper .flexbox-container {
  display: flex;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
}
html .pace .pace-progress {
  background: #bd832b;
}

.app-content.center-layout {
  overflow: hidden;
}

/*
* Col 3 layout for detached and general type
*/
@media (min-width: 992px) {
  body .content-right {
    width: calc(100vw - (100vw - 100%) - 260px);
    float: right;
  }
  body .content-left {
    width: calc(100vw - (100vw - 100%) - 260px);
    float: left;
  }
  body .content-detached {
    width: 100%;
  }
  body .content-detached.content-right {
    float: right;
    margin-left: -260px;
  }
  body .content-detached.content-right .content-body {
    margin-left: calc(260px + 2rem);
  }
  body .content-detached.content-left {
    float: left;
    margin-right: -260px;
  }
  body .content-detached.content-left .content-body {
    margin-right: calc(260px + 2rem);
  }

  .sidebar-right.sidebar-sticky {
    float: right !important;
    margin-left: -260px;
    width: 260px !important;
    margin-top: 5.7rem;
  }
}
@media (max-width: 767.98px) {
  html body .app-content {
    padding: calc(2rem - 0.8rem + 4.45rem + 1.3rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }

  html body.navbar-static .app-content {
    padding: calc(2rem - 0.8rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }

  html body.navbar-sticky .app-content {
    padding: calc(2rem - 0.8rem + 4.45rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }

  html body.navbar-hidden .app-content {
    padding: calc(2rem - 0.8rem) calc(2rem - 0.8rem) 0 calc(2rem - 0.8rem) !important;
  }
}
@media (max-width: 767.98px) {
  .navbar-static .app-content .header-navbar .navbar-container,
.navbar-sticky .app-content .header-navbar .navbar-container {
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html.full-screen {
    width: 100%;
  }
  html.full-screen .content.app-content {
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
  }
}
/*=========================================================================================
    File Name: sidebar.scss
    Description: content sidebar specific scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.sidebar {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (min-width: 992px) {
  .sidebar {
    vertical-align: top;
  }
}

.sidebar-fixed {
  position: fixed;
  height: 100%;
  overflow: scroll;
}

.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba(34, 41, 47, 0.5);
  z-index: 997;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidenav-overlay.show {
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease;
}

.drag-target {
  height: 100%;
  width: 40px;
  position: fixed;
  top: 0;
  left: -10px;
  z-index: 1036;
}

.sidebar-detached .sidebar {
  width: 260px;
}

@media (min-width: 992px) {
  .sidebar-left {
    float: left;
  }

  .sidebar-right {
    float: right;
  }
}
@media (max-width: 991.98px) {
  .sidebar-detached .sidebar {
    width: 100%;
  }
}
footer.footer {
  padding: 0.8rem 2rem;
  min-height: 3.35rem;
  transition: 300ms ease all;
}
footer.footer span i,
footer.footer span svg {
  height: 1.51rem;
  width: 1.51rem;
  font-size: 1.51rem;
  margin-left: 0.5rem;
  color: #ea5455;
}
footer.footer .scroll-top {
  padding: 0.81rem 0.83rem;
}
footer.footer-shadow {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
footer.footer-border {
  border-top: 1px solid #ebe9f1;
}

.footer-fixed footer {
  position: fixed !important;
  z-index: 1030;
  right: 0;
  left: 0;
  bottom: 0;
}
.footer-fixed footer.footer-light {
  background: #fff;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.footer-fixed footer.footer-dark {
  background: #b8c2cc;
  color: #fff;
}

.footer-hidden .app-content {
  padding-bottom: 0.1px !important;
}

.scroll-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  display: none;
  z-index: 99;
}

/*=========================================================================================
    File Name: navigation.scss
    Description: Common mixin for menus, contain dark and light version scss.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.main-menu {
  z-index: 1031;
  position: absolute;
  display: table-cell;
  height: 100%;
  overflow: hidden;
}
.main-menu.menu-light {
  color: #6e6b7b;
  background: #fff;
}
.main-menu.menu-light .navigation {
  background: #fff;
}
.main-menu.menu-light .navigation .navigation-header {
  color: #a6a4b0;
  margin: calc(2rem + 0.286rem) 0 0.8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
  letter-spacing: 0.01rem;
}
.main-menu.menu-light .navigation .navigation-header span {
  font-weight: 500;
}
.main-menu.menu-light .navigation li.has-sub > a {
  transition: all 0.5s ease, background 0s, color 0s;
}
.main-menu.menu-light .navigation li a {
  color: #625f6e;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
}
.main-menu.menu-light .navigation li a span.menu-sub-title {
  color: #bdbbc4;
}
.main-menu.menu-light .navigation li a > * {
  transition: transform 0.25s ease;
}
.main-menu.menu-light .navigation li.nav-item a:hover > * {
  transition: transform 0.25s ease;
  transform: translateX(5px);
}
.main-menu.menu-light .navigation li.nav-item ul.menu-content li a:hover > * {
  transition: transform 0.25s ease;
  transform: translateX(5px);
}
.main-menu.menu-light .navigation > li > a {
  margin: 0 15px;
}
.main-menu.menu-light .navigation > li > a .label {
  margin-top: 4px;
  margin-right: 5px;
}
.main-menu.menu-light .navigation > li > a i,
.main-menu.menu-light .navigation > li > a svg {
  position: relative;
  top: 1px;
}
.main-menu.menu-light .navigation > li > ul li:first-of-type {
  margin-top: 7px !important;
}
.main-menu.menu-light .navigation > li > ul li:not(.has-sub) {
  margin: 0 15px;
}
.main-menu.menu-light .navigation > li > ul li.has-sub > a {
  margin: 0 15px;
}
.main-menu.menu-light .navigation > li > ul li.has-sub > ul > li {
  margin: 0 15px;
}
.main-menu.menu-light .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-light .navigation > li.sidebar-group-active > a {
  color: #565360;
  background: whitesmoke;
  border-radius: 6px;
}
.main-menu.menu-light .navigation > li:not(.open) > ul {
  display: none;
}
.main-menu.menu-light .navigation > li:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}
.main-menu.menu-light .navigation > li.active {
  z-index: 1;
}
.main-menu.menu-light .navigation > li.active > a {
  background: linear-gradient(118deg, #bd832b, rgba(189, 131, 43, 0.7));
  box-shadow: 0 0 10px 1px rgba(189, 131, 43, 0.7);
  color: #fff;
  font-weight: 400;
  border-radius: 4px;
}
.main-menu.menu-light .navigation > li .active {
  background: #f0f0f0;
}
.main-menu.menu-light .navigation > li .active > a {
  color: #bd832b;
  margin-bottom: 0;
}
.main-menu.menu-light .navigation > li .active .hover > a,
.main-menu.menu-light .navigation > li .active :hover > a {
  background: whitesmoke;
}
.main-menu.menu-light .navigation > li ul {
  padding: 0;
  margin: 0;
}
.main-menu.menu-light .navigation > li ul li {
  color: #6e6b7b;
  background: transparent;
}
.main-menu.menu-light .navigation > li ul li > a {
  padding: 10px 15px 10px 20px;
}
.main-menu.menu-light .navigation > li ul li ul a {
  padding: 10px 15px 10px 53px;
}
.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul {
  display: none;
}
.main-menu.menu-light .navigation > li ul .has-sub:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}
.main-menu.menu-light .navigation > li ul .open > a,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > a {
  color: #6e6b7b;
  background: whitesmoke;
  border-radius: 6px;
}
.main-menu.menu-light .navigation > li ul .open > ul,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > ul {
  display: block;
}
.main-menu.menu-light .navigation > li ul .open > ul .open > ul,
.main-menu.menu-light .navigation > li ul .sidebar-group-active > ul .open > ul {
  display: block;
}
.main-menu.menu-light .navigation > li ul .hover > a, .main-menu.menu-light .navigation > li ul:hover > a {
  color: #787486;
}
.main-menu.menu-light .navigation > li ul .active {
  background: linear-gradient(118deg, #bd832b, rgba(189, 131, 43, 0.7));
  box-shadow: 0 0 10px 1px rgba(189, 131, 43, 0.7);
  border-radius: 4px;
  z-index: 1;
}
.main-menu.menu-light .navigation > li ul .active > a {
  color: #fff;
}
.main-menu.menu-dark {
  color: #dcdcdc;
  background: #10163a;
}
.main-menu.menu-dark .navigation {
  background: #10163a;
}
.main-menu.menu-dark .navigation .navigation-header {
  color: white;
  margin: calc(2rem + 0.286rem) 0 0.8rem 2.2rem;
  padding: 0;
  line-height: 1.5;
  letter-spacing: 0.01rem;
}
.main-menu.menu-dark .navigation .navigation-header span {
  font-weight: 500;
}
.main-menu.menu-dark .navigation li.has-sub > a {
  transition: all 0.5s ease, background 0s, color 0s;
}
.main-menu.menu-dark .navigation li a {
  color: #d0d0d0;
  padding: 10px 15px 10px 15px;
  line-height: 1.45;
}
.main-menu.menu-dark .navigation li a span.menu-sub-title {
  color: white;
}
.main-menu.menu-dark .navigation li a > * {
  transition: transform 0.25s ease;
}
.main-menu.menu-dark .navigation li.nav-item a:hover > * {
  transition: transform 0.25s ease;
  transform: translateX(5px);
}
.main-menu.menu-dark .navigation li.nav-item ul.menu-content li a:hover > * {
  transition: transform 0.25s ease;
  transform: translateX(5px);
}
.main-menu.menu-dark .navigation > li > a {
  margin: 0 15px;
}
.main-menu.menu-dark .navigation > li > a .label {
  margin-top: 4px;
  margin-right: 5px;
}
.main-menu.menu-dark .navigation > li > a i,
.main-menu.menu-dark .navigation > li > a svg {
  position: relative;
  top: 1px;
}
.main-menu.menu-dark .navigation > li > ul li:first-of-type {
  margin-top: 7px !important;
}
.main-menu.menu-dark .navigation > li > ul li:not(.has-sub) {
  margin: 0 15px;
}
.main-menu.menu-dark .navigation > li > ul li.has-sub > a {
  margin: 0 15px;
}
.main-menu.menu-dark .navigation > li > ul li.has-sub > ul > li {
  margin: 0 15px;
}
.main-menu.menu-dark .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-dark .navigation > li.sidebar-group-active > a {
  color: #c3c3c3;
  background: #0c102a;
  border-radius: 6px;
}
.main-menu.menu-dark .navigation > li:not(.open) > ul {
  display: none;
}
.main-menu.menu-dark .navigation > li:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}
.main-menu.menu-dark .navigation > li.active {
  z-index: 1;
}
.main-menu.menu-dark .navigation > li.active > a {
  background: linear-gradient(118deg, #bd832b, rgba(189, 131, 43, 0.7));
  box-shadow: 0 0 10px 1px rgba(189, 131, 43, 0.7);
  color: #fff;
  font-weight: 400;
  border-radius: 4px;
}
.main-menu.menu-dark .navigation > li .active {
  background: #090d22;
}
.main-menu.menu-dark .navigation > li .active > a {
  color: #bd832b;
  margin-bottom: 0;
}
.main-menu.menu-dark .navigation > li .active .hover > a,
.main-menu.menu-dark .navigation > li .active :hover > a {
  background: #0c102a;
}
.main-menu.menu-dark .navigation > li ul {
  padding: 0;
  margin: 0;
}
.main-menu.menu-dark .navigation > li ul li {
  color: #dcdcdc;
  background: transparent;
}
.main-menu.menu-dark .navigation > li ul li > a {
  padding: 10px 15px 10px 20px;
}
.main-menu.menu-dark .navigation > li ul li ul a {
  padding: 10px 15px 10px 53px;
}
.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul {
  display: none;
}
.main-menu.menu-dark .navigation > li ul .has-sub:not(.open) > ul .active {
  background: transparent;
  box-shadow: none;
}
.main-menu.menu-dark .navigation > li ul .open > a,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > a {
  color: #dcdcdc;
  background: #0c102a;
  border-radius: 6px;
}
.main-menu.menu-dark .navigation > li ul .open > ul,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul {
  display: block;
}
.main-menu.menu-dark .navigation > li ul .open > ul .open > ul,
.main-menu.menu-dark .navigation > li ul .sidebar-group-active > ul .open > ul {
  display: block;
}
.main-menu.menu-dark .navigation > li ul .hover > a, .main-menu.menu-dark .navigation > li ul:hover > a {
  color: #e6e6e6;
}
.main-menu.menu-dark .navigation > li ul .active {
  background: linear-gradient(118deg, #bd832b, rgba(189, 131, 43, 0.7));
  box-shadow: 0 0 10px 1px rgba(189, 131, 43, 0.7);
  border-radius: 4px;
  z-index: 1;
}
.main-menu.menu-dark .navigation > li ul .active > a {
  color: #fff;
}
.main-menu.menu-fixed {
  position: fixed;
}
.main-menu.menu-static {
  height: 100%;
  padding-bottom: calc(100% - 20rem);
}
.main-menu.menu-static .main-menu-content {
  height: unset !important;
}
.main-menu.menu-shadow {
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
}
.main-menu.menu-border {
  border-right: 1px solid #ebe9f1;
}
.main-menu .shadow-bottom {
  display: none;
  position: absolute;
  z-index: 2;
  height: 50px;
  width: 100%;
  pointer-events: none;
  margin-top: -0.7rem;
  filter: blur(5px);
  background: linear-gradient(#fff 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}
.bordered-layout .main-menu .shadow-bottom {
  background: linear-gradient(#f8f8f8 41%, rgba(255, 255, 255, 0.11) 95%, rgba(255, 255, 255, 0));
}
.main-menu.menu-native-scroll .main-menu-content {
  overflow-y: scroll !important;
}
.main-menu .navbar-header {
  height: 100%;
  width: 260px;
  height: 4.45rem;
  position: relative;
  padding: 0.35rem 1rem 0.3rem 1.64rem;
  transition: 300ms ease all, background 0s;
}
.main-menu .navbar-header .navbar-brand {
  display: flex;
  align-items: center;
  margin-top: 1.35rem;
  font-size: inherit;
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 36px;
}
.main-menu .navbar-header .navbar-brand .brand-text {
  color: #bd832b;
  padding-left: 1rem;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: 0.01rem;
  font-size: 1.45rem;
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}
.main-menu .navbar-header .modern-nav-toggle {
  animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}
.main-menu .main-menu-content {
  height: calc(100% - 4.45rem) !important;
  position: relative;
}
.main-menu .main-menu-content .ps__rail-y {
  z-index: 3;
}
.main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.main-menu ul.navigation-main {
  overflow-x: hidden;
  padding-bottom: 0.75rem;
}
.main-menu ul.navigation-main > li:first-child {
  margin-top: 0.5rem;
}
.main-menu a {
  outline: none;
}
.main-menu a:hover, .main-menu a:focus {
  text-decoration: none;
}

.navigation {
  font-size: 1.1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 400;
  overflow-y: hidden;
  padding-bottom: 20px;
}
.navigation .navigation-header {
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 500;
  line-height: 1.2;
  padding: 12px 22px;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.navigation li {
  position: relative;
  white-space: nowrap;
}
.navigation li a {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.navigation li a i,
.navigation li a svg {
  height: 11px;
  width: 11px;
  font-size: 11px;
  margin-right: 1.38rem;
}
.navigation li.menu-item-animating {
  transition: height 0.25s ease-in-out;
}
.navigation li.disabled a {
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none;
}

@media (max-width: 767.98px) {
  .menu-hide .main-menu,
.menu-open .main-menu {
    transition: transform 0.25s, top 0.35s, height 0.35s;
  }

  .main-menu {
    transform: translate3d(-240px, 0, 0);
    backface-visibility: hidden;
    perspective: 1000;
  }

  .menu-open .main-menu {
    transform: translate3d(0, 0, 0);
  }
}
@media (min-width: 768px) {
  .drag-target {
    z-index: 0;
  }
}
.avatar {
  white-space: nowrap;
  background-color: #c3c3c3;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  color: #fff;
  display: inline-flex;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  font-weight: 600;
}
.avatar .avatar-content {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 0.857rem;
}
.avatar .avatar-content .avatar-icon {
  height: 1rem;
  width: 1rem;
}
.avatar [class*=avatar-status-] {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid #fff;
}
.avatar [class*=avatar-status-].avatar-status-lg, .avatar [class*=avatar-status-].avatar-status-xl {
  width: 17px;
  height: 17px;
  border-width: 2px;
}
.avatar .avatar-status-online {
  background-color: #28c76f;
}
.avatar .avatar-status-busy {
  background-color: #ea5455;
}
.avatar .avatar-status-away {
  background-color: #ff9f43;
}
.avatar .avatar-status-offline {
  background-color: #82868b;
}
.avatar.avatar-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 0.357rem;
}
.avatar img {
  border-radius: 50%;
}
.avatar.avatar-xl {
  font-size: 1.5rem;
}
.avatar.avatar-xl img {
  width: 70px;
  height: 70px;
}
.avatar.avatar-xl .avatar-content {
  height: 70px;
  width: 70px;
  font-size: 2.57rem;
}
.avatar.avatar-xl .avatar-content .avatar-icon,
.avatar.avatar-xl .avatar-content i,
.avatar.avatar-xl .avatar-content svg {
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
}
.avatar.avatar-xl [class*=avatar-status-] {
  right: 3px;
  bottom: 0;
}
.avatar.avatar-lg {
  font-size: 1.2rem;
}
.avatar.avatar-lg img {
  width: 50px;
  height: 50px;
}
.avatar.avatar-lg .avatar-content {
  width: 50px;
  height: 50px;
  font-size: 1.714rem;
}
.avatar.avatar-lg .avatar-content .avatar-icon,
.avatar.avatar-lg .avatar-content i,
.avatar.avatar-lg .avatar-content svg {
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
}
.avatar.avatar-lg [class*=avatar-status-] {
  right: 3px;
  bottom: 1px;
}
.avatar.avatar-sm .avatar-content {
  width: 24px;
  height: 24px;
}
.avatar.avatar-sm .avatar-content .avatar-icon,
.avatar.avatar-sm .avatar-content i,
.avatar.avatar-sm .avatar-content svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
}
.avatar.avatar-sm img {
  width: 24px;
  height: 24px;
}
.avatar.avatar-sm [class*=avatar-status-] {
  right: -3px;
  bottom: -2px;
}

.avatar-group {
  display: flex;
  justify-content: flex-start;
}
.avatar-group .avatar {
  transition: all 0.25s ease;
}
.avatar-group .avatar img,
.avatar-group .avatar .avatar-content {
  box-shadow: 0 0 0 2px #fff, inset 0 0 0 1px rgba(34, 41, 47, 0.07);
}
.avatar-group .avatar .avatar-content {
  background-color: #9b9ea2 !important;
}
.avatar-group .avatar:hover {
  transition: all 0.25s ease;
}
.avatar-group .avatar.pull-up:hover {
  transform: translateY(-4px) scale(1.07);
}
.avatar-group .avatar:not(:first-child) {
  margin-left: -0.55rem;
}
.avatar-group .avatar-sm:not(:first-child) {
  margin-left: -0.4rem;
}
.avatar-group .avatar-lg:not(:first-child) {
  margin-left: -1rem;
}
.avatar-group .avatar-xl:not(:first-child) {
  margin-left: -1.5rem;
}

/*=========================================================================================
	File Name: search.scss
	Description: Search  functionality.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.navbar-container a.nav-link-search {
  float: left;
}
.navbar-container .search-input {
  width: 0;
  float: left;
  display: none;
}
.navbar-container .search-input input {
  width: 0;
  border: none;
  background: none;
  transition: all 0.2s ease-out;
  line-height: 16px;
  padding: 1.6rem 3.6rem;
}
.navbar-container .search-input.open {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  display: block;
  background: #fff;
  border-radius: 0.5rem;
}
.navbar-container .search-input.open .search-input-close,
.navbar-container .search-input.open .search-input-icon {
  display: block;
}
.navbar-container .search-input.open input {
  width: 100%;
  outline: none;
  background: none;
  height: auto;
  box-shadow: none;
  transition: all 0.3s ease-out;
}
.navbar-container .search-input .search-list {
  max-height: 450px;
  height: auto;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  width: 100%;
  margin-top: 0.5rem;
  padding-left: 0;
  border-radius: 0.357rem;
  display: none;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 875px) and (orientation: landscape) {
  .navbar-container .search-input .search-list {
    max-height: 250px;
  }
}
.navbar-container .search-input .search-list.search-list-bookmark {
  height: auto !important;
}
.navbar-container .search-input .search-list.search-list-bookmark i,
.navbar-container .search-input .search-list.search-list-bookmark svg {
  font-size: 1.2rem;
  height: 1.2rem;
  width: 1.2rem;
}
.navbar-container .search-input .search-list.show {
  display: block;
  min-height: 3rem;
}
.navbar-container .search-input .search-list li a {
  padding: 0.9rem 1rem;
  color: #6e6b7b;
}
.navbar-container .search-input .search-list li a i,
.navbar-container .search-input .search-list li a svg[class*="feather feather-"] {
  height: 1.25rem;
  width: 1.25rem;
  font-size: 1.25rem;
}
.navbar-container .search-input .search-list li:first-child {
  border-top-left-radius: 0.357rem;
  border-top-right-radius: 0.357rem;
}
.navbar-container .search-input .search-list li:last-child {
  border-bottom-left-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;
}
.navbar-container .search-input .search-list li.auto-suggestion {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.navbar-container .search-input .search-list li.auto-suggestion:hover, .navbar-container .search-input .search-list li.auto-suggestion.current_item {
  background-color: #f8f8f8;
}
.navbar-container .search-input .search-input-icon {
  z-index: 2;
  display: none;
  position: absolute;
  left: 1.5rem;
  top: 32%;
  cursor: pointer;
}
.navbar-container .search-input .search-input-icon i,
.navbar-container .search-input .search-input-icon svg {
  height: 1.25rem;
  width: 1.25rem;
  font-size: 1.25rem;
}
.navbar-container .search-input .search-input-close {
  z-index: 1;
  display: none;
  position: absolute;
  right: 2rem;
  top: 32%;
  cursor: pointer;
}
.navbar-container .search-input .search-input-close i,
.navbar-container .search-input .search-input-close svg {
  height: 1.25rem;
  width: 1.25rem;
  font-size: 1.25rem;
}
.navbar-container .bookmark-input {
  position: absolute;
  top: 102%;
  width: 25%;
  display: none;
  background: #fff;
  border-radius: 0.357rem;
  box-shadow: 0 15px 30px 0 rgba(34, 41, 47, 0.11), 0 5px 15px 0 rgba(34, 41, 47, 0.08);
}
.navbar-container .bookmark-input.show {
  display: block;
}
.navbar-container .bookmark-input .bookmark-input-icon {
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 24px;
}
.navbar-container .bookmark-input input {
  padding: 0.7rem 0.7rem 0.7rem 2.5rem;
  margin: 12px;
  width: calc(100% - 24px);
  background: #fff;
  border: 1px solid rgba(34, 41, 47, 0.2);
}
.navbar-container .bookmark-input input:focus {
  border: 1px solid #bd832b;
}
.navbar-container .bookmark-input .search-list {
  position: relative;
}
.navbar-container .bookmark-icon {
  cursor: pointer;
}
.navbar-container .dropdown.bookmark-dropdown {
  padding: 1.4rem 0.5rem 1.35rem;
}
.navbar-container .dropdown.bookmark-dropdown .dropdown-toggle:after {
  display: none;
}
.navbar-container .search-data-title {
  line-height: 1.25rem;
}

.navbar-dark .search-input .input {
  color: #f5f7fa;
  border-radius: 0.5rem;
}
.navbar-dark .search-input.open .input {
  color: #f5f7fa;
  border: 1px solid #f5f7fa;
}

.navbar-static .navbar-container .search-input.open {
  border-radius: 0;
}

.btn-social, .btn-social-icon {
  position: relative;
  padding-left: 4.95rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-social > :first-child, .btn-social-icon > :first-child {
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 0;
  width: 2.95rem;
  line-height: 2.7rem;
  font-size: 1rem;
  text-align: center;
  border-right: 1px solid rgba(34, 41, 47, 0.2);
}

.btn-social-icon {
  height: 2.95rem;
  width: 2.95rem;
  padding: 0;
}
.btn-social-icon > :first-child {
  border: none;
  text-align: center;
  width: 100% !important;
}

.btn-adn {
  color: #fff;
  background-color: #d87a68;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-adn:hover {
  color: #fff;
  background-color: #b86858;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-adn, .btn-adn:focus {
  color: #fff;
  background-color: #b86858;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-adn, .btn-check:active + .btn-adn, .btn-adn:active, .btn-adn.active, .show > .btn-adn.dropdown-toggle {
  color: #fff;
  background-color: #ad6253;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-adn:focus, .btn-check:active + .btn-adn:focus, .btn-adn:active:focus, .btn-adn.active:focus, .show > .btn-adn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-adn:disabled, .btn-adn.disabled {
  color: #fff;
  background-color: #d87a68;
  border-color: transparent;
}
.btn-adn:focus {
  box-shadow: none !important;
}

.btn-bitbucket {
  color: #fff;
  background-color: #205081;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-bitbucket:hover {
  color: #fff;
  background-color: #1b446e;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-bitbucket, .btn-bitbucket:focus {
  color: #fff;
  background-color: #1b446e;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-bitbucket, .btn-check:active + .btn-bitbucket, .btn-bitbucket:active, .btn-bitbucket.active, .show > .btn-bitbucket.dropdown-toggle {
  color: #fff;
  background-color: #1a4067;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-bitbucket:focus, .btn-check:active + .btn-bitbucket:focus, .btn-bitbucket:active:focus, .btn-bitbucket.active:focus, .show > .btn-bitbucket.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-bitbucket:disabled, .btn-bitbucket.disabled {
  color: #fff;
  background-color: #205081;
  border-color: transparent;
}
.btn-bitbucket:focus {
  box-shadow: none !important;
}

.btn-dropbox {
  color: #fff;
  background-color: #1087dd;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-dropbox:hover {
  color: #fff;
  background-color: #0e73bc;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-dropbox, .btn-dropbox:focus {
  color: #fff;
  background-color: #0e73bc;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-dropbox, .btn-check:active + .btn-dropbox, .btn-dropbox:active, .btn-dropbox.active, .show > .btn-dropbox.dropdown-toggle {
  color: #fff;
  background-color: #0d6cb1;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-dropbox:focus, .btn-check:active + .btn-dropbox:focus, .btn-dropbox:active:focus, .btn-dropbox.active:focus, .show > .btn-dropbox.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-dropbox:disabled, .btn-dropbox.disabled {
  color: #fff;
  background-color: #1087dd;
  border-color: transparent;
}
.btn-dropbox:focus {
  box-shadow: none !important;
}

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-facebook:hover {
  color: #fff;
  background-color: #324c81;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-facebook, .btn-facebook:focus {
  color: #fff;
  background-color: #324c81;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-facebook, .btn-check:active + .btn-facebook, .btn-facebook:active, .btn-facebook.active, .show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2f477a;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-facebook:focus, .btn-check:active + .btn-facebook:focus, .btn-facebook:active:focus, .btn-facebook.active:focus, .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-facebook:disabled, .btn-facebook.disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: transparent;
}
.btn-facebook:focus {
  box-shadow: none !important;
}

.btn-flickr {
  color: #fff;
  background-color: #ff0084;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-flickr:hover {
  color: #fff;
  background-color: #d90070;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-flickr, .btn-flickr:focus {
  color: #fff;
  background-color: #d90070;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-flickr, .btn-check:active + .btn-flickr, .btn-flickr:active, .btn-flickr.active, .show > .btn-flickr.dropdown-toggle {
  color: #fff;
  background-color: #cc006a;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-flickr:focus, .btn-check:active + .btn-flickr:focus, .btn-flickr:active:focus, .btn-flickr.active:focus, .show > .btn-flickr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-flickr:disabled, .btn-flickr.disabled {
  color: #fff;
  background-color: #ff0084;
  border-color: transparent;
}
.btn-flickr:focus {
  box-shadow: none !important;
}

.btn-foursquare {
  color: #fff;
  background-color: #f94877;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-foursquare:hover {
  color: #fff;
  background-color: #d43d65;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-foursquare, .btn-foursquare:focus {
  color: #fff;
  background-color: #d43d65;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-foursquare, .btn-check:active + .btn-foursquare, .btn-foursquare:active, .btn-foursquare.active, .show > .btn-foursquare.dropdown-toggle {
  color: #fff;
  background-color: #c73a5f;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-foursquare:focus, .btn-check:active + .btn-foursquare:focus, .btn-foursquare:active:focus, .btn-foursquare.active:focus, .show > .btn-foursquare.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-foursquare:disabled, .btn-foursquare.disabled {
  color: #fff;
  background-color: #f94877;
  border-color: transparent;
}
.btn-foursquare:focus {
  box-shadow: none !important;
}

.btn-github {
  color: #fff;
  background-color: #444444;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-github:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-github, .btn-github:focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-github, .btn-check:active + .btn-github, .btn-github:active, .btn-github.active, .show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #363636;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-github:focus, .btn-check:active + .btn-github:focus, .btn-github:active:focus, .btn-github.active:focus, .show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-github:disabled, .btn-github.disabled {
  color: #fff;
  background-color: #444444;
  border-color: transparent;
}
.btn-github:focus {
  box-shadow: none !important;
}

.btn-google {
  color: #fff;
  background-color: #dd4b39;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-google:hover {
  color: #fff;
  background-color: #bc4030;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-google, .btn-google:focus {
  color: #fff;
  background-color: #bc4030;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-google, .btn-check:active + .btn-google, .btn-google:active, .btn-google.active, .show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #b13c2e;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-google:focus, .btn-check:active + .btn-google:focus, .btn-google:active:focus, .btn-google.active:focus, .show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-google:disabled, .btn-google.disabled {
  color: #fff;
  background-color: #dd4b39;
  border-color: transparent;
}
.btn-google:focus {
  box-shadow: none !important;
}

.btn-instagram {
  color: #fff;
  background-color: #3f729b;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-instagram:hover {
  color: #fff;
  background-color: #366184;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-instagram, .btn-instagram:focus {
  color: #fff;
  background-color: #366184;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-instagram, .btn-check:active + .btn-instagram, .btn-instagram:active, .btn-instagram.active, .show > .btn-instagram.dropdown-toggle {
  color: #fff;
  background-color: #325b7c;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-instagram:focus, .btn-check:active + .btn-instagram:focus, .btn-instagram:active:focus, .btn-instagram.active:focus, .show > .btn-instagram.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-instagram:disabled, .btn-instagram.disabled {
  color: #fff;
  background-color: #3f729b;
  border-color: transparent;
}
.btn-instagram:focus {
  box-shadow: none !important;
}

.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-linkedin:hover {
  color: #fff;
  background-color: #00699b;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-linkedin, .btn-linkedin:focus {
  color: #fff;
  background-color: #00699b;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-linkedin, .btn-check:active + .btn-linkedin, .btn-linkedin:active, .btn-linkedin.active, .show > .btn-linkedin.dropdown-toggle {
  color: #fff;
  background-color: #006292;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-linkedin:focus, .btn-check:active + .btn-linkedin:focus, .btn-linkedin:active:focus, .btn-linkedin.active:focus, .show > .btn-linkedin.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-linkedin:disabled, .btn-linkedin.disabled {
  color: #fff;
  background-color: #007bb6;
  border-color: transparent;
}
.btn-linkedin:focus {
  box-shadow: none !important;
}

.btn-microsoft {
  color: #fff;
  background-color: #2672ec;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-microsoft:hover {
  color: #fff;
  background-color: #2061c9;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-microsoft, .btn-microsoft:focus {
  color: #fff;
  background-color: #2061c9;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-microsoft, .btn-check:active + .btn-microsoft, .btn-microsoft:active, .btn-microsoft.active, .show > .btn-microsoft.dropdown-toggle {
  color: #fff;
  background-color: #1e5bbd;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-microsoft:focus, .btn-check:active + .btn-microsoft:focus, .btn-microsoft:active:focus, .btn-microsoft.active:focus, .show > .btn-microsoft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-microsoft:disabled, .btn-microsoft.disabled {
  color: #fff;
  background-color: #2672ec;
  border-color: transparent;
}
.btn-microsoft:focus {
  box-shadow: none !important;
}

.btn-odnoklassniki {
  color: #22292f;
  background-color: #f4731c;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-odnoklassniki:hover {
  color: #22292f;
  background-color: #f6883e;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:focus + .btn-odnoklassniki, .btn-odnoklassniki:focus {
  color: #22292f;
  background-color: #f6883e;
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-check:checked + .btn-odnoklassniki, .btn-check:active + .btn-odnoklassniki, .btn-odnoklassniki:active, .btn-odnoklassniki.active, .show > .btn-odnoklassniki.dropdown-toggle {
  color: #22292f;
  background-color: #f68f49;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:checked + .btn-odnoklassniki:focus, .btn-check:active + .btn-odnoklassniki:focus, .btn-odnoklassniki:active:focus, .btn-odnoklassniki.active:focus, .show > .btn-odnoklassniki.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-odnoklassniki:disabled, .btn-odnoklassniki.disabled {
  color: #22292f;
  background-color: #f4731c;
  border-color: transparent;
}
.btn-odnoklassniki:focus {
  box-shadow: none !important;
}

.btn-openid {
  color: #22292f;
  background-color: #f7931e;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-openid:hover {
  color: #22292f;
  background-color: #f8a340;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:focus + .btn-openid, .btn-openid:focus {
  color: #22292f;
  background-color: #f8a340;
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-check:checked + .btn-openid, .btn-check:active + .btn-openid, .btn-openid:active, .btn-openid.active, .show > .btn-openid.dropdown-toggle {
  color: #22292f;
  background-color: #f9a94b;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:checked + .btn-openid:focus, .btn-check:active + .btn-openid:focus, .btn-openid:active:focus, .btn-openid.active:focus, .show > .btn-openid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-openid:disabled, .btn-openid.disabled {
  color: #22292f;
  background-color: #f7931e;
  border-color: transparent;
}
.btn-openid:focus {
  box-shadow: none !important;
}

.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-pinterest:hover {
  color: #fff;
  background-color: #ad1b21;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-pinterest, .btn-pinterest:focus {
  color: #fff;
  background-color: #ad1b21;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-pinterest, .btn-check:active + .btn-pinterest, .btn-pinterest:active, .btn-pinterest.active, .show > .btn-pinterest.dropdown-toggle {
  color: #fff;
  background-color: #a21a1f;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-pinterest:focus, .btn-check:active + .btn-pinterest:focus, .btn-pinterest:active:focus, .btn-pinterest.active:focus, .show > .btn-pinterest.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-pinterest:disabled, .btn-pinterest.disabled {
  color: #fff;
  background-color: #cb2027;
  border-color: transparent;
}
.btn-pinterest:focus {
  box-shadow: none !important;
}

.btn-reddit {
  color: #22292f;
  background-color: #eff7ff;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-reddit:hover {
  color: #22292f;
  background-color: #f1f8ff;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:focus + .btn-reddit, .btn-reddit:focus {
  color: #22292f;
  background-color: #f1f8ff;
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-check:checked + .btn-reddit, .btn-check:active + .btn-reddit, .btn-reddit:active, .btn-reddit.active, .show > .btn-reddit.dropdown-toggle {
  color: #22292f;
  background-color: #f2f9ff;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:checked + .btn-reddit:focus, .btn-check:active + .btn-reddit:focus, .btn-reddit:active:focus, .btn-reddit.active:focus, .show > .btn-reddit.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-reddit:disabled, .btn-reddit.disabled {
  color: #22292f;
  background-color: #eff7ff;
  border-color: transparent;
}
.btn-reddit:focus {
  box-shadow: none !important;
}

.btn-soundcloud {
  color: #fff;
  background-color: #ff5500;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-soundcloud:hover {
  color: #fff;
  background-color: #d94800;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-soundcloud, .btn-soundcloud:focus {
  color: #fff;
  background-color: #d94800;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-soundcloud, .btn-check:active + .btn-soundcloud, .btn-soundcloud:active, .btn-soundcloud.active, .show > .btn-soundcloud.dropdown-toggle {
  color: #fff;
  background-color: #cc4400;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-soundcloud:focus, .btn-check:active + .btn-soundcloud:focus, .btn-soundcloud:active:focus, .btn-soundcloud.active:focus, .show > .btn-soundcloud.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-soundcloud:disabled, .btn-soundcloud.disabled {
  color: #fff;
  background-color: #ff5500;
  border-color: transparent;
}
.btn-soundcloud:focus {
  box-shadow: none !important;
}

.btn-tumblr {
  color: #fff;
  background-color: #2c4762;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-tumblr:hover {
  color: #fff;
  background-color: #253c53;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-tumblr, .btn-tumblr:focus {
  color: #fff;
  background-color: #253c53;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-tumblr, .btn-check:active + .btn-tumblr, .btn-tumblr:active, .btn-tumblr.active, .show > .btn-tumblr.dropdown-toggle {
  color: #fff;
  background-color: #23394e;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-tumblr:focus, .btn-check:active + .btn-tumblr:focus, .btn-tumblr:active:focus, .btn-tumblr.active:focus, .show > .btn-tumblr.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-tumblr:disabled, .btn-tumblr.disabled {
  color: #fff;
  background-color: #2c4762;
  border-color: transparent;
}
.btn-tumblr:focus {
  box-shadow: none !important;
}

.btn-twitter {
  color: #22292f;
  background-color: #55acee;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-twitter:hover {
  color: #22292f;
  background-color: #6fb8f1;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:focus + .btn-twitter, .btn-twitter:focus {
  color: #22292f;
  background-color: #6fb8f1;
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-check:checked + .btn-twitter, .btn-check:active + .btn-twitter, .btn-twitter:active, .btn-twitter.active, .show > .btn-twitter.dropdown-toggle {
  color: #22292f;
  background-color: #77bdf1;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:checked + .btn-twitter:focus, .btn-check:active + .btn-twitter:focus, .btn-twitter:active:focus, .btn-twitter.active:focus, .show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-twitter:disabled, .btn-twitter.disabled {
  color: #22292f;
  background-color: #55acee;
  border-color: transparent;
}
.btn-twitter:focus {
  box-shadow: none !important;
}

.btn-vimeo {
  color: #22292f;
  background-color: #1ab7ea;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-vimeo:hover {
  color: #22292f;
  background-color: #3cc2ed;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:focus + .btn-vimeo, .btn-vimeo:focus {
  color: #22292f;
  background-color: #3cc2ed;
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-check:checked + .btn-vimeo, .btn-check:active + .btn-vimeo, .btn-vimeo:active, .btn-vimeo.active, .show > .btn-vimeo.dropdown-toggle {
  color: #22292f;
  background-color: #48c5ee;
  border-color: rgba(255, 255, 255, 0.1);
}
.btn-check:checked + .btn-vimeo:focus, .btn-check:active + .btn-vimeo:focus, .btn-vimeo:active:focus, .btn-vimeo.active:focus, .show > .btn-vimeo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 41, 47, 0.5);
}
.btn-vimeo:disabled, .btn-vimeo.disabled {
  color: #22292f;
  background-color: #1ab7ea;
  border-color: transparent;
}
.btn-vimeo:focus {
  box-shadow: none !important;
}

.btn-vk {
  color: #fff;
  background-color: #587ea3;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-vk:hover {
  color: #fff;
  background-color: #4b6b8b;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-vk, .btn-vk:focus {
  color: #fff;
  background-color: #4b6b8b;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-vk, .btn-check:active + .btn-vk, .btn-vk:active, .btn-vk.active, .show > .btn-vk.dropdown-toggle {
  color: #fff;
  background-color: #466582;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-vk:focus, .btn-check:active + .btn-vk:focus, .btn-vk:active:focus, .btn-vk.active:focus, .show > .btn-vk.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-vk:disabled, .btn-vk.disabled {
  color: #fff;
  background-color: #587ea3;
  border-color: transparent;
}
.btn-vk:focus {
  box-shadow: none !important;
}

.btn-yahoo {
  color: #fff;
  background-color: #720e9e;
  border-color: transparent;
  color: #fff !important;
  border: none;
}
.btn-yahoo:hover {
  color: #fff;
  background-color: #610c86;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-check:focus + .btn-yahoo, .btn-yahoo:focus {
  color: #fff;
  background-color: #610c86;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-yahoo, .btn-check:active + .btn-yahoo, .btn-yahoo:active, .btn-yahoo.active, .show > .btn-yahoo.dropdown-toggle {
  color: #fff;
  background-color: #5b0b7e;
  border-color: rgba(0, 0, 0, 0.25);
}
.btn-check:checked + .btn-yahoo:focus, .btn-check:active + .btn-yahoo:focus, .btn-yahoo:active:focus, .btn-yahoo.active:focus, .show > .btn-yahoo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-yahoo:disabled, .btn-yahoo.disabled {
  color: #fff;
  background-color: #720e9e;
  border-color: transparent;
}
.btn-yahoo:focus {
  box-shadow: none !important;
}

.btn-outline-adn {
  border: 1px solid #d87a68 !important;
  color: #d87a68;
}

.btn-outline-bitbucket {
  border: 1px solid #205081 !important;
  color: #205081;
}

.btn-outline-dropbox {
  border: 1px solid #1087dd !important;
  color: #1087dd;
}

.btn-outline-facebook {
  border: 1px solid #3b5998 !important;
  color: #3b5998;
}

.btn-outline-flickr {
  border: 1px solid #ff0084 !important;
  color: #ff0084;
}

.btn-outline-foursquare {
  border: 1px solid #f94877 !important;
  color: #f94877;
}

.btn-outline-github {
  border: 1px solid #444444 !important;
  color: #444444;
}

.btn-outline-google {
  border: 1px solid #dd4b39 !important;
  color: #dd4b39;
}

.btn-outline-instagram {
  border: 1px solid #3f729b !important;
  color: #3f729b;
}

.btn-outline-linkedin {
  border: 1px solid #007bb6 !important;
  color: #007bb6;
}

.btn-outline-microsoft {
  border: 1px solid #2672ec !important;
  color: #2672ec;
}

.btn-outline-odnoklassniki {
  border: 1px solid #f4731c !important;
  color: #f4731c;
}

.btn-outline-openid {
  border: 1px solid #f7931e !important;
  color: #f7931e;
}

.btn-outline-pinterest {
  border: 1px solid #cb2027 !important;
  color: #cb2027;
}

.btn-outline-reddit {
  border: 1px solid #ff4500 !important;
  color: #ff4500;
}

.btn-outline-soundcloud {
  border: 1px solid #ff5500 !important;
  color: #ff5500;
}

.btn-outline-tumblr {
  border: 1px solid #2c4762 !important;
  color: #2c4762;
}

.btn-outline-twitter {
  border: 1px solid #55acee !important;
  color: #55acee;
}

.btn-outline-vimeo {
  border: 1px solid #1ab7ea !important;
  color: #1ab7ea;
}

.btn-outline-vk {
  border: 1px solid #587ea3 !important;
  color: #587ea3;
}

.btn-outline-yahoo {
  border: 1px solid #720e9e !important;
  color: #720e9e;
}

.btn-outline-adn:hover {
  color: #ad6253;
  border: 1px solid #ad6253 !important;
}

.btn-outline-bitbucket:hover {
  color: #1a4067;
  border: 1px solid #1a4067 !important;
}

.btn-outline-dropbox:hover {
  color: #0d6cb1;
  border: 1px solid #0d6cb1 !important;
}

.btn-outline-facebook:hover {
  color: #2f477a;
  border: 1px solid #2f477a !important;
}

.btn-outline-flickr:hover {
  color: #cc006a;
  border: 1px solid #cc006a !important;
}

.btn-outline-foursquare:hover {
  color: #c73a5f;
  border: 1px solid #c73a5f !important;
}

.btn-outline-github:hover {
  color: #363636;
  border: 1px solid #363636 !important;
}

.btn-outline-google:hover {
  color: #b13c2e;
  border: 1px solid #b13c2e !important;
}

.btn-outline-instagram:hover {
  color: #325b7c;
  border: 1px solid #325b7c !important;
}

.btn-outline-linkedin:hover {
  color: #006292;
  border: 1px solid #006292 !important;
}

.btn-outline-microsoft:hover {
  color: #1e5bbd;
  border: 1px solid #1e5bbd !important;
}

.btn-outline-odnoklassniki:hover {
  color: #c35c16;
  border: 1px solid #c35c16 !important;
}

.btn-outline-openid:hover {
  color: #c67618;
  border: 1px solid #c67618 !important;
}

.btn-outline-pinterest:hover {
  color: #a21a1f;
  border: 1px solid #a21a1f !important;
}

.btn-outline-reddit:hover {
  color: #cc3700;
  border: 1px solid #cc3700 !important;
}

.btn-outline-soundcloud:hover {
  color: #cc4400;
  border: 1px solid #cc4400 !important;
}

.btn-outline-tumblr:hover {
  color: #23394e;
  border: 1px solid #23394e !important;
}

.btn-outline-twitter:hover {
  color: #448abe;
  border: 1px solid #448abe !important;
}

.btn-outline-vimeo:hover {
  color: #1592bb;
  border: 1px solid #1592bb !important;
}

.btn-outline-vk:hover {
  color: #466582;
  border: 1px solid #466582 !important;
}

.btn-outline-yahoo:hover {
  color: #5b0b7e;
  border: 1px solid #5b0b7e !important;
}

.bg-adn {
  background-color: #d87a68;
}

.bg-bitbucket {
  background-color: #205081;
}

.bg-dropbox {
  background-color: #1087dd;
}

.bg-facebook {
  background-color: #3b5998;
}

.bg-flickr {
  background-color: #ff0084;
}

.bg-foursquare {
  background-color: #f94877;
}

.bg-github {
  background-color: #444444;
}

.bg-google {
  background-color: #dd4b39;
}

.bg-instagram {
  background-color: #3f729b;
}

.bg-linkedin {
  background-color: #007bb6;
}

.bg-microsoft {
  background-color: #2672ec;
}

.bg-odnoklassniki {
  background-color: #f4731c;
}

.bg-openid {
  background-color: #f7931e;
}

.bg-pinterest {
  background-color: #cb2027;
}

.bg-reddit {
  background-color: #ff4500;
}

.bg-soundcloud {
  background-color: #ff5500;
}

.bg-tumblr {
  background-color: #2c4762;
}

.bg-twitter {
  background-color: #55acee;
}

.bg-vimeo {
  background-color: #1ab7ea;
}

.bg-vk {
  background-color: #587ea3;
}

.bg-yahoo {
  background-color: #720e9e;
}

/*=========================================================================================
    File Name: demo.scss
    Description: CSS used for demo purpose only. Remove this css from your project.
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.demo-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.demo-inline-spacing > * {
  margin-right: 1.5rem;
  margin-top: 1.5rem;
}

.demo-vertical-spacing > * {
  margin: 0;
  margin-bottom: 1.5rem;
}
.demo-vertical-spacing > *:last-child {
  margin: 0;
}

.demo-spacing-0 > *:last-child {
  margin: 0;
}

.icon-wrapper .feather {
  height: 24px;
  width: 24px;
}

.scroll-example {
  padding: 0.5rem;
  position: relative;
  border: 2px solid #dfdfdf;
  overflow: auto;
}
.scroll-example .horz-scroll-content {
  width: 1200px;
}

.browser {
  background: #e0e0e0;
  border: 4px solid #e0e0e0;
  width: 100%;
  height: 12rem;
  padding-top: 20px;
  margin: 0 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.browser iframe {
  border: 0;
  background: #fff;
  height: 100%;
  width: 100%;
}

.loader-wrapper {
  height: 8em;
}

.maintenance-icon {
  font-size: 4rem;
}

.animationIcon {
  right: 30px;
  bottom: 10px;
}

#component-breadcrumbs .breadcrumb:not(last-child) {
  margin-bottom: 0.5rem;
}

#breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow: 1px 1px 10px rgba(34, 41, 47, 0.1);
  padding: 1px;
  border-radius: 0.357rem;
}
#breadcrumb-alignment .breadcrumb-wrapper .breadcrumb {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.dark-layout #breadcrumb-alignment .breadcrumb-wrapper {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
}

.buy-now {
  position: fixed;
  bottom: 5%;
  right: 79px;
  z-index: 1031;
}
.buy-now .btn {
  box-shadow: 0 1px 20px 1px #ea5455 !important;
}
.buy-now .btn:hover {
  box-shadow: none !important;
}

/*=========================================================================================
	File Name: customizer.scss
	Description: CSS used for demo purpose only. Remove this css from your project.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
.customizer {
  width: 400px;
  right: -400px;
  padding: 0;
  background-color: #fff;
  z-index: 1051;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  backface-visibility: hidden;
  border-left: 1px solid rgba(34, 41, 47, 0.05);
}
.customizer.open {
  box-shadow: 0 15px 30px 0 rgba(34, 41, 47, 0.11), 0 5px 15px 0 rgba(34, 41, 47, 0.08);
  right: 0;
}
.customizer .customizer-content {
  position: relative;
  height: 100%;
}
.customizer .customizer-close {
  position: absolute;
  right: 0.6rem;
  top: 0.6rem;
  padding: 7px;
  width: auto;
  z-index: 10;
  color: #5e5873;
}
.customizer .customizer-close i,
.customizer .customizer-close svg {
  font-size: 1.5rem;
  height: 1.5rem;
  width: 1.5rem;
}
.customizer .customizer-toggle {
  background: #bd832b;
  color: #fff !important;
  display: block;
  box-shadow: -3px 0px 8px rgba(34, 41, 47, 0.1);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  position: absolute;
  top: 50%;
  width: 38px;
  height: 38px;
  left: -39px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.customizer .color-box {
  height: 24px;
  width: 24px;
  margin: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;
}
.customizer .color-box.selected:after {
  content: "";
  border: 1px solid #bd832b;
  height: 30px;
  width: 30px;
  top: -3px;
  left: -3px;
  position: absolute;
  border-radius: 0.5rem;
}
.customizer .color-box.selected.border:after {
  top: -4px;
  left: -4px;
}

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;
}
.divider .divider-text {
  position: relative;
  display: inline-block;
  font-size: 0.9375rem;
  padding: 0 1rem;
}
.divider .divider-text i,
.divider .divider-text svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
}
.divider .divider-text:before, .divider .divider-text:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  border-top: 1px solid #ebe9f1;
}
.divider .divider-text:before {
  right: 100%;
}
.divider .divider-text:after {
  left: 100%;
}
.divider.divider-start .divider-text {
  float: left;
  padding-left: 0;
}
.divider.divider-start .divider-text:before {
  display: none;
}
.divider.divider-start-center .divider-text {
  left: -25%;
}
.divider.divider-end .divider-text {
  float: right;
  padding-right: 0;
}
.divider.divider-end .divider-text:after {
  display: none;
}
.divider.divider-end-center .divider-text {
  right: -25%;
}
.divider.divider-dotted .divider-text:before, .divider.divider-dotted .divider-text:after {
  border-style: dotted;
  border-width: 1px;
  border-top-width: 0;
  border-color: #ebe9f1;
}
.divider.divider-dashed .divider-text:before, .divider.divider-dashed .divider-text:after {
  border-style: dashed;
  border-width: 1px;
  border-top-width: 0;
  border-color: #ebe9f1;
}

.timeline {
  padding: 0;
  margin-bottom: 0;
  margin-left: 1rem;
  list-style: none;
}
.timeline .timeline-item {
  position: relative;
  padding-left: 2.5rem;
  border-left: 1px solid #ebe9f1;
}
.timeline .timeline-item:not(:last-child) {
  padding-bottom: 1.8rem;
}
.timeline .timeline-item .timeline-point {
  position: absolute;
  left: -0.85rem;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  width: 1.75rem;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #bd832b;
  background-color: #fff;
}
.timeline .timeline-item .timeline-point.timeline-point-indicator {
  left: -0.412rem;
  top: 0.07rem;
  height: 12px;
  width: 12px;
  border: 0;
  background-color: #bd832b;
}
.timeline .timeline-item .timeline-point.timeline-point-indicator:before {
  content: "";
  background: rgba(189, 131, 43, 0.12);
  height: 20px;
  width: 20px;
  display: block;
  position: absolute;
  top: -0.285rem;
  left: -0.285rem;
  border-radius: 50%;
}
.timeline .timeline-item .timeline-point i,
.timeline .timeline-item .timeline-point svg {
  color: #bd832b;
  font-size: 0.85rem;
  vertical-align: baseline;
}
.timeline .timeline-item .timeline-event {
  position: relative;
  width: 100%;
  min-height: 4rem;
}
.timeline .timeline-item .timeline-event .timeline-event-time {
  font-size: 0.85rem;
  color: #b9b9c3;
}
.timeline .timeline-item:last-of-type {
  border-left-color: transparent !important;
}
.timeline .timeline-item:last-of-type:after {
  content: "";
  position: absolute;
  left: -1px;
  bottom: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(#ebe9f1, transparent);
}

.custom-options-checkable .custom-option-item {
  width: 100%;
  cursor: pointer;
  border-radius: 0.42rem;
  color: #82868b;
  background-color: rgba(130, 134, 139, 0.06);
  border: 1px solid #ebe9f1;
}
.custom-options-checkable .custom-option-item .custom-option-item-title {
  color: #82868b;
}

.custom-option-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.custom-option-item-check:checked + .custom-option-item {
  color: #bd832b;
  background-color: rgba(189, 131, 43, 0.12);
  border-color: #bd832b;
}
.custom-option-item-check:checked + .custom-option-item .custom-option-item-title {
  color: #bd832b;
}

@media screen and (min-width: 0px) {
  head {
    font-family: "xs 0px";
  }

  body:after {
    content: "xs - min-width: 0px";
  }
}
@media screen and (min-width: 544px) {
  head {
    font-family: "sm 544px";
  }

  body:after {
    content: "sm - min-width: 544px";
  }
}
@media screen and (min-width: 768px) {
  head {
    font-family: "md 768px";
  }

  body:after {
    content: "md - min-width: 768px";
  }
}
@media screen and (min-width: 992px) {
  head {
    font-family: "lg 992px";
  }

  body:after {
    content: "lg - min-width: 992px";
  }
}
@media screen and (min-width: 1200px) {
  head {
    font-family: "xl 1200px";
  }

  body:after {
    content: "xl - min-width: 1200px";
  }
}
head {
  clear: both;
}
head title {
  font-family: "xs 0px, sm 544px, md 768px, lg 992px, xl 1200px";
}

body:after {
  display: none;
}

*[data-usn-if] {
  display: none;
}

.select2-container {
  width: 100% !important;
  margin: 0;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
}

.select2-container--classic:focus,
.select2-container--default:focus {
  outline: none;
}
.select2-container--classic .select2-selection__choice,
.select2-container--default .select2-selection__choice {
  font-size: 0.9rem;
  margin-top: 6px !important;
}
.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--single {
  min-height: 2.714rem;
  padding: 5px;
  border: 1px solid #d8d6de;
}
.select2-container--classic .select2-selection--single:focus,
.select2-container--default .select2-selection--single:focus {
  outline: 0;
  border-color: #bd832b !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered i,
.select2-container--classic .select2-selection--single .select2-selection__rendered svg,
.select2-container--default .select2-selection--single .select2-selection__rendered i,
.select2-container--default .select2-selection--single .select2-selection__rendered svg {
  font-size: 1.15rem;
  height: 1.15rem;
  width: 1.15rem;
  margin-right: 0.5rem;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b,
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23d8d6de' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 18px 14px, 18px 14px;
  background-repeat: no-repeat;
  height: 1rem;
  padding-right: 1.5rem;
  margin-left: 0;
  margin-top: 0;
  left: -8px;
  border-style: none;
}
.select2-container--classic.select2-container--open,
.select2-container--default.select2-container--open {
  box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
}
.select2-container--classic.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--single {
  border-color: #bd832b !important;
  outline: 0;
}
.select2-container--classic.select2-container--focus,
.select2-container--default.select2-container--focus {
  outline: 0;
}
.select2-container--classic.select2-container--focus .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #bd832b !important;
  outline: 0;
}
.select2-container--classic .select2-dropdown,
.select2-container--default .select2-dropdown {
  border-color: #d8d6de;
  z-index: 1060;
}
.select2-container--classic .select2-search--dropdown,
.select2-container--default .select2-search--dropdown {
  padding: 0.5rem;
}
.select2-container--classic .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none !important;
  border-radius: 0.357rem;
  border-color: #d8d6de;
  padding: 0.571rem 1rem;
}
.select2-container--classic .select2-selection--multiple,
.select2-container--default .select2-selection--multiple {
  min-height: 38px !important;
  border: 1px solid #d8d6de;
}
.select2-container--classic .select2-selection--multiple:focus,
.select2-container--default .select2-selection--multiple:focus {
  outline: 0;
  border-color: #bd832b !important;
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #bd832b !important;
  border-color: #bd832b !important;
  color: #fff;
  padding: 2px 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 6px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered li .select2-search__field,
.select2-container--default .select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top: 7px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  float: right;
  margin-left: 0.7rem;
  margin-right: 0.5rem;
  font-size: 0px;
  display: inline-block;
  position: relative;
  line-height: 1rem;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:before,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  background-size: 0.85rem;
  height: 0.85rem;
  width: 0.85rem;
  position: absolute;
  top: 22%;
  left: -4px;
}
.select2-container--classic .select2-selection--multiple i,
.select2-container--classic .select2-selection--multiple svg,
.select2-container--default .select2-selection--multiple i,
.select2-container--default .select2-selection--multiple svg {
  position: relative;
  top: 1px;
  margin-right: 0.5rem;
  height: 1.15rem;
  width: 1.15rem;
  font-size: 1.15rem;
  padding-left: 1px;
}
.select2-container--classic .select2-results__group,
.select2-container--default .select2-results__group {
  padding: 6px 9px;
  font-weight: 600;
}
.select2-container--classic .select2-results__option,
.select2-container--default .select2-results__option {
  padding: 0.428rem 1rem;
}
.select2-container--classic .select2-results__option[role=group],
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #bd832b !important;
  color: #fff !important;
}
.select2-container--classic .select2-results__option i,
.select2-container--classic .select2-results__option svg,
.select2-container--default .select2-results__option i,
.select2-container--default .select2-results__option svg {
  height: 1.15rem;
  width: 1.15rem;
  font-size: 1.15rem;
  margin-right: 0.5rem;
}
.select2-container--classic .select2-results__option--highlighted,
.select2-container--default .select2-results__option--highlighted {
  background-color: rgba(189, 131, 43, 0.12) !important;
  color: #bd832b !important;
}
.select2-container--classic .select2-result-repository__avatar img,
.select2-container--default .select2-result-repository__avatar img {
  width: 50px;
}
.select2-container--classic .select-lg,
.select2-container--default .select-lg {
  min-height: 2.714rem !important;
  font-size: 1.2rem;
  margin-bottom: 0 !important;
  padding: 0.3rem 0.7rem;
}
.select2-container--classic .select-lg.select2-selection--single .select2-selection__rendered,
.select2-container--default .select-lg.select2-selection--single .select2-selection__rendered {
  padding-top: -0.1rem;
}
.select2-container--classic .select-lg.select2-selection--single .select2-selection__arrow,
.select2-container--default .select-lg.select2-selection--single .select2-selection__arrow {
  top: 0rem !important;
}
.select2-container--classic .select-lg.select2-selection--multiple,
.select2-container--default .select-lg.select2-selection--multiple {
  padding: 0 0.2rem;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered {
  padding-top: 0 !important;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li {
  font-size: 1.2rem;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top: 10px;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  padding: 5px;
}
.select2-container--classic .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before,
.select2-container--default .select-lg.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before {
  top: 46%;
}
.select2-container--classic .select-sm,
.select2-container--default .select-sm {
  min-height: 2.142rem !important;
  padding: 0 0.2rem;
  font-size: 0.75rem;
  margin-bottom: 0 !important;
  line-height: 1.45;
}
.select2-container--classic .select-sm.select2-selection--single .select2-selection__arrow,
.select2-container--default .select-sm.select2-selection--single .select2-selection__arrow {
  top: -0.3rem !important;
}
.select2-container--classic .select-sm.select2-selection--multiple,
.select2-container--default .select-sm.select2-selection--multiple {
  line-height: 1.25;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered {
  padding: 3px;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li {
  font-size: 0.75rem;
  margin-top: 2px;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__rendered li .select2-search__field {
  margin-top: 4px;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice {
  padding: 0 0.2rem;
  margin-top: 4px !important;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before,
.select2-container--default .select-sm.select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:before {
  top: 5%;
}
.select2-container--classic .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field,
.select2-container--default .select-sm.select2-selection--multiple .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.dark-layout .select2-container .select2-selection,
.dark-layout .select2-container .select2-search__field,
.dark-layout .select2-container .select2-selection__placeholder {
  background: #283046;
  border-color: #3b4253;
  color: #b4b7bd;
}
.dark-layout .select2-container .select2-selection__rendered {
  color: #b4b7bd;
}
.dark-layout .select2-container .select2-dropdown {
  background-color: #283046;
  border-color: #3b4253;
}
.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice {
  background: rgba(189, 131, 43, 0.12) !important;
  color: #bd832b !important;
  border: none;
}
.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: #bd832b !important;
}
.dark-layout .select2-container .select2-selection--multiple .select2-selection__choice__remove:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23bd832b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
}
.dark-layout .select2-container .select2-results__option[aria-disabled=true] {
  color: #676d7d;
}
.dark-layout .select2-container .select2-selection__arrow b {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23b4b7bd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.dark-layout .select2-container--disabled .select2-selection {
  opacity: 0.5;
}

table.dataTable {
  width: 100% !important;
  border-collapse: collapse !important;
}
table.dataTable.table-responsive:not(.datatables-ajax) {
  display: block;
}
table.dataTable td,
table.dataTable th {
  padding: 0.72rem 1.5rem;
  vertical-align: middle;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  padding-right: inherit;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  color: #6e6b7b;
  width: 14px;
  height: 14px;
  content: "";
  right: 0.3rem;
  top: 0.5rem;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%235e5873' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  color: #6e6b7b;
  width: 14px;
  height: 14px;
  content: "";
  right: 0.3rem;
  top: 1.3rem;
}

div.dataTables_wrapper .dt-button-collection {
  background-color: #fff;
}
div.dataTables_wrapper .dt-button-collection > div[role=menu] {
  text-align: left;
  box-shadow: 0px 5px 16px -8px;
  min-width: 8rem;
}
div.dataTables_wrapper .dt-button-collection [class*=buttons-]:focus {
  outline: 0;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  line-height: 0.8em;
}

@media screen and (max-width: 1399.98px) {
  table.dataTable.table-responsive {
    display: block;
  }
}
.modal.dtr-bs-modal .modal-body {
  padding: 0;
}
.modal.dtr-bs-modal .table tr:last-child > td {
  border-bottom: 0;
}

div.dataTables_wrapper div.dataTables_filter label,
div.dataTables_wrapper div.dataTables_length label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
div.dataTables_wrapper div.dataTables_filter select,
div.dataTables_wrapper div.dataTables_length select {
  background-position: calc(100% - 3px) 11px, calc(100% - 20px) 13px, 100% 0;
  width: 5rem;
  margin: 0 0.5rem;
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.75rem;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
div.dataTables_wrapper .dataTables_info {
  padding-top: 1.25rem !important;
  color: #b9b9c3;
}

tr.group {
  background-color: #fafafc;
}

@media only screen and (max-width: 992px) {
  table.dataTable.table-responsive.datatables-ajax {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center;
  }

  div.dataTables_wrapper div.dataTables_info {
    white-space: inherit;
  }

  div.dt-buttons {
    justify-content: center;
    margin-top: 1rem;
  }
  div.dt-buttons button {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 575.98px) {
  .dtr-bs-modal .modal-body {
    padding-left: 0;
    padding-right: 0;
    overflow-x: scroll;
  }
  .dtr-bs-modal .table.dtr-details {
    margin: 0 auto 1rem;
  }
  .dtr-bs-modal .table.dtr-details td {
    padding-left: 0;
    padding-right: 0;
  }
  .dtr-bs-modal .table.dtr-details tr td:first-child {
    padding-left: 0.75rem;
  }

  div.dataTables_wrapper {
    overflow: scroll;
  }
}
/* Bootstrap Touchspin */
.bootstrap-touchspin.input-group {
  width: 8.4rem;
  align-items: center;
}
.bootstrap-touchspin.input-group .form-control {
  padding: 5px;
  height: auto;
  border: 0;
  background-color: #f8f8f8;
  border-radius: 0.357rem !important;
  text-align: center;
  font-weight: 500;
}
.bootstrap-touchspin.input-group .form-control:focus {
  z-index: 1;
  box-shadow: none;
}
.bootstrap-touchspin.input-group > .input-group-btn:first-child {
  left: 12px !important;
  position: inherit;
}
.bootstrap-touchspin.input-group > .input-group-btn:last-child {
  right: 12px !important;
  position: inherit;
}
.bootstrap-touchspin .bootstrap-touchspin-injected {
  margin: 0 !important;
}
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  padding: 0;
  min-width: 20px;
  min-height: 20px;
  border-radius: 0.357rem !important;
}
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down i,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down svg,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up i,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up svg {
  height: 0.8rem;
  width: 0.8rem;
  font-size: 0.8rem;
  position: relative;
  top: -1px;
}
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down,
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up {
  background-color: rgba(34, 41, 47, 0.5) !important;
  cursor: default;
  opacity: 0.5;
}
.bootstrap-touchspin.input-group-lg {
  width: 9.375rem;
}
.bootstrap-touchspin.input-group-lg .touchspin.form-control {
  height: auto !important;
}
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up {
  min-width: 24px;
  min-height: 24px;
}
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down i,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down svg,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up i,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up svg {
  height: 1rem;
  width: 1rem;
  font-size: 1rem;
  top: 0;
}
.bootstrap-touchspin.input-group-sm {
  width: 6.25rem;
}
.bootstrap-touchspin.input-group-sm .touchspin.form-control {
  height: auto !important;
}
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up {
  min-width: 16px;
  min-height: 16px;
}
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down i,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down svg,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up i,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up svg {
  height: 0.6rem;
  width: 0.6rem;
  font-size: 0.6rem;
  top: -3px;
}

/* Number Type Input Box Scss for Touchspin - Remove arrow for firefox */
.bootstrap-touchspin.input-group input[type=number] {
  -moz-appearance: textfield;
}

.dark-layout .bootstrap-touchspin.input-group .form-control {
  background-color: #161d31;
}
.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up,
.dark-layout .bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-injected .disabled-max-min {
  background-color: #b8c2cc !important;
  opacity: 1;
}
.dark-layout .bootstrap-touchspin .bootstrap-touchspin-injected .disabled-max-min {
  background-color: #b8c2cc !important;
  opacity: 1;
}